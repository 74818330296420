import React, { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import CertificatesContext from "./CertificatesContext.js";
import history from './history';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import axios from "axios";
import API from "./api";

import './App.scss';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import StartPage from './components/Pages/StartPage/StartPage';
import ItBetreuung from './components/Pages/ItBetreuung/ItBetreuung';
import Referenzen from './components/Pages/Referenzen/Referenzen';
import Contact from './components/Pages/Contact/Contact';
import CookieOptIn from './components/Pages/CookieOptIn/CookieOptIn';
import NotFound from './components/Pages/NotFound/NotFound';
import Partner from './components/Pages/Partner/Partner';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import TextPage from './components/Pages/TextPage/TextPage';
import Hosting from './components/Pages/Hosting/Hosting';
import SoftwareEntwicklung from './components/Pages/SoftwareEntwicklung/SoftwareEntwicklung';
import CVPage from './components/Pages/CVPage/CVPage';
import Imprint from './components/Pages/Imprint/Imprint';
import Blog from "./components/Pages/Blog";
import Post from "./components/Blog/Categories/Posts/Post.jsx"
import WebDesign from "./components/Pages/WebDesign"
import AboutUs from "./components/Pages/AboutUs";
import ECommerce from './components/Pages/ECommerce/ECommerce';
import SuccessStoryBw from "./components/Pages/SuccessStoryBw/SuccessStoryBw"
import SuccessStoryShort from "./components/Pages/SuccessStoryShort/SuccessStoryShort"
import CorporateWebsites from './components/Pages/CorporateWebsites/CorporateWebsites';
import Magento from './components/Pages/Magento/Magento';
import Wordpress from './components/Pages/Wordpress/Wordpress';
import Shopware from './components/Pages/Shopware/Shopware';
import Usercentrics from './components/Pages/Usercentrics/Usercentrics';
import { Helmet } from 'react-helmet';

class App extends Component {

    state = {
        headerOptions: {
            pageId: '',
            darkLogo: false,
            darkNav: false
        },
        contactButton: true,
        contactBlock: true,
        preventContactBlockAnimation: false,
        certificates: {
            shopware_developer: {},
            shopware_designer: {},
            usercentrics: {},
        },
    };

    setContactElements = (
        statusButton = true,
        statusBlock = true,
        animationStatus = true
    ) => {
        this.setState({
            contactButton: statusButton,
            contactBlock: statusBlock,
            preventContactBlockAnimation: animationStatus,
        });
    };

    setHeaderOptions = (
        pageId = '',
        logoStatus = false,
        menuButtonStatus = false
    ) => {
        this.setState({
            headerOptions: {
                ...this.state.headerOptions,
                darkLogo: logoStatus,
                darkNav: menuButtonStatus,
                pageId: pageId,
            },
        });
    };

    loadCertificatesData = async () => {
        try {
            const certificatesDataResponce = await axios.get(API.basePath+'/wp-json/acf/v3/options/theme-general-settings')
            this.setState({
                certificates: certificatesDataResponce.data.acf.certificates_items,
            });
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.loadCertificatesData()
    }

    render() {
        return (
            <CertificatesContext.Provider value={this.state.certificates}>
               <Helmet>
                    <script>
                    {`if(window.location.pathname=="/imprint" || window.location.pathname=="/privacy-policy") {
                        window.UC_UI_SUPPRESS_CMP_DISPLAY=true
                    }`}
                    </script>
                   <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id="1RUVM4r7P" async></script>
                    {process.env.REACT_APP_GTM_ID && (
                        <script type="text/plain" data-usercentrics="Google Tag Manager">{(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer',process.env.REACT_APP_GTM_ID)}</script>
                    )}
               </Helmet>
                <div id='page' className='page'>
                    <Router history={history}>
                        <ScrollToTop setHeaderOptions={this.setHeaderOptions} />
                        <Header options={this.state.headerOptions} />
                        <div className='page__content'>
                            <Switch>
                                {/* redirect (remove slash) if url has a trailing slash */}
                                {history.location.pathname.endsWith('/') && <Redirect from="/:url*(/+)" to={history.location.pathname.replace(/\/+$/, "")} />}
                                <Route
                                    exact path="/"
                                    render={() => (
                                        <StartPage
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/it-support"
                                    render={() => (
                                        <ItBetreuung
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route exact path="/web-design" component={WebDesign} />
                                <Route
                                    exact path="/projects/bw-online-shop"
                                    render={() => (
                                        <SuccessStoryBw
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/projects/office-people"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'office-people'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/projects/volve"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'volve'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/projects/olymp"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'olymp'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/projects/kostueme"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'kostueme'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/projects/fameonme"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'fameonme'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/projects/atrava"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'atrava'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/projects"
                                    render={() => (
                                        <Referenzen
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                {/* <Route exact path="/blog" render={props => (<Blog {...props} />)} />
                                <Route path="/blog/:slug" render={props => (<Blog {...props} setContactElements={
                                    this.setContactElements} setHeaderOptions={this.setHeaderOptions} />)} />*/}
                                <Route
                                    exact path="/contact"
                                    render={() => (
                                        <Contact
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/hosting"
                                    render={() => (
                                        <Hosting
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/partners"
                                    render={() => (
                                        <Partner
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/web-development/magento"
                                    render={() => (
                                        <Magento
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/web-development/wordpress"
                                    render={() => (
                                        <Wordpress
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/web-development/shopware"
                                    render={() => (
                                        <Shopware
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/web-development"
                                    render={() => (
                                        <SoftwareEntwicklung
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/about-us'
                                    render={() => (
                                        <AboutUs
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route 
                                    exact path="/e-commerce" 
                                    render={() => (
                                        <Redirect to="/online-shops"/>
                                    )}
                                />
                                <Route
                                    exact path='/online-shops'
                                    render={() => (
                                        <ECommerce
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route 
                                    exact path="/gdpr-cookie-consent" 
                                    render={() => (
                                        <Redirect to="/cookie-banner-integration"/>
                                    )}
                                />
                                <Route
                                    exact path='/cookie-banner-integration'
                                    render={() => (
                                        <Usercentrics
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/corporate-websites'
                                    render={() => (
                                        <CorporateWebsites
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/imprint'
                                    render={() => (
                                        <Imprint
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path={[
                                        '/privacy-policy',
                                        '/terms-and-conditions',
                                        '/dpa',
                                        '/sma',
                                        '/amtc'
                                    ]}
                                    render={(props) => (
                                        <TextPage
                                            key={props.location.pathname}
                                            {...props}
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    path='/:cvpage'
                                    render={(props) => (
                                        <CVPage
                                            {...props}
                                            setHeaderOptions={this.setHeaderOptions}
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route path='*' component={NotFound} />
                            </Switch>
                        </div>
                        <Footer
                            contactButtonStatus={this.state.contactButton}
                            contactBlockStatus={this.state.contactBlock}
                            preventContactBlockAnimation={
                                this.state.preventContactBlockAnimation
                            }
                        />
                    </Router>
                </div>
            </CertificatesContext.Provider>
        );
    }
}

export default App;
