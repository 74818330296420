import React from 'react';

const OnlineShops = (props) => {
    const content = props.content;
    const {parallax} = props;

    return (
        <section className='konzeption anim-item'>
            <div className='number konzeption__number'>1</div>
            <div className='e-commerce-page__title'>
                <h2 className='e-commerce-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                <div className='e-commerce-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
            </div>
            <div className="e-commerce-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
            <div className="konzeption__image e-commerce-page__image">
                <div className="laptop fadeInRight">
                    <img style={{transform: parallax(1, 1)}} src={content.laptop_image.url} alt={content.laptop_image.alt} />
                </div>
                <div className="tablet fadeInRight">
                    <img style={{transform: parallax(1, -1)}} src={content.tablet_image.url} alt={content.tablet_image.alt} />
                </div>
                <div className="phone fadeInRight">
                    <img style={{transform: parallax(-1, 1)}} src={content.phone_image.url} alt={content.phone_image.alt} />
                </div>
            </div>
        </section>
    )
};

export default OnlineShops;
