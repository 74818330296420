import React from "react";

const WhoWeAre = (props) => {
    return (
        <div className="who-we-are">
            <div className="who-we-are__text-container">
                <div className="who-we-are__title">   
                    <h2 className="who-we-are__title_text">{props.content.title}</h2>
                    <div className="who-we-are__title_bg">{props.content.title}</div>
                </div>
                <div className="who-we-are__info" dangerouslySetInnerHTML={{ __html: props.content.text}}></div>
            </div>
            <img className="who-we-are__img" src={props.content.image.url} alt={props.content.image.alt} />
            <div className="who-we-are--bottom-bg"></div>
        </div>
    )
};

export default WhoWeAre;
