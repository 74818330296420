import React, { useEffect, useState } from 'react'

import "./SuccessStoryShort.scss"

import API from '../../../api';

import axios from 'axios';

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import Loader from "../../Loader/Loader";

import Header from '../../SuccessStory/Header/Header';

import ClientReview from '../../SuccessStory/ClientReview/ClientReview';

import ServicesSuccess from '../../SuccessStory/ServicesSuccess/ServicesSuccess';

import PageOnScreen from '../../SuccessStory/PageOnScreen/PageOnScreen';

const SuccessStoryShort = (props) => {

    const [page_data, setPage_data] = useState({});
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);

    useEffect(() => {
        axios.get(API.basePath + '/wp-json/wp/v2/pages?slug='+props.pageSlug)
            .then(response => {
                setPage_data(response.data[0].acf);
                setYoastData(response.data[0]);
                setLoaded_status(true);
            })
            .catch(error => {
                console.log(error);
            });

        props.setContactElements();
    }, [])

    const setYoastData = (data) => {
        setYoast_meta(data);
    }

    const canAnimate = (element) => {
        return (element.top + element.height > 0) && (window.innerHeight - element.top > 0)
    }

    if (!loaded_status) {
        return (
            <div className="success-stories-loader">
                <Loader />
            </div>
        );
    } else {
        return (
            <>
                <PageMeta metaData={yoast_meta} hreflangs={page_data['hreflang_list']}/>
                <CustomSchema schema={page_data.schema} />
                <div className="success-stories_short">
                    {page_data.banner &&  <Header contentLeft={page_data.banner.left_side} contentRight={page_data.banner.right_side} isSuccessStoryShort />}
                    {page_data.client_review && <ClientReview content={page_data.client_review} />}
                    {page_data.services && <ServicesSuccess content={page_data.services} />}
                    {page_data.pages__screens && page_data.pages__screens.page_screen &&
                        <PageOnScreen content={page_data.pages__screens} canAnimate={canAnimate} />
                    }
                </div>
            </>
        )
    }
}

export default SuccessStoryShort;
