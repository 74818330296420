import React from 'react';
import Swiper from 'react-id-swiper';
import SliderItem from './SliderItem';

import 'swiper/swiper.scss';

const TextSlider = (props) => {

    // Slider options
    const params = {
        spaceBetween: 0,
        loop: true,
        slidesPerView: 1,
        loopedSlides: 1, //looped slides should be the same
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        observer: true,
        speed: 0,
        autoplay: {
            delay: 7000,
            disableOnInteraction: true,
        },
        rebuildOnUpdate: true,
        shouldSwiperUpdate: true,
        runCallbacksOnInit: true,
    };

    if (props.slides.length === 1) {

        return (
            <>
                <div className="start-banner__single-slide">
                    <SliderItem content={props.slides[0]}/>
                </div>
            </>
        );

    } else {

        return (
            <>
                <Swiper {...params}>
                    {
                        props.slides.map((item, key) => {

                            return (
                                <div key={key}>
                                    <SliderItem content={item}/>
                                </div>
                            )
                        })
                    }
                </Swiper>
            </>
        );
    }
};

export default TextSlider;