import React, { Component } from 'react'

import './UxDesign.scss'
export default class UxDesign extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    state = {
        transform: 'none',
        width: 50,
        transition: false
    }

    mouseMove(e) {
        let w, h
        w = window.innerWidth
        h = window.innerHeight

        if (w > 992) {
            this.myRef.current.classList.remove('transition')

            let x,
                y,
                rotateX,
                moveY,
                boxPos = this.myRef.current.offsetTop

            if (e.pageX || e.pageY) {
                x = e.pageX
                y = e.pageY
            } else if (e.clientX || e.clientY) {
                x =
                    e.clientX +
                    document.body.scrollLeft +
                    document.documentElement.scrollLeft
                y =
                    e.clientY +
                    document.body.scrollTop +
                    document.documentElement.scrollTop
            }

            y = -(boxPos - y)

            rotateX = (w / 2 - x) / 1000 / 4
            moveY = (y - h / 2) / 25

            let movePercent = ((w / 2 - x) * 100) / w / 3

            this.setState({
                transform:
                    'matrix3d(' +
                    Math.cos(rotateX) +
                    ', 0, ' +
                    Math.sin(rotateX) +
                    ', 0, 0, 1, 0, 0, ' +
                    Math.sin(rotateX) +
                    ', 0, ' +
                    Math.cos(rotateX) +
                    ', 0 , 0, ' +
                    moveY +
                    ', 0, 1)',
                width: 50 + movePercent
            })

            if (this.state.transition) {
                this.setState({
                    transition: !this.state.transition
                })
            }
        }
    }

    mouseLeave() {
        this.setState({
            transform: 'none',
            width: 50,
            transition: !this.state.transition
        })
    }

    render() {
        const { left_side, right_side } = this.props.content

        return (
            <section
                className={`design${
                    this.state.transition ? ' transition' : ''
                }`}
                ref={this.myRef}
                onMouseMove={this.mouseMove.bind(this)}
                onMouseLeave={this.mouseLeave.bind(this)}
            >
                <div className="design__bg view">
                    <div
                        className="view__wrapper view__wrapper--left"
                        id="viewBefore"
                        style={{ width: this.state.width + '%' }}
                    >
                        <div className="view__item">
                            <div className="view__gears view__gears--bottom"></div>
                            <div className="view-img loading-block">
                            <img
                                    src={right_side.image.img.url}
                                    alt={right_side.image.alt}
                                    style={{ transform: this.state.transform }}
                                    id="imgAfter"
                                /> 
                            </div>
                        </div>
                    </div>
                    <div className="view__wrapper view__wrapper--right">
                        <div className="view__item">
                            <div className="view__gears view__gears--top"></div>
                            <div className="view-img view__bg-img--ux loading-block">
                            <img
                                    src={left_side.image.img.url}
                                    alt={left_side.image.alt}
                                    style={{ transform: this.state.transform }}
                                    id="imgBefore"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="design__wrapper">
                    <div className="design__item design__item--left loading-block loading-block--2">
                        <div className="design__item-body">
                            <h2
                                className="design__title"
                                dangerouslySetInnerHTML={{
                                    __html: left_side.title
                                }}
                            ></h2>
                            <div
                                className="design__content"
                                dangerouslySetInnerHTML={{
                                    __html: left_side.description
                                }}
                            ></div>
                            <div className="design__img design__img--ui">
                                <img
                                    src={right_side.image.img_mobile.url}
                                    alt={right_side.image.alt}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="design__item design__item--right loading-block loading-block--2">
                        <div className="design__item-body">
                            <h2
                                className="design__title"
                                dangerouslySetInnerHTML={{
                                    __html: right_side.title
                                }}
                            ></h2>
                            <div
                                className="design__content"
                                dangerouslySetInnerHTML={{
                                    __html: right_side.description
                                }}
                            ></div>
                            <div className="design__img design__img--ux">
                                <img
                                    src={left_side.image.img_mobile.url}
                                    alt={left_side.image.alt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
