import React, {Component} from "react";

import {Link} from 'react-scroll';
import TextSlider from "./TextSlider/TextSlider";

import axios from "axios";
import API from "../../api";

import './MainBanner.scss';

import Loader from "../Loader/Loader";

import Plx from 'react-plx';

class MainBanner extends Component {

    state = {
        partners_logotypes: [],
        data_load_status: false
    };

    componentDidMount() {

        // Get partners logos
        axios.get(API.basePath+'/wp-json/acf/v3/posts/69')
            .then(response => {
                this.setState({
                    partners_logotypes: response.data.acf.partners_logotypes,
                    data_load_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {

        if (this.props.content) {

            let logos = '';

            if(this.props.logotypes) {
                logos = <div className="partners-logos loading-block loading-block--4">
                    {
                        this.props.logotypes.map((item, key) =>
                            <div className="partners-logos__item" key={item.ID}>
                                <img src={item.url} alt={item.alt} />
                            </div>
                        )
                    }
                </div>;
            }

            const dataRightTopParallax = [
                {
                    start: 'self',
                    duration: 900,
                    properties: [
                        {
                            startValue: 0,
                            endValue: 30,
                            unit: '%',
                            property: 'translateY',
                        },
                    ],
                },
            ];

            return (
                // <!-- start .start-banner -->
                <div className={`start-banner start-banner--${this.props.id || null}`}>
                    <Plx className="banner-image loading-block loading-block--2" parallaxData={dataRightTopParallax}>
                        <img
                            src={this.props.content.photo_front_side.url}
                            alt={this.props.content.photo_front_side.alt}
                        />
                    </Plx>

                    <div className={`start-banner__slider loading-block loading-block--3 start-banner__slider--${this.props.id}`}>
                        <TextSlider slides={this.props.content.slide}/>
                    </div>

                    {logos}

                    <div className="scroll-down loading-block loading-block--4">
                        <Link activeClass="active" className="scroll-down__btn" to="scrollPosition" spy={true} smooth={true} offset={0} duration={500} onSetActive={this.handleSetActive}>
                            <i className="scroll-down__btn-i">
                                <svg width="10" height="35" viewBox="0 0 10 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.00391 1.14282V6.85711" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1 30L5 34L9 30" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </i>
                        </Link>
                    </div>
                </div>
                // <!-- end .start-banner -->
            );

        } else {

            return (
                // <!-- start .start-banner -->
                <div className={`start-banner`}>
                    <Loader />
                </div>
                // <!-- end .start-banner -->
            );
        }


    }
}

export default MainBanner;
