import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

const Why = (props) => {
    const { title, text, image } = props.content;

    return (
        <section className='why'>
            <ParallaxProvider>
                <div className="number number--right">7</div>
                <div className="why__text-container">
                    <div className='usercentrics-page__title'>
                        <h2 className='usercentrics-page__title-text' dangerouslySetInnerHTML={{ __html: title.text }} />
                        <div className='usercentrics-page__title-bg' dangerouslySetInnerHTML={{ __html: title.background }} />
                    </div>
                    <div className="usercentrics-page__text" dangerouslySetInnerHTML={{ __html: text }} />
                </div>
                <div className="why__image">
                    <Parallax
                        translateY={[10, -10]}
                        className="why__image--phone"
                    >
                        <img src={image.phone.url} alt={image.phone.alt}/>
                    </Parallax>
                    <img className="why__image--laptop" src={image.laptop.url} alt={image.laptop.alt}/>
                </div>
            </ParallaxProvider>
        </section>
    )
};

export default Why;
