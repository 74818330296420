import React, { useState, useEffect, useRef, Fragment } from 'react';

import NotFound from '../../../Pages/NotFound/NotFound';

import PostImages from './PostImages';

import PostCategory from './PostCategory';

import PostList from './PostList';

import { withRouter } from 'react-router';

import axios from 'axios';

import API from '../../../../api';

import '../Posts/Post.scss';

import CategoriesList from '../CategoriesList';

import Tags from './Tags/Tags';

import AuthorPost from './AuthorPost/AuthorPost';

import SocialLink from './SocialLink/SocialLink'

import ButtonForShare from './SocialLink/ButtonForShare'

import { Link } from 'react-router-dom';

import { scroller } from "react-scroll";

import PageMeta from "../../../PageMeta/PageMeta"

import Loader from '../../../Loader/Loader';

import { Html5Entities } from 'html-entities';

import dateFormat from "dateformat";

const htmlEntities = new Html5Entities();



const Post = (props) => {
    const { history } = props;
    const { slug } = props.match.params;
    const contentRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);
    const [pageData, setPageData] = useState([]);
    const [posts, setPosts] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [categories, setCategories] = useState([]);
    const [dataStatus, setDataStatus] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const changedScrollPosition = () => {
        if (contentRef.current) {
            const distanceToTop = contentRef.current.getBoundingClientRect().top;
            if (distanceToTop > 0) {
                setIsActive(false)
            } else {
                setIsActive(true)
                setIsVisible(true)
            }
        }
    }

    const getWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        const API_POST_PER_PAGE = 3;
        const offset = currentPage * API_POST_PER_PAGE;
        axios
            .get(API.basePath + '/wp-json/wp/v2/posts?_embed=true&slug=' + slug)
            .then((response) => {
                setPageData(response.data);
                setYoast_meta(response.data[0]);
                setDataStatus(true);
            })
            .catch((error) => {
                console.log(error);
            });

        axios
            .get(
                API.basePath +
                '/wp-json/wp/v2/posts?per_page=' +
                API_POST_PER_PAGE +
                '&offset=' +
                offset
            )
            .then((response) => {
                setPosts(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        axios
            .get(API.basePath + '/wp-json/wp/v2/categories?slug')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        window.addEventListener("scroll", changedScrollPosition);
        window.addEventListener("resize", getWindowWidth);
        return () => {
            window.removeEventListener("scroll", changedScrollPosition);
            window.removeEventListener("resize", getWindowWidth);

        };
    }, [currentPage, slug]);

    useEffect(() => {
        getWindowWidth()
    }, [])

    let scrollOnChangeCategoryPost = () => {
        scroller.scrollTo("articles", {
            duration: 700,
            delay: 0,
            smooth: "easeOutCubic",
        })
    }

    if (!dataStatus) {
        return <div className="blog-page-loader">
            <Loader />
        </div>
    } else {
        if (pageData.length > 0) {

            return (
                <>
                    <PageMeta metaData={yoast_meta} />
                    <section className="post">
                        {pageData.map((item, index) => {
                            const { date } = item
                            const day = dateFormat(date, `dS`);
                            const month = dateFormat(date, `mmmm`);
                            const year = dateFormat(date, `yyyy`);


                            const title = item.title.rendered[0].toUpperCase() + item.title.rendered.slice(1);
                            return (
                                <Fragment key={item.id}>
                                    <div className="post__header">
                                        <div className="post__header-item post">
                                            <h1 className="post-title">{htmlEntities.decode(title)}</h1>
                                            <PostCategory post={item.id} isBig />
                                            <div className="post-date">
                                                <span>{day} {month.toUpperCase()} {year}</span>
                                            </div>
                                            {windowWidth > 992 ?
                                                <div className="post-social-links">
                                                    {item.acf.social ?
                                                        item.acf.social.map((link) => {
                                                            return <SocialLink link={link} key={link.icon.id} />
                                                        }) : null
                                                    }
                                                </div>
                                                :
                                                <ButtonForShare
                                                    link={item.acf.social_share_header}
                                                    className={"share-button"} />
                                            }
                                            <Tags
                                                postId={item.id}
                                                windowWidth={windowWidth}
                                                getWindowWidth={getWindowWidth} />
                                        </div>
                                        <div className="post__header-item post__header-item-img">
                                            <PostImages
                                                postId={item.featured_media}
                                                isBig />
                                        </div>
                                    </div>
                                    <div className="post__content" id="post__content" ref={contentRef}>
                                        <div className="post__content-left">
                                            {windowWidth > 992 ?
                                                <div className={`social-links--fixed ${isVisible ? " social-links--fixed-visible" : " "}${isActive ? " social-links--fixed-fixed" : " "} `}>
                                                    {item.acf.social_footer ?
                                                        item.acf.social_footer.map((link) => {
                                                            return <SocialLink link={link} key={link.icon.id} />
                                                        }) : null
                                                    }
                                                </div>
                                                :
                                                ''
                                            }
                                        </div>
                                        <div className="post__content-inner">
                                            <div dangerouslySetInnerHTML={{ __html: item.content.rendered }} />
                                            <AuthorPost author={item._embedded.author[0]} />
                                            <Tags
                                                postId={item.id}
                                                windowWidth={windowWidth}
                                                getWindowWidth={getWindowWidth} />
                                            {windowWidth < 992 ?
                                                <ButtonForShare
                                                    link={item.acf.social_share_footer}
                                                    className={"share-button__footer"} />
                                                :
                                                ''
                                            }
                                        </div>
                                        <div className="post__content-right">
                                            <div className="post__content-right-categories">
                                                <h2 className="post__content-right-title title-categories">
                                                    <Link to="/blog">other categories</Link>
                                                </h2>
                                                <CategoriesList
                                                    scrollOnChangeCategory={scrollOnChangeCategoryPost}
                                                    categories={categories}
                                                    isBig
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post__content__last-posts">
                                        <h2 className="post__content-right-title title-post">
                                            The last posts
                                                </h2>
                                        <PostList post={posts} />
                                    </div>
                                </Fragment>
                            );
                        })}
                    </section>
                </>
            );
        } else {
            return (
                <NotFound setContactElements={props.setContactElements} />
            )
        }

    }
};
export default withRouter(Post);
