import React from "react";
import './Support.scss';

const Support = ({data: {support}, changeBackground = false}) => {

    if (!support) {
        return null;
    }

    return (
        <div className={`support ${changeBackground ? 'background-gray' : ''}`}>
            <div className="support--bottom">
                 <div className='support__title'>
                    <h2 className='support__title-text' dangerouslySetInnerHTML={{__html: support.title}}/>
                </div>
                <div className="support__text" dangerouslySetInnerHTML={{__html: support.text}}/>
                <a href={support.button.url} className="support__button">
                    {support.button.title}
                </a>
            </div>
        </div>
    )
}

export default Support;