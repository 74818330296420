import React from 'react';

const Review = (props) => {

    const reviewData = props.data;

    const reviewStructedData = {
        "@context": "https://schema.org/",
        "@type": "Review",
        "itemReviewed": {
            "@type": "Organization",
            "name": "alkima WEB & DESIGN ®",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Phoenix Business Centre, The Penthouse, Old Railway Track",
                "addressLocality": "Santa Venera",
                "postalCode": "SVR 9022",
                "addressCountry": "MT"
            },
        },
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5"
        },
        "name": `${reviewData.review_name}`,
        "author": {
            "@type": "Person",
            "name": `${reviewData.client_name}`
        },
        "reviewBody": `${reviewData.client_review.replace(/(<([^>]+)>)/ig, '')}`
    }

    if (reviewData) {
        return (
            <>
                <script className='structured-data-list' type="application/ld+json">
                    {JSON.stringify(reviewStructedData)}
                </script>
                <div className="hosting-review">
                    <div className="hosting-review__photo">
                        <img src={reviewData.client_photo.url} alt={reviewData.client_photo.alt} />
                    </div>
                    <div className="hosting-review__author-name">
                        {reviewData.client_name}
                    </div>
                    <div className="hosting-review__author-position">{reviewData.client_position}</div>
                    <div className="hosting-review__text" dangerouslySetInnerHTML={{__html: reviewData.client_review}}/>
                    <div className="hosting-review__project-logo">
                        <img src={reviewData.logo.url} alt={reviewData.logo.alt} />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <></>
        );
    }

};

export default Review;
