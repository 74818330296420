import React from "react";
import "../SoftwareEntwicklung.scss"

const TechnologiesList = ({data, context}) => {
    const {shopware_developer, shopware_designer} = context;
    
    if (!data) {
        return null;
    }

    return (
        <div className="software-page__list">
            {
                data.technologies_list.map((item, key) => {
                    let technology = item.technology;
                    return (
                        <div className={`software-page__item software-page__item--${technology.slug}`} key={key}>
                            <div className="software-page__item-icon-bg"
                                 dangerouslySetInnerHTML={{__html: technology.icon}}/>
                            <div className="software-page__item-text-bg"
                                 dangerouslySetInnerHTML={{__html: technology.text_background}}/>
                            <div className="software-page__item-icon"
                                 dangerouslySetInnerHTML={{__html: technology.icon}}/>
                            <h3 className="software-page__item-title"
                                dangerouslySetInnerHTML={{__html: technology.title}}/>
                            <div className="software-page__item-subtitle"
                                 dangerouslySetInnerHTML={{__html: technology.subtitle}}/>
                            <div className="software-page__item-description"
                                 dangerouslySetInnerHTML={{__html: technology.description}}/>
                            {technology.button &&
                                <a className="software-page__item-button" href={technology.button.url}>
                                    <span className="base-btn">{technology.button.title}</span>
                                </a>
                            }
                            {technology.slug === 'shopware' &&
                                <div className="software-page__services">
                                    <img src={shopware_developer.url} alt={shopware_developer.alt}/>
                                    <img src={shopware_designer.url} alt={shopware_designer.alt}/>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TechnologiesList;