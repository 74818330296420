import React from 'react'

const ButtonForShare = (props) => {

    const { link, className } = props

    const shareButton = () => {

        if (navigator.share) {
            navigator.share({
                title: link.title,
                text: link.title,
                url: window.location.href
            })

                .catch(function () {
                    console.log("Sharing failed")
                })

        } else {
            console.log("Sorry! Your browser does not support Web Share API")
        }
    }


    if (link) {
        return (
            <div id="share-button" className={className} onClick={shareButton}>
                <img src={link.url} alt={link.title} />
            </div>
        )
    } else {
        return null
    }
}

export default ButtonForShare
