import React, { useState } from "react"

import useMobile from "../../../../hooks/useMobile"

const Founders = (props) => {
    const isMobile = useMobile();

    const marco = props.content.marco
    const kian = props.content.kian

    const [blockWidth, setBlockWidth] = useState(50);
    const [activeBlock, setActiveBlock] = useState(null);

    const mouseMove = (e) => {
        let w = window.innerWidth;

        if (w > 992) {
            let x, movePercent

            if (e.pageX) {
                x = e.pageX;
            } else if (e.clientX) {
                x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            }

            if (x > w / 2) {
                movePercent = (((x - w / 2) * 100) / w) / 3;
                setBlockWidth(50 + movePercent)
            } else {
                movePercent = (((w / 2 - x) * 100) / w) / 3;
                setBlockWidth(50 + movePercent)
            }
        }
    }

    return (
        <div className="founders" onMouseMove={(e) => mouseMove(e)}>
            {!isMobile &&
                <h2 className="founders__title">{props.content.title}</h2>
            }

            <div className="founders__item marco"
                onMouseEnter={() => setActiveBlock('left')}
                onMouseLeave={() => setActiveBlock(null)}
            >
                {isMobile ?
                    <img className="founders__image" src={marco.mobile_image.url} alt={marco.mobile_image.alt} />
                    :
                    <>
                        <img className="founders__image" src={marco.desktop_background.url} alt={marco.desktop_background.alt} />
                        <img className="founders__portrait" src={marco.portrait.url} alt={marco.portrait.alt} />
                        <img className="founders__bg-element" src={marco.background_element.url} alt={marco.background_element.alt} />
                    </>
                }
                <div className="founders__content">
                    <div className="founders__position">
                        <div className="founders__position_name">{marco.name}</div>
                        <div className="founders__position_left">{marco.position_left}</div>
                        <div className="founders__position_right">{marco.position_right}</div>
                    </div>
                    <div className="founders__text" dangerouslySetInnerHTML={{ __html: marco.text }}></div>
                </div>
            </div>

            <div className="founders__item kian"
                onMouseEnter={() => setActiveBlock('right')}
                onMouseLeave={() => setActiveBlock(null)}
            >
                {isMobile ?
                    <img className="founders__image" src={kian.mobile_image.url} alt={kian.mobile_image.alt} />
                    :
                    <>
                        <img className="founders__image" src={kian.desktop_background.url} alt={kian.desktop_background.alt} />
                        <img className="founders__portrait" src={kian.portrait.url} alt={kian.portrait.alt} />
                        <img className="founders__bg-element" src={kian.background_element.url} alt={kian.background_element.alt} />
                    </>
                }
                <div className="founders__content">
                    <div className="founders__position">
                        <div className="founders__position_name">{kian.name}</div>
                        <div className="founders__position_left">{kian.position_left}</div>
                        <div className="founders__position_right">{kian.position_right}</div>
                    </div>
                    <div className="founders__text" dangerouslySetInnerHTML={{ __html: kian.text }}></div>
                </div>
            </div>

            { !isMobile &&
                <>
                    <div
                        className={`founders__overlay founders__overlay--left
                            ${activeBlock === 'left' ? ' active' : ''}
                        `}
                        style={{ width: blockWidth + '%' }}
                    />
                    <div
                        className={`founders__overlay founders__overlay--right
                            ${activeBlock === 'right' ? ' active' : ''}
                        `}
                        style={{ width: blockWidth + '%' }}
                    />
                    {/* ${transition ? ' transition' : ''} */}
                </>
            }
        </div>
    )
};

export default Founders;
