import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import API from '../../../api';

import Loader from "../../Loader/Loader";
import Home from "../../Blog/Home";
import Post from "../../Blog/Categories/Posts/Post";


const BlogSwitcher = (props) => {
    const { slug } = props.match.params;
    const [loaded_status, setLoaded_status] = useState(false);
    const [categorySlug, setCategorySlug] = useState();

    useEffect(() => {
        axios.get(API.basePath + '/wp-json/wp/v2/pages?slug=blog')
            .then(response => {
                setCategorySlug(response.data[0].acf.categories.map((item) => {
                    return item.slug
                }));
                setLoaded_status(true)
            })
            .catch(error => {
                console.log(error);
            });
    }, [])


    if (!loaded_status) {
        return (
            <div className="blog-page-loader">
                <Loader />
            </div>
        );
    } else {

        const isCategory = categorySlug.filter(item => item === slug);

        if(!slug || (slug && isCategory.length > 0)) {
            return <Home />
        } else {
            return <Post setContactElements={props.setContactElements} setHeaderOptions={props.setHeaderOptions}/>
        }
    }

}

export default withRouter(BlogSwitcher);