import {useState, useEffect} from 'react';

const useScrollAnimation = (animItem) => {
    const [scrollTop, setScrollTop] = useState(0)

    function scrollAnimation() {
        if(animItem) {
            let scrolledPx =
                window.innerHeight -
                animItem.getBoundingClientRect().top -
                animItem.offsetHeight

            if (scrolledPx > 0) {
                setScrollTop(scrolledPx)
            } else {
                setScrollTop(0)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollAnimation)
    
        return () => {
            window.removeEventListener('scroll', scrollAnimation)
        }
    })

    return scrollTop
}

export default useScrollAnimation
