import React, { useEffect, useState } from 'react';

import ReactPaginate from 'react-paginate';

import queryString from 'query-string'

import { scroller } from 'react-scroll';

import axios from 'axios';

import API from '../../../api';

import Articles from './Articles/Articles';

import CategoriesList from './CategoriesList';

import { withRouter } from 'react-router-dom';

import './Categories.scss';

import "./Pagination/Pagination.scss"

import Loader from '../../Loader/Loader';

const API_POST_PER_PAGE = 9;

const Categories = (props) => {

  const isDesktop = window.innerWidth > 992;
  const queryVars = queryString.parse(props.history.location.search);
  const { activeCategorySlug, content, history, match } = props;
  const { page, tag } = queryVars;
  const { upload_button_name } = props.content;
  const [windowWidth, setWindowWidth] = useState(0);
  const [categories, setCategories] = useState([]);
  const [loadedStatus, setLoadedStatus] = useState(false);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(0);
  const [tagName, setTagName] = useState('');
  const { slug } = match.params;
  const [isNotFirstRequest, setIsNotFirstRequest] = useState(false);

  const { category_title, posts_content } = props.content

  useEffect(() => {

    const offset = page ? API_POST_PER_PAGE * (page - 1) : 0;

    if (slug) {
      axios.get(API.basePath + `/wp-json/wp/v2/categories?slug=${slug}`).then((response) => {
        if (response.data[0]) {
          fetchPosts({ categories: response.data[0].id, offset, page });
        }
      })
    } else if (tag) {
      axios.get(API.basePath + `/wp-json/wp/v2/tags?slug=${tag}`).then((response) => {
        if (response.data[0]) {
          setTagName(response.data[0].name)
          fetchPosts({ tags: response.data[0].id, offset, page });
        }
      })

    } else {
      fetchPosts({ offset, page });
    }


  }, [page, slug, tag]);


  const getWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    fetchCategories()
    window.addEventListener("resize", getWindowWidth);

    return () => {
      window.removeEventListener("resize", getWindowWidth);
    };
  }, [])


  const fetchPosts = async (queryObj) => {


    setLoadedStatus(false);
    let offset = page ? API_POST_PER_PAGE * (page - 1) : 0;
    let per_page = API_POST_PER_PAGE;

    const queryData = {
      per_page,
      offset: offset,
      ...queryObj
    };

    const getQuery = queryString.stringify(queryData);
    const postsUrl = `${API.basePath}/wp-json/wp/v2/posts?${getQuery}`;
    try {
      const postsResult = await axios.get(postsUrl);

      setTotal(postsResult.headers["x-wp-total"]);
      setPosts(postsResult.data)

      if (window.innerWidth < 992 && postsResult.data.length > 0) {

        let result = [];
        if (queryObj.page) {
          result = isNotFirstRequest ? [...postsResult.data, ...posts] : [...posts, ...postsResult.data]

        } else {
          result = [...postsResult.data];
        }

        setPosts(result)
      }

    } catch (e) {
      console.log(e);


    } finally {
      setLoadedStatus(true);
    }

  }

  const fetchCategories = async () => {
    try {
      const categoriesResult = await axios.get(API.basePath + "/wp-json/wp/v2/categories?slug=");
      setCategories(categoriesResult.data)
      setIsNotFirstRequest(false);
    }
    catch (e) {
      console.log(e);
    }
  }

  const getAllPosts = () => {
    props.history.push(`/blog`);
    setIsNotFirstRequest(false);
    scroller.scrollTo("articles", {
      duration: 700,
      delay: 0,
      smooth: "easeOutCubic",
    });
  }

  const handlePageClick = ({ selected }) => {
    const query = { page: selected + 1 };
    if (tag) {
      query.tag = tag
    }
    history.push({
      search: `?${queryString.stringify(query)}`
    })
    if (isDesktop) {
      scrollOnChangeCategory()
    }
  }

  const handlePageClickTop = ({ selected }) => {
    setIsNotFirstRequest(true);
    handlePageClick({ selected })
  }

  let scrollOnChangeCategory = () => {
    scroller.scrollTo("articles", {
      duration: 700,
      delay: 0,
      smooth: "easeOutCubic",
    })
  }

  const pagesQuantity = Math.ceil(total / API_POST_PER_PAGE);

  const isLastPage = pagesQuantity === parseInt(page);
  const hideMobilePagination = isLastPage && !isDesktop;
  const isNotFirstPage = page !== undefined && page != 1;
  const disableCondition = posts.length < API_POST_PER_PAGE;

  const showPrevLabel = () => {
    const postLength = (posts.length <= API_POST_PER_PAGE || (total - posts.length) > 0) && !(posts.length == API_POST_PER_PAGE * page)
    if (!isDesktop && isNotFirstPage && postLength) {
      return true
    }
  }

  const showNextLabel = () => {
    if (posts.length && !hideMobilePagination && !isNotFirstRequest) {
      return true
    }
  }


  return (
    <section className="categories">
      <div className="categories__wrapper">
        <div className="categories__title__wrapper" onClick={() => getAllPosts()}>
          <h2 className="categories__title" >{category_title}</h2>
          <span className="title-back title-back--white">{category_title}</span>
        </div>
        <CategoriesList
          scrollOnChangeCategory={scrollOnChangeCategory}
          categories={props.categories}
        />
      </div>
      {showPrevLabel() ?
        <div style={{ position: 'relative' }}>
          <ReactPaginate
            previousLabel={"Load more"}
            nextLabel={false}
            breakLabel={false}
            pageCount={pagesQuantity}
            onPageChange={handlePageClickTop}
            previousClassName={'pagination__mobile pagination__mobile--top'}
            forcePage={page ? page - 1 : 0}
            pageCount={false}
          />
          {loadedStatus ? null : <Loader />}
        </div>
        :
        null}
      <Articles
        loadedStatus={loadedStatus}
        posts={posts}
        title={tag ? `All articles on the topic of #${tagName}` : posts_content.title}
        activeCategory={categories.filter(category => category.slug === slug)}
      />


      { showNextLabel() ?
        <div style={{ position: 'relative' }}>
          <ReactPaginate
            previousLabel={isDesktop ? <i className="icon-angle-left" /> : false}
            nextLabel={isDesktop ? <i className="icon-angle-right" /> : upload_button_name}
            breakLabel={isDesktop ? '...' : false}
            breakClassName={'break-me'}
            pageCount={pagesQuantity}
            onPageChange={handlePageClick}
            containerClassName={`pagination ${(disableCondition && !page) ? "disable" : " "}`}
            pageClassName={`${(disableCondition && !page) ? "pagination__disable" : "pagination__item"}`}
            subContainerClassName={'pages pagination'}
            activeClassName={'pagination__item-active'}
            previousClassName={'pagination__btn pagination__btn--prev'}
            nextClassName={isDesktop ? 'pagination__btn pagination__btn--next' : `${disableCondition ? "pagination__disable" : "pagination__mobile"}`}
            forcePage={page ? page - 1 : 0}
          />
          {loadedStatus ? null : <Loader />}
        </div>
        :
        null
      }
    </section>
  );
};

export default withRouter(Categories);
