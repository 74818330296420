import React from 'react';

import './HostingAnimation.scss'

const HostingAnimation = () => {
    return (
        <div className="hosting__animation">
            <svg width="700" height="642" viewBox="0 0 700 642" fill="none" xmlns="http://www.w3.org/2000/svg">
                
                <g id="Group">
                    <path id="Vector" d="M279.025 46.9277H198.215V616.115H279.025V46.9277Z" fill="url(#hosting0_linear)" />
                    <path id="Vector_2" d="M472.67 42.3867H410.156V611.574H472.67V42.3867Z" fill="url(#hosting1_linear)" />
                    <path id="Vector_3" d="M429.999 47.0801H388.57V616.32H429.999V47.0801Z" fill="url(#hosting2_linear)" />
                    <path id="Vector_4" d="M335.904 46.9277H305.715V616.115H335.904V46.9277Z" fill="url(#hosting3_linear)" />
                    <path id="Vector_5" d="M399.997 0H251.426V616.32H399.997V0Z" fill="url(#hosting4_linear)" />
                    <path id="Vector_6" d="M181.915 395.102C181.305 382.235 181 369.368 180.696 356.501C180.391 343.633 180.391 330.766 180.391 317.899C180.391 305.032 180.543 292.164 180.696 279.297C180.848 266.43 181.305 253.563 181.915 240.695C182.525 253.563 182.83 266.43 183.135 279.297C183.44 292.164 183.44 305.032 183.44 317.899C183.44 330.766 183.288 343.633 183.135 356.501C182.983 369.368 182.525 382.235 181.915 395.102Z" fill="url(#hosting5_linear)" />
                    <path id="Vector_7" d="M315.181 209.458C314.571 202.041 314.266 194.472 313.961 187.054C313.656 179.637 313.656 172.068 313.656 164.65C313.656 157.233 313.809 149.664 313.961 142.246C314.114 134.828 314.571 127.259 315.181 119.842C315.791 127.259 316.096 134.828 316.401 142.246C316.706 149.664 316.706 157.233 316.706 164.65C316.706 172.068 316.553 179.637 316.401 187.054C316.096 194.472 315.791 202.041 315.181 209.458Z" fill="url(#hosting6_linear)" />
                    <path id="Vector_8" d="M494.482 547.539C493.872 529.828 493.567 512.117 493.262 494.405C492.957 476.694 492.957 458.983 492.957 441.271C492.957 423.56 493.11 405.849 493.262 388.138C493.414 370.426 493.872 352.715 494.482 335.004C495.092 352.715 495.397 370.426 495.702 388.138C496.006 405.849 496.006 423.56 496.006 441.271C496.006 458.983 495.854 476.694 495.702 494.405C495.549 512.117 495.092 529.828 494.482 547.539Z" fill="url(#hosting7_linear)" />
                    <path id="Vector_9" d="M273.396 292.263C272.786 284.846 272.481 277.277 272.176 269.859C271.871 262.441 271.871 254.872 271.871 247.455C271.871 240.037 272.024 232.468 272.176 225.051C272.329 217.633 272.786 210.064 273.396 202.646C274.006 210.064 274.311 217.633 274.616 225.051C274.921 232.468 274.921 240.037 274.921 247.455C274.921 254.872 274.768 262.441 274.616 269.859C274.463 277.277 274.006 284.694 273.396 292.263Z" fill="url(#hosting8_linear)" />
                    <path id="Vector_10" d="M351.525 240.918C350.915 235.771 350.61 230.776 350.305 225.629C350 220.482 350 215.487 350 210.34C350 205.193 350.152 200.198 350.305 195.051C350.457 189.904 350.915 184.909 351.525 179.762C352.135 184.909 352.44 189.904 352.744 195.051C353.049 200.198 353.049 205.193 353.049 210.34C353.049 215.487 352.897 220.482 352.744 225.629C352.44 230.776 352.135 235.923 351.525 240.918Z" fill="url(#hosting9_linear)" />
                    <path id="Vector_11" d="M380.095 279.093C379.485 273.946 379.18 268.95 378.875 263.804C378.57 258.657 378.57 253.661 378.57 248.514C378.57 243.367 378.723 238.372 378.875 233.225C379.028 228.078 379.485 223.082 380.095 217.936C380.705 223.082 381.01 228.078 381.315 233.225C381.62 238.372 381.62 243.367 381.62 248.514C381.62 253.661 381.467 258.657 381.315 263.804C381.01 268.95 380.705 273.946 380.095 279.093Z" fill="url(#hosting10_linear)" />
                    <path id="Vector_12" d="M216.505 563.586C215.895 553.595 215.59 543.453 215.285 533.461C214.98 523.47 214.98 513.328 214.98 503.337C214.98 493.346 215.133 483.203 215.285 473.212C215.438 463.221 215.895 453.079 216.505 443.088C217.115 453.079 217.42 463.221 217.725 473.212C218.03 483.203 218.03 493.346 218.03 503.337C218.03 513.328 217.878 523.47 217.725 533.461C217.42 543.604 217.115 553.595 216.505 563.586Z" fill="url(#hosting11_linear)" />
                    <path id="Vector_13" className="grey" strokeWidth="1" d="M141.969 613.241H104.003C86.7736 613.241 72.7461 599.314 72.7461 582.208V165.006H74.2708V582.208C74.2708 598.557 87.536 611.727 104.003 611.727H141.816V613.241H141.969Z" fill="url(#hosting12_linear)" />
                    <path id="Vector_14" d="M338.043 627.469C424.947 627.469 495.395 620.759 495.395 612.481C495.395 604.204 424.947 597.494 338.043 597.494C251.14 597.494 180.691 604.204 180.691 612.481C180.691 620.759 251.14 627.469 338.043 627.469Z" fill="#FF4D66" />
                    <path id="Vector_15" className="grey" strokeWidth="1" d="M338.046 642C227.655 642 141.051 628.981 141.051 612.33C141.051 595.678 227.655 582.811 338.046 582.811C448.436 582.811 535.041 595.829 535.041 612.481C535.041 629.133 448.589 642 338.046 642ZM338.046 584.324C285.748 584.324 236.499 587.352 199.448 592.802C148.217 600.371 142.576 609.151 142.576 612.481C142.576 615.811 148.065 624.591 199.448 632.16C236.499 637.61 285.748 640.638 338.046 640.638C390.344 640.638 439.593 637.61 476.644 632.16C527.874 624.591 533.516 615.811 533.516 612.481C533.516 609.151 528.027 600.371 476.644 592.802C439.745 587.352 390.496 584.324 338.046 584.324Z" fill="url(#hosting13_linear)" />
                    <path id="Vector_16" d="M430.734 604.611H247.004V281.567C247.004 275.058 252.34 269.76 258.897 269.76H418.688C425.245 269.76 430.581 275.058 430.581 281.567V604.611H430.734Z" fill="#3E3E53" />
                    <path id="Vector_17" d="M445.372 278.389H232.215V611.725H445.372V278.389Z" fill="#181C2D" />
                    <path id="Vector_18" d="M434.553 288.229H243.352V601.734H434.553V288.229Z" fill="#767994" />
                    <path id="Vector_19" d="M429.826 292.92H247.773V572.367H429.826V292.92Z" fill="#3E3E53" />
                    <path id="Vector_20" d="M429.826 580.238H247.773V596.133H429.826V580.238Z" fill="#3E3E53" />
                    <path id="Vector_21" d="M404.052 502.125H273.535V556.773H404.052V502.125Z" fill="#767994" />
                    <path id="Vector_22" opacity="0.3" d="M397.04 508.938H280.703V515.144H397.04V508.938Z" fill="#0D2545" />
                    <path id="Vector_23" opacity="0.3" d="M397.04 520.291H280.703V526.498H397.04V520.291Z" fill="#0D2545" />
                    <path id="Vector_24" opacity="0.3" d="M397.04 531.646H280.703V537.853H397.04V531.646Z" fill="#0D2545" />
                    <path id="Vector_25" opacity="0.3" d="M397.04 543.15H280.703V549.357H397.04V543.15Z" fill="#0D2545" />
                    <path id="Vector_26" opacity="0.3" d="M404.052 433.098H273.535V487.746H404.052V433.098Z" fill="#767994" />
                    <path id="Vector_27" d="M387.051 478.695H395.742V442.365H387.051V478.695Z" fill="url(#hosting14_linear)" />
                    <path id="Vector_28" opacity="0.3" d="M280.327 446.078H369.066V442.445H280.327V446.078Z" fill="#0D2545" />
                    <path id="Vector_29" opacity="0.3" d="M280.327 456.984H369.066V453.351H280.327V456.984Z" fill="#0D2545" />
                    <path id="Vector_30" opacity="0.3" d="M280.327 468.043H369.066V464.41H280.327V468.043Z" fill="#0D2545" />
                    <path id="Vector_31" opacity="0.3" d="M280.327 478.951H369.066V475.318H280.327V478.951Z" fill="#0D2545" />
                    <path id="Vector_32" d="M376.263 478.695H379.465V442.365H376.263V478.695Z" fill="url(#hosting15_linear)" />
                    <g id="Group_2">
                        <path id="Vector_33" d="M273.69 310.631H261.645V322.59H273.69V310.631Z" fill="#F599B9" />
                        <path id="Vector_34" d="M293.967 310.631H281.922V322.59H293.967V310.631Z" fill="#767994" />
                        <path id="Vector_35" d="M314.405 310.631H302.359V322.59H314.405V310.631Z" fill="#767994" />
                        <path id="Vector_36" d="M334.674 310.631H322.629V322.59H334.674V310.631Z" fill="#767994" />
                        <path id="Vector_37" d="M355.116 310.631H343.07V322.59H355.116V310.631Z" fill="#FFB744" />
                        <path id="Vector_38" d="M375.389 310.631H363.344V322.59H375.389V310.631Z" fill="#767994" />
                        <path id="Vector_39" d="M395.819 310.631H383.773V322.59H395.819V310.631Z" fill="#767994" />
                        <path id="Vector_40" d="M416.26 310.631H404.215V322.59H416.26V310.631Z" fill="url(#hosting16_linear)" />
                    </g>
                    <g id="Group_3">
                        <path id="Vector_41" d="M273.69 333.338H261.645V345.297H273.69V333.338Z" fill="#767994" />
                        <path id="Vector_42" d="M293.967 333.338H281.922V345.297H293.967V333.338Z" fill="#FFB744" />
                        <path id="Vector_43" d="M314.405 333.338H302.359V345.297H314.405V333.338Z" fill="url(#hosting17_linear)" />
                        <path id="Vector_44" d="M334.674 333.338H322.629V345.297H334.674V333.338Z" fill="#767994" />
                        <path id="Vector_45" d="M355.116 333.338H343.07V345.297H355.116V333.338Z" fill="#FF6826" />
                        <path id="Vector_46" d="M375.389 333.338H363.344V345.297H375.389V333.338Z" fill="#767994" />
                        <path id="Vector_47" d="M395.819 333.338H383.773V345.297H395.819V333.338Z" fill="#F599B9" />
                        <path id="Vector_48" d="M416.26 333.338H404.215V345.297H416.26V333.338Z" fill="#767994" />
                    </g>
                    <g id="Group_4">
                        <path id="Vector_49" d="M273.69 355.893H261.645V367.852H273.69V355.893Z" fill="url(#hosting18_linear)" />
                        <path id="Vector_50" d="M293.967 355.893H281.922V367.852H293.967V355.893Z" fill="#767994" />
                        <path id="Vector_51" d="M314.405 355.893H302.359V367.852H314.405V355.893Z" fill="#767994" />
                        <path id="Vector_52" d="M334.674 355.893H322.629V367.852H334.674V355.893Z" fill="url(#hosting19_linear)" />
                        <path id="Vector_53" d="M355.116 355.893H343.07V367.852H355.116V355.893Z" fill="#767994" />
                        <path id="Vector_54" d="M375.389 355.893H363.344V367.852H375.389V355.893Z" fill="#767994" />
                        <path id="Vector_55" d="M395.819 355.893H383.773V367.852H395.819V355.893Z" fill="#FF6826" />
                        <path id="Vector_56" d="M416.26 355.893H404.215V367.852H416.26V355.893Z" fill="#767994" />
                    </g>
                    <g id="Group_5">
                        <path id="Vector_57" d="M273.69 378.449H261.645V390.409H273.69V378.449Z" fill="#767994" />
                        <path id="Vector_58" d="M293.967 378.449H281.922V390.409H293.967V378.449Z" fill="#767994" />
                        <path id="Vector_59" d="M314.405 378.449H302.359V390.409H314.405V378.449Z" fill="#FF6826" />
                        <path id="Vector_60" d="M334.674 378.449H322.629V390.409H334.674V378.449Z" fill="#767994" />
                        <path id="Vector_61" d="M355.116 378.449H343.07V390.409H355.116V378.449Z" fill="#767994" />
                        <path id="Vector_62" d="M375.389 378.449H363.344V390.409H375.389V378.449Z" fill="url(#hosting20_linear)" />
                        <path id="Vector_63" d="M395.819 378.449H383.773V390.409H395.819V378.449Z" fill="#767994" />
                        <path id="Vector_64" d="M416.26 378.449H404.215V390.409H416.26V378.449Z" fill="url(#hosting21_linear)" />
                    </g>
                    <g id="Group_6">
                        <path id="Vector_65" d="M273.69 401.004H261.645V412.963H273.69V401.004Z" fill="#767994" />
                        <path id="Vector_66" d="M293.967 401.004H281.922V412.963H293.967V401.004Z" fill="url(#hosting22_linear)" />
                        <path id="Vector_67" d="M314.405 401.004H302.359V412.963H314.405V401.004Z" fill="#767994" />
                        <path id="Vector_68" d="M334.674 401.004H322.629V412.963H334.674V401.004Z" fill="#FFB744" />
                        <path id="Vector_69" d="M355.116 401.004H343.07V412.963H355.116V401.004Z" fill="#767994" />
                        <path id="Vector_70" d="M375.389 401.004H363.344V412.963H375.389V401.004Z" fill="#767994" />
                        <path id="Vector_71" d="M395.819 401.004H383.773V412.963H395.819V401.004Z" fill="#F599B9" />
                        <path id="Vector_72" d="M416.26 401.004H404.215V412.963H416.26V401.004Z" fill="#767994" />
                    </g>
                    <path id="Vector_73" className="grey" strokeWidth="1" d="M75.0166 196.038C38.1182 196.038 0 190.134 0 180.143C0 170.152 38.1182 164.248 75.1691 164.248C112.22 164.248 150.338 170.152 150.338 180.143C150.338 190.134 112.068 196.038 75.0166 196.038ZM75.0166 165.762C30.952 165.762 1.37226 173.179 1.37226 180.143C1.37226 187.106 30.952 194.524 75.0166 194.524C119.081 194.524 148.661 187.106 148.661 180.143C148.661 173.179 119.081 165.762 75.0166 165.762Z" fill="url(#hosting23_linear)" />
                    <path id="Vector_74" d="M75.0263 173.028C104.92 173.028 129.154 168.081 129.154 161.977C129.154 155.874 104.92 150.926 75.0263 150.926C45.1323 150.926 20.8984 155.874 20.8984 161.977C20.8984 168.081 45.1323 173.028 75.0263 173.028Z" fill="url(#hosting24_linear)" />
                    <path id="Vector_75" d="M170.781 145.932H10.9895C7.63514 145.932 4.89062 143.207 4.89062 139.876V37.9967C4.89062 34.6663 7.63514 31.9414 10.9895 31.9414H170.781C174.135 31.9414 176.88 34.6663 176.88 37.9967V139.876C176.88 143.207 174.135 145.932 170.781 145.932Z" fill="#181C2D" />
                    <path id="Vector_76" d="M167.274 140.178H14.4961C11.904 140.178 9.92188 138.21 9.92188 135.637V42.0844C9.92188 39.5109 11.904 37.543 14.4961 37.543H167.274C169.866 37.543 171.848 39.5109 171.848 42.0844V135.637C171.848 138.21 169.866 140.178 167.274 140.178Z" fill="#3E3E53" />
                    <path id="Vector_77" d="M164.374 45.8691H17.3906V50.7135H164.374V45.8691Z" fill="url(#hosting25_linear)" />
                    <path id="Vector_78" opacity="0.3" d="M164.374 57.9785H17.3906V62.8242H164.374V57.9785Z" fill="#767994" />
                    <path id="Vector_79" opacity="0.3" d="M164.374 70.0898H17.3906V74.9342H164.374V70.0898Z" fill="#767994" />
                    <path id="Vector_80" opacity="0.3" d="M164.374 83.1094H17.3906V127.766H164.374V83.1094Z" fill="#767994" />
                    <path id="Vector_81" d="M154.925 90.373H132.969V120.498H154.925V90.373Z" fill="url(#hosting26_linear)" />
                    <path id="Vector_82" d="M85.7006 164.246H39.3489C35.9945 164.246 33.25 161.521 33.25 158.191V63.8814C33.25 60.551 35.9945 57.8262 39.3489 57.8262H85.5482C88.9026 57.8262 91.6471 60.551 91.6471 63.8814V158.191C91.7996 161.521 89.0551 164.246 85.7006 164.246Z" fill="#181C2D" />
                    <path id="Vector_83" d="M82.4986 158.949H42.5507C39.9587 158.949 37.9766 156.981 37.9766 154.408V67.6683C37.9766 65.0949 39.9587 63.127 42.5507 63.127H82.4986C85.0907 63.127 87.0728 65.0949 87.0728 67.6683V154.408C87.0728 156.981 85.0907 158.949 82.4986 158.949Z" fill="#3E3E53" />
                    <path id="Vector_84" d="M80.0609 70.8457H44.9922V75.3873H80.0609V70.8457Z" fill="url(#hosting27_linear)" />
                    <path id="Vector_85" opacity="0.3" d="M80.0609 114.746H44.9922V147.293H80.0609V114.746Z" fill="#767994" />
                    <path id="Vector_86" d="M80.0609 81.4434H44.9922V107.936H80.0609V81.4434Z" fill="#4F5066" />
                    <path id="Vector_87" d="M62.9879 84.6211H49.5703V104.756H62.9879V84.6211Z" fill="url(#hosting28_linear)" />
                    <g id="Group_7">
                        <path id="Vector_88" className="grey" strokeWidth="1" d="M573.6 613.238H534.262V611.724H573.6C590.067 611.724 603.332 598.555 603.332 582.206V307.453H604.857V582.206C604.857 599.311 590.829 613.238 573.6 613.238Z" fill="url(#hosting29_linear)" />
                    </g>
                    <g id="Group_8">
                        <path id="Vector_89" className="grey" strokeWidth="1"d="M604.103 363.614C567.204 363.614 528.934 357.71 528.934 347.719C528.934 337.728 567.052 331.824 604.103 331.824C641.154 331.824 679.272 337.728 679.272 347.719C679.272 357.559 641.154 363.614 604.103 363.614ZM604.103 333.338C560.038 333.338 530.458 340.756 530.458 347.719C530.458 354.683 560.038 362.1 604.103 362.1C648.167 362.1 677.747 354.683 677.747 347.719C677.747 340.756 648.167 333.338 604.103 333.338Z" fill="url(#hosting30_linear)" />
                    </g>
                    <g id="Group_9">
                        <path id="Vector_90" d="M603.784 344.238C633.678 344.238 657.912 339.291 657.912 333.188C657.912 327.084 633.678 322.137 603.784 322.137C573.89 322.137 549.656 327.084 549.656 333.188C549.656 339.291 573.89 344.238 603.784 344.238Z" fill="url(#hosting31_linear)" />
                    </g>
                    <g id="Group_10">
                        <path id="Vector_91" d="M678.201 322.742H528.473V229.493C528.473 226.162 531.217 223.438 534.572 223.438H672.102C675.457 223.438 678.201 226.162 678.201 229.493V322.742Z" fill="#3E3E53" />
                        <path id="Vector_92" d="M678.201 312.75H528.473V219.5C528.473 216.17 531.217 213.445 534.572 213.445H672.102C675.457 213.445 678.201 216.17 678.201 219.5V312.75Z" fill="#181C2D" />
                        <path id="Vector_93" d="M669.213 307.755H537.324C534.732 307.755 532.75 305.787 532.75 303.214V222.983C532.75 220.409 534.732 218.441 537.324 218.441H669.213C671.805 218.441 673.788 220.409 673.788 222.983V303.214C673.788 305.787 671.805 307.755 669.213 307.755Z" fill="#3E3E53" />
                        <path id="Vector_94" d="M577.728 225.559H539.305V229.797H577.728V225.559Z" fill="url(#hosting32_linear)" />
                        <path id="Vector_95" d="M654.269 236.154H539.305V240.393H654.269V236.154Z" fill="url(#hosting33_linear)" />
                        <path id="Vector_96" d="M607.155 246.75H539.305V250.99H607.155V246.75Z" fill="url(#hosting34_linear)" />
                        <path id="Vector_97" opacity="0.3" d="M667.229 258.104H539.305V297.008H667.229V258.104Z" fill="#767994" />
                        <path id="Vector_98" d="M566.133 264.461H547.074V290.651H566.133V264.461Z" fill="url(#hosting35_linear)" />
                    </g>
                    <path id="Vector_99" d="M689.173 330.161H518.556C512.609 330.161 507.883 325.468 507.883 319.564V318.504H699.999V319.564C699.999 325.316 695.119 330.161 689.173 330.161Z" fill="#181C2D" />
                    <path id="Vector_100" d="M583.056 265.219H571.926V276.27H583.056V265.219Z" fill="url(#hosting36_linear)" />
                    <path id="Vector_101" d="M461.685 144.717H460.16V589.318H461.685V144.717Z" fill="url(#hosting37_linear)" />
                    <g id="Group_11">
                        <path id="Vector_102" d="M461.236 175.147C424.337 175.147 386.066 169.243 386.066 159.252C386.066 149.261 424.185 143.357 461.236 143.357C498.134 143.357 536.405 149.261 536.405 159.252C536.405 169.243 498.134 175.147 461.236 175.147ZM461.236 144.871C417.171 144.871 387.591 152.289 387.591 159.252C387.591 166.216 417.171 173.633 461.236 173.633C505.3 173.633 534.88 166.216 534.88 159.252C534.88 152.289 505.3 144.871 461.236 144.871Z" fill="#FF4D66" />
                    </g>
                    <g id="Group_12">
                        <path id="Vector_103" d="M460.929 155.771C490.823 155.771 515.057 150.823 515.057 144.719C515.057 138.616 490.823 133.668 460.929 133.668C431.035 133.668 406.801 138.616 406.801 144.719C406.801 150.823 431.035 155.771 460.929 155.771Z" fill="url(#hosting38_linear)" />
                    </g>
                    <path id="Vector_104" d="M524.814 122.92H390.943L390.638 120.952C390.485 120.195 388.198 100.668 400.701 87.3461C408.324 79.1716 419.76 75.0844 434.702 75.5385C435.77 61.0061 446.443 46.3223 468.704 46.3223C478.309 46.3223 487.153 49.5012 493.862 55.2537C501.028 61.3088 504.992 69.7861 505.45 79.9285C514.293 80.3827 520.697 83.4103 524.661 89.0113C533.047 101.273 525.729 120.952 525.271 121.709L524.814 122.92ZM395.059 118.379H521.612C523.137 113.838 526.796 99.7592 521.002 91.282C517.8 86.5892 511.854 84.1671 503.468 84.1671H501.18V81.8965C501.18 72.2082 497.674 64.185 491.117 58.4326C485.323 53.4371 477.242 50.7123 469.009 50.7123C454.829 50.7123 439.429 59.1895 439.429 77.8092V80.2313L436.989 80.0799C422.352 79.323 411.374 82.8047 404.208 90.3737C395.212 99.7592 394.754 113.383 395.059 118.379Z" fill="#3E3E53" />
                    <path id="Vector_105" d="M515.967 112.324H403.137C403.137 112.324 404.356 82.3513 445.677 86.2872C443.694 46.6258 497.67 47.2313 494.468 90.5258C509.41 86.1358 520.846 97.1865 515.967 112.324Z" fill="url(#hosting39_linear)" />
                    <path id="Vector_106" className="blink" d="M114.977 296.552H105.523V287.166H114.977V296.552ZM107.048 295.038H113.452V288.68H107.048V295.038Z" fill="url(#hosting40_linear)" />
                    <path id="Vector_107" className="blink" d="M38.2589 312.043H46.1875V304.171H38.2589V312.043Z" fill="url(#hosting41_linear)" />
                    <path id="Vector_108" className="blink" d="M53.7042 361.961H61.6328V354.089H53.7042V361.961Z" fill="url(#hosting42_linear)" />
                    <path id="Vector_109" className="blink" d="M41.8917 361.961H49.8203V354.089H41.8917V361.961Z" fill="url(#hosting43_linear)" />
                    <path id="Vector_110" className="blink" d="M94.7862 361.961H102.715V354.089H94.7862V361.961Z" fill="url(#hosting44_linear)" />
                    <path id="Vector_111" className="blink" d="M83.2472 361.959H91.1758V354.088H83.2472V361.959Z" fill="url(#hosting45_linear)" />
                    <path id="Vector_112" className="blink" d="M106.15 328.682H114.078V320.809H106.15V328.682Z" fill="url(#hosting46_linear)" />
                    <path id="Vector_113" className="blink" d="M126.559 329.402H117.105V320.018H126.559V329.402ZM118.63 327.889H125.034V321.531H118.63V327.889Z" fill="url(#hosting47_linear)" />
                    <path id="Vector_114" className="blink" d="M106.15 378.602H114.078V370.729H106.15V378.602Z" fill="url(#hosting48_linear)" />
                    <path id="Vector_115" className="blink" d="M126.559 379.357H117.105V369.973H126.559V379.357ZM118.63 377.844H125.034V371.486H118.63V377.844Z" fill="url(#hosting49_linear)" />
                    <path id="Vector_116" className="blink" d="M106.153 345.32H114.082V337.449H106.153V345.32Z" fill="url(#hosting50_linear)" />
                    <path id="Vector_117" className="blink" d="M94.6105 345.32H102.539V337.449H94.6105V345.32Z" fill="url(#hosting51_linear)" />
                    <path id="Vector_118" className="blink" d="M94.6105 273.338H102.539V265.466H94.6105V273.338Z" fill="url(#hosting52_linear)" />
                    <path id="Vector_119" className="blink" d="M31.13 345.32H39.0586V337.449H31.13V345.32Z" fill="url(#hosting53_linear)" />
                    <path id="Vector_120" className="blink" d="M117.868 345.32H125.797V337.449H117.868V345.32Z" fill="url(#hosting54_linear)" />
                    <path id="Vector_121" className="blink" d="M30.3839 391.971H38.3125V384.099H30.3839V391.971Z" fill="url(#hosting55_linear)" />
                    <path id="Vector_122" className="blink" d="M18.8488 391.971H26.7773V384.1H18.8488V391.971Z" fill="url(#hosting56_linear)" />
                    <path id="Vector_123" className="blink" d="M55.0167 391.971H62.9453V384.099H55.0167V391.971Z" fill="url(#hosting57_linear)" />
                    <path id="Vector_124" className="blink" d="M95.4894 391.971H103.418V384.1H95.4894V391.971Z" fill="url(#hosting58_linear)" />
                    <path id="Vector_125" className="blink" d="M114.977 409.029H105.523V399.643H114.977V409.029ZM107.048 407.515H113.452V401.156H107.048V407.515Z" fill="url(#hosting59_linear)" />
                    <path id="Vector_126" className="blink" d="M50.1721 409.029H40.7188V399.643H50.1721V409.029ZM42.2435 407.515H48.6473V401.156H42.2435V407.515Z" fill="url(#hosting60_linear)" />
                    <path id="Vector_127" className="blink" d="M94.6027 408.209H102.531V400.337H94.6027V408.209Z" fill="url(#hosting61_linear)" />
                    <path id="Vector_128" className="blink" d="M94.6027 431.498H102.531V423.626H94.6027V431.498Z" fill="url(#hosting62_linear)" />
                    <path id="Vector_129" className="blink" d="M130.778 408.209H138.707V400.337H130.778V408.209Z" fill="url(#hosting63_linear)" />
                    <path id="Vector_130" className="blink" d="M117.876 408.209H125.805V400.337H117.876V408.209Z" fill="url(#hosting64_linear)" />
                    <path id="Vector_131" className="blink" d="M625.141 422.654H615.688V413.268H625.141V422.654ZM617.212 421.14H623.616V414.781H617.212V421.14Z" fill="url(#hosting65_linear)" />
                    <path id="Vector_132" className="blink"  d="M570.546 430.371H562.617V438.243H570.546V430.371Z" fill="url(#hosting66_linear)" />
                    <path id="Vector_133" className="blink"  d="M570.55 480.328H562.621V488.199H570.55V480.328Z" fill="url(#hosting67_linear)" />
                    <path id="Vector_134" className="blink"  d="M530.909 480.328H522.98V488.199H530.909V480.328Z" fill="url(#hosting68_linear)" />
                    <path id="Vector_135" className="blink"  d="M581.991 480.328H574.062V488.199H581.991V480.328Z" fill="url(#hosting69_linear)" />
                    <path id="Vector_136" className="blink"  d="M624.218 480.328H616.289V488.199H624.218V480.328Z" fill="url(#hosting70_linear)" />
                    <path id="Vector_137" className="blink"  d="M656.401 447.023H648.473V454.895H656.401V447.023Z" fill="url(#hosting71_linear)" />
                    <path id="Vector_138" className="blink" d="M559.575 455.652H550.121V446.266H559.575V455.652ZM551.646 454.138H558.05V447.779H551.646V454.138Z" fill="url(#hosting72_linear)" />
                    <path id="Vector_139" className="blink"  d="M570.554 496.98H562.625V504.852H570.554V496.98Z" fill="url(#hosting73_linear)" />
                    <path id="Vector_140" className="blink"  d="M651.522 496.98H643.594V504.852H651.522V496.98Z" fill="url(#hosting74_linear)" />
                    <path id="Vector_141" className="blink"  d="M640.237 496.98H632.309V504.852H640.237V496.98Z" fill="url(#hosting75_linear)" />
                    <path id="Vector_142" className="blink" d="M559.575 505.609H550.121V496.223H559.575V505.609ZM551.646 504.095H558.05V497.737H551.646V504.095Z" fill="url(#hosting76_linear)" />
                    <path id="Vector_143" className="blink"  d="M570.554 463.676H562.625V471.548H570.554V463.676Z" fill="url(#hosting77_linear)" />
                    <path id="Vector_144" className="blink"  d="M581.991 463.676H574.062V471.548H581.991V463.676Z" fill="url(#hosting78_linear)" />
                    <path id="Vector_145" className="blink"  d="M581.991 391.619H574.062V399.492H581.991V391.619Z" fill="url(#hosting79_linear)" />
                    <path id="Vector_146" className="blink"  d="M546.007 463.676H538.078V471.548H546.007V463.676Z" fill="url(#hosting80_linear)" />
                    <path id="Vector_147" className="blink"  d="M637.8 463.676H629.871V471.548H637.8V463.676Z" fill="url(#hosting81_linear)" />
                    <path id="Vector_148" className="blink"  d="M624.222 510.301H616.293V518.172H624.222V510.301Z" fill="url(#hosting82_linear)" />
                    <path id="Vector_149" className="blink"  d="M581.991 510.301H574.062V518.172H581.991V510.301Z" fill="url(#hosting83_linear)" />
                    <path id="Vector_150" className="blink"  d="M546.007 510.301H538.078V518.172H546.007V510.301Z" fill="url(#hosting84_linear)" />
                    <path id="Vector_151" className="blink" d="M571.317 535.279H561.863V525.893H571.317V535.279ZM563.388 533.765H569.792V527.406H563.388V533.765Z" fill="url(#hosting85_linear)" />
                    <path id="Vector_152" className="blink"  d="M581.991 526.5H574.062V534.372H581.991V526.5Z" fill="url(#hosting86_linear)" />
                    <path id="Vector_153" className="blink"  d="M581.991 549.811H574.062V557.683H581.991V549.811Z" fill="url(#hosting87_linear)" />
                    <path id="Vector_154" className="blink"  d="M546.007 526.5H538.078V534.372H546.007V526.5Z" fill="url(#hosting88_linear)" />
                    <path id="Vector_155" className="blink" d="M641.61 535.279H632.156V525.893H641.61V535.279ZM633.681 533.765H640.085V527.406H633.681V533.765Z" fill="url(#hosting89_linear)" />
                    <path id="Vector_156" className="blink" d="M449.953 223.588H440.5V214.203H449.953V223.588ZM442.025 222.074H448.429V215.717H442.025V222.074Z" fill="url(#hosting90_linear)" />
                    <path id="Vector_157" className="blink"  d="M442.341 239.141H450.27V231.268H442.341V239.141Z" fill="url(#hosting91_linear)" />
                    <path id="Vector_158" className="blink" d="M481.982 255.779H489.91V247.908H481.982V255.779Z" fill="url(#hosting92_linear)" />
                    <path id="Vector_159" className="blink" d="M469.708 209.426H477.637V201.554H469.708V209.426Z" fill="url(#hosting93_linear)" />
                    <path id="Vector_160" className="blink" d="M502.395 256.586H492.941V247.201H502.395V256.586ZM494.314 255.072H500.718V248.715H494.314V255.072Z" fill="url(#hosting94_linear)" />
                    <path id="Vector_161" className="blink" d="M481.825 272.418H489.754V264.546H481.825V272.418Z" fill="url(#hosting95_linear)" />
                    <path id="Vector_162" className="blink" d="M481.825 222.893H489.754V215.021H481.825V222.893Z" fill="url(#hosting96_linear)" />
                    <path id="Vector_163" className="blink" d="M470.462 272.418H478.391V264.546H470.462V272.418Z" fill="url(#hosting97_linear)" />
                </g>
                <defs>
                    <linearGradient id="hosting0_linear" x1="238.62" y1="122.618" x2="238.62" y2="611.583" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF4D66" stopOpacity="0" />
                        <stop offset="1" stopColor="#FF4D66" />
                    </linearGradient>
                    <linearGradient id="hosting1_linear" x1="441.413" y1="118.077" x2="441.413" y2="607.042" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF3E59" stopOpacity="0" />
                        <stop offset="1" stopColor="#FF3E59" />
                    </linearGradient>
                    <linearGradient id="hosting2_linear" x1="409.265" y1="72.8172" x2="409.265" y2="352.899" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF3E59" stopOpacity="0" />
                        <stop offset="1" stopColor="#FF4D66" />
                    </linearGradient>
                    <linearGradient id="hosting3_linear" x1="320.795" y1="72.6624" x2="320.795" y2="352.718" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF3E59" stopOpacity="0" />
                        <stop offset="1" stopColor="#FF3E59" />
                    </linearGradient>
                    <linearGradient id="hosting4_linear" x1="325.711" y1="62.2877" x2="325.711" y2="362.293" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF3E59" stopOpacity="0" />
                        <stop offset="1" stopColor="#FF3E59" />
                    </linearGradient>
                    <linearGradient id="hosting5_linear" x1="183.44" y1="395.102" x2="179.721" y2="395.086" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting6_linear" x1="316.706" y1="209.458" x2="312.986" y2="209.431" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting7_linear" x1="496.006" y1="547.539" x2="492.287" y2="547.527" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting8_linear" x1="274.921" y1="292.263" x2="271.201" y2="292.235" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting9_linear" x1="353.049" y1="240.918" x2="349.33" y2="240.877" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting10_linear" x1="381.62" y1="279.093" x2="377.901" y2="279.052" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting11_linear" x1="218.03" y1="563.586" x2="214.311" y2="563.565" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting12_linear" x1="141.969" y1="613.241" x2="57.6332" y2="610.379" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting13_linear" x1="535.041" y1="642" x2="381.956" y2="418.099" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting14_linear" x1="387.051" y1="442.365" x2="397.623" y2="442.921" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting15_linear" x1="376.263" y1="442.365" x2="380.167" y2="442.441" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting16_linear" x1="416.26" y1="322.59" x2="402.254" y2="319.491" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting17_linear" x1="314.405" y1="345.297" x2="300.399" y2="342.198" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting18_linear" x1="273.69" y1="367.852" x2="259.684" y2="364.753" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting19_linear" x1="334.674" y1="367.852" x2="320.668" y2="364.753" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting20_linear" x1="375.389" y1="390.409" x2="361.383" y2="387.309" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting21_linear" x1="416.26" y1="390.409" x2="402.254" y2="387.309" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting22_linear" x1="293.967" y1="412.963" x2="279.961" y2="409.864" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting23_linear" x1="150.338" y1="196.038" x2="62.1689" y2="104.42" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting24_linear" x1="129.154" y1="173.028" x2="67.9721" y2="107.185" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting25_linear" x1="164.374" y1="50.7135" x2="160.429" y2="24.4134" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting26_linear" x1="154.925" y1="120.498" x2="128.814" y2="116.316" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting27_linear" x1="80.0609" y1="75.3873" x2="69.0327" y2="56.6763" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting28_linear" x1="62.9879" y1="104.756" x2="46.9656" y2="102.41" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting29_linear" x1="604.857" y1="613.238" x2="518.971" y2="608.882" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting30_linear" x1="679.272" y1="363.614" x2="591.103" y2="271.996" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting31_linear" x1="657.912" y1="344.238" x2="596.733" y2="278.395" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting32_linear" x1="577.728" y1="229.797" x2="568.292" y2="211.005" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting33_linear" x1="654.269" y1="240.393" x2="650.429" y2="217.507" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting34_linear" x1="607.155" y1="250.99" x2="600.962" y2="229.214" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting35_linear" x1="566.133" y1="290.651" x2="543.466" y2="287.027" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting36_linear" x1="583.056" y1="276.27" x2="570.114" y2="273.406" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting37_linear" x1="461.685" y1="589.318" x2="459.825" y2="589.317" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting38_linear" x1="515.057" y1="155.771" x2="453.875" y2="89.927" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting39_linear" x1="517.108" y1="112.324" x2="401.927" y2="59.9308" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting40_linear" x1="114.977" y1="296.552" x2="103.985" y2="294.119" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting41_linear" x1="38.2589" y1="304.171" x2="47.4781" y2="306.211" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting42_linear" x1="53.7042" y1="354.089" x2="62.9234" y2="356.129" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting43_linear" x1="41.8917" y1="354.089" x2="51.1109" y2="356.129" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting44_linear" x1="94.7862" y1="354.089" x2="104.005" y2="356.129" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting45_linear" x1="83.2472" y1="354.088" x2="92.4663" y2="356.128" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting46_linear" x1="106.15" y1="320.809" x2="115.369" y2="322.85" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting47_linear" x1="126.559" y1="329.402" x2="115.567" y2="326.969" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting48_linear" x1="106.15" y1="370.729" x2="115.369" y2="372.77" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting49_linear" x1="126.559" y1="379.357" x2="115.567" y2="376.925" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting50_linear" x1="106.153" y1="337.449" x2="115.373" y2="339.49" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting51_linear" x1="94.6105" y1="337.449" x2="103.83" y2="339.49" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting52_linear" x1="94.6105" y1="265.466" x2="103.83" y2="267.506" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting53_linear" x1="31.13" y1="337.449" x2="40.3491" y2="339.49" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting54_linear" x1="117.868" y1="337.449" x2="127.087" y2="339.49" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting55_linear" x1="30.3839" y1="384.099" x2="39.6031" y2="386.139" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting56_linear" x1="18.8488" y1="384.1" x2="28.0678" y2="386.14" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting57_linear" x1="55.0167" y1="384.099" x2="64.2359" y2="386.139" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting58_linear" x1="95.4894" y1="384.1" x2="104.708" y2="386.14" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting59_linear" x1="114.977" y1="409.029" x2="103.985" y2="406.596" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting60_linear" x1="50.1721" y1="409.029" x2="39.1799" y2="406.596" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting61_linear" x1="94.6027" y1="400.337" x2="103.822" y2="402.377" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting62_linear" x1="94.6027" y1="423.626" x2="103.822" y2="425.666" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting63_linear" x1="130.778" y1="400.337" x2="139.998" y2="402.377" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting64_linear" x1="117.876" y1="400.337" x2="127.095" y2="402.377" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting65_linear" x1="625.141" y1="422.654" x2="614.149" y2="420.221" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting66_linear" x1="570.546" y1="438.243" x2="561.327" y2="436.203" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting67_linear" x1="570.55" y1="488.199" x2="561.331" y2="486.159" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting68_linear" x1="530.909" y1="488.199" x2="521.69" y2="486.159" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting69_linear" x1="581.991" y1="488.199" x2="572.772" y2="486.159" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting70_linear" x1="624.218" y1="488.199" x2="614.999" y2="486.159" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting71_linear" x1="656.401" y1="454.895" x2="647.182" y2="452.854" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting72_linear" x1="559.575" y1="455.652" x2="548.582" y2="453.219" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting73_linear" x1="570.554" y1="504.852" x2="561.334" y2="502.811" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting74_linear" x1="651.522" y1="504.852" x2="642.303" y2="502.811" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting75_linear" x1="640.237" y1="504.852" x2="631.018" y2="502.811" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting76_linear" x1="559.575" y1="505.609" x2="548.582" y2="503.176" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting77_linear" x1="570.554" y1="471.548" x2="561.334" y2="469.508" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting78_linear" x1="581.991" y1="471.548" x2="572.772" y2="469.508" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting79_linear" x1="581.991" y1="399.492" x2="572.772" y2="397.452" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting80_linear" x1="546.007" y1="471.548" x2="536.787" y2="469.508" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting81_linear" x1="637.8" y1="471.548" x2="628.58" y2="469.508" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting82_linear" x1="624.222" y1="518.172" x2="615.002" y2="516.131" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting83_linear" x1="581.991" y1="518.172" x2="572.772" y2="516.131" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting84_linear" x1="546.007" y1="518.172" x2="536.788" y2="516.131" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting85_linear" x1="571.317" y1="535.279" x2="560.324" y2="532.846" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting86_linear" x1="581.991" y1="534.372" x2="572.772" y2="532.332" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting87_linear" x1="581.991" y1="557.683" x2="572.772" y2="555.642" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting88_linear" x1="546.007" y1="534.372" x2="536.788" y2="532.332" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting89_linear" x1="641.61" y1="535.279" x2="630.617" y2="532.846" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting90_linear" x1="449.953" y1="223.588" x2="438.961" y2="221.155" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting91_linear" x1="442.341" y1="231.268" x2="451.56" y2="233.309" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting92_linear" x1="481.982" y1="247.908" x2="491.201" y2="249.949" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting93_linear" x1="469.708" y1="201.554" x2="478.927" y2="203.594" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting94_linear" x1="502.395" y1="256.586" x2="491.403" y2="254.153" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                    </linearGradient>
                    <linearGradient id="hosting95_linear" x1="481.825" y1="264.546" x2="491.045" y2="266.586" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting96_linear" x1="481.825" y1="215.021" x2="491.044" y2="217.062" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                    <linearGradient id="hosting97_linear" x1="470.462" y1="264.546" x2="479.681" y2="266.586" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF6826" />
                        <stop offset="0.494792" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FC6076" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    )
}

export default HostingAnimation
