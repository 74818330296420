import React, { useState, useEffect } from 'react';

import axios from 'axios';
import API from '../../../api';

import './Imprint.scss';

import circle1 from './images/circle-1.svg';
import circle2 from './images/circle-2.svg';
import circle3 from './images/circle-3.svg';
import circle4 from './images/circle-4.svg';
import circle5 from './images/circle-5.svg';
import circle6 from './images/circle-6.svg';
import circle7 from './images/circle-7.svg';
import continents from './images/continents.svg';

import bgDesktop from './images/desktop-bg.svg';

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

const countryCodes = {
    mt: 'mt',
    de: 'de',
    ua: 'ua',
    hk: 'de'
}

const showLocation = {
    mt: true,
    de: true,
    ua: true,
    hk: false
}

function Imprint(props) {
    const [activeCountry, setActiveCountry] = useState(countryCodes.mt);

    const [page_data, setPage_data] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);

    useEffect(() => {
        axios
            .get(API.basePath + '/wp-json/wp/v2/pages?slug=imprint')
            .then((response) => {
                setPage_data(response.data[0]);
                setYoast_meta(response.data[0]);
                setLoaded_status(true);
            })
            .catch((error) => {
                console.log(error);
            });
        props.setContactElements(false, true, false);
    }, []);

    if (loaded_status) {

        const { maltese_office,
            ukrainian_office,
            german_office,
            hong_kong_office,
            imprint_text_group } = page_data.acf;

        return (
            <>
                <PageMeta metaData={yoast_meta || null} hreflangs={page_data.acf['hreflang_list'] || null} />
                <CustomSchema schema={page_data.acf.schema} />
                <div className='imprint-page'>
                    <div className='imprint-page__top-bg'></div>
                    <div className='imprint-page__bottom-bg'></div>

                    <img
                        className='bg-desktop'
                        src={bgDesktop}
                        alt='connections'
                    />

                    <div className='imprint-page__title'>
                        <h1 className='imprint-page__title-text'>{page_data.title.rendered}</h1>
                        <div className='imprint-page__title-bg'>{page_data.title.rendered}</div>
                    </div>

                    <div className='imprint-page__globe'>
                        <img
                            className='circle circle-1'
                            src={circle1}
                            alt='circle1'
                        />
                        <img
                            className='circle circle-2'
                            src={circle2}
                            alt='circle2'
                        />
                        <img
                            className='circle circle-3'
                            src={circle3}
                            alt='circle3'
                        />
                        <img
                            className='circle circle-4'
                            src={circle4}
                            alt='circle4'
                        />
                        <img
                            className='circle circle-5'
                            src={circle5}
                            alt='circle5'
                        />
                        <img
                            className='circle circle-6'
                            src={circle6}
                            alt='circle6'
                        />
                        <img
                            className='circle circle-7'
                            src={circle7}
                            alt='circle7'
                        />
                        <img
                            className='circle circle-8'
                            src={continents}
                            alt='continents'
                        />

                        {showLocation.mt && (
                            <div
                                className={`location location-malta  
                                ${
                                    activeCountry === countryCodes.mt ? 'location--active' : ''
                                }`}
                                onMouseEnter={() => setActiveCountry(countryCodes.mt)}
                            ></div>
                        )}

                        {showLocation.de && (
                            <div
                                className={`location location-germany  
                                ${
                                    activeCountry === countryCodes.de ? 'location--active' : ''
                                }`}
                                onMouseEnter={() => setActiveCountry(countryCodes.de)}
                            ></div>
                        )}
                        {showLocation.ua && (
                            <div
                                className={`location location-ukraine  
                                ${
                                    activeCountry === countryCodes.ua ? 'location--active' : ''
                                }`}
                                onMouseEnter={() => setActiveCountry(countryCodes.ua)}
                            ></div>
                        )}
                        {showLocation.hk && (
                            <div
                                className={`location location-hongKong  
                                ${
                                    activeCountry === countryCodes.hk ? 'location--active' : ''
                                }`}
                                onMouseEnter={() => setActiveCountry(countryCodes.hk)}
                            ></div>
                        )}
                    </div>

                    <div
                        className={`about-office office-malta 
                        ${
                            activeCountry === countryCodes.mt ? 'about-office--active' : ''
                        }`}
                        onMouseEnter={() => setActiveCountry(countryCodes.mt)}
                    >
                        <img
                            className='flag'
                            src={maltese_office.flag.url}
                            alt={maltese_office.flag.alt}
                        />
                        <div className='about-office__title office-malta__title'>
                            <i className='icon-location'></i>
                            <p>{maltese_office.title}</p>
                        </div>
                        <div className='office-malta__textHolder'>
                            <div className='about-office__textHolder office-malta__textHolder-top'>
                                <p className='office-malta__subTitle'>{maltese_office.subtitle}</p>
                                <p className='about-office__description' dangerouslySetInnerHTML={{ __html: maltese_office.information}} />
                            </div>
                            <div className='office-malta__textHolder-bottom'>
                                <p className='email'>
                                    {maltese_office.email.left_text}
                                    <span>
                                        <a href={`mailto:${maltese_office.email.right_text}`}>
                                            {maltese_office.email.right_text}
                                        </a>
                                    </span>
                                </p>
                                <p className='phone'>
                                    {maltese_office.phone.left_text}
                                    <span>
                                        <a href={`tel:${maltese_office.phone.phone_number_label}`}>
                                            {maltese_office.phone.right_text}
                                        </a>
                                    </span>
                                </p>
                                <p className='director'>
                                    {maltese_office.director.left_text}
                                    <span>{maltese_office.director.right_text}</span>
                                </p>

                                <p className='operational_office'>
                                    {maltese_office.operational_office.left_text}
                                    <span className="operational_office__text" dangerouslySetInnerHTML={{ __html: maltese_office.operational_office.right_text}} />
                                </p>
                               
                                <p className='vat-id'>
                                    {maltese_office.uid.left_text}<span>{maltese_office.uid.right_text}</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`about-office office-ukraine 
                        ${
                            activeCountry === countryCodes.ua ? 'about-office--active' : ''
                        }`}
                        onMouseEnter={() => setActiveCountry(countryCodes.ua)}
                    >
                        <img
                            className='flag'
                            src={ukrainian_office.flag.url}
                            alt={ukrainian_office.flag.alt}
                        />
                        <div className='about-office__textHolder'>
                            <div className='about-office__title office-ukraine__title'>
                                <i className='icon-location'></i>
                                <p>{ukrainian_office.title}</p>
                            </div>
                            <p className='about-office__description' dangerouslySetInnerHTML={{ __html: ukrainian_office.information}}/>
                        </div>
                    </div>

                    <div
                        className={`about-office office-germany 
                        ${
                            activeCountry === countryCodes.de ? 'about-office--active' : ''
                        }`}
                        onMouseEnter={() => setActiveCountry(countryCodes.de)}
                    >
                        <img
                            className='flag'
                            src={german_office.flag.url}
                            alt={german_office.flag.alt}
                        />
                        <div className='about-office__textHolder'>
                            <div className='about-office__title office-germany__title'>
                                <i className='icon-location'></i>
                                <p>{german_office.title}</p>
                            </div>
                            <p className='about-office__description' dangerouslySetInnerHTML={{ __html: german_office.information}}/>
                        </div>
                    </div>

                    <div
                        className={`about-office office-hongKong 
                        ${
                            activeCountry === countryCodes.hk ? 'about-office--active' : ''
                        }`}
                        onMouseEnter={() => setActiveCountry(countryCodes.hk)}
                    >
                        <img
                            className='flag'
                            src={hong_kong_office.flag.url}
                            alt={hong_kong_office.flag.alt}
                        />
                        <div className='about-office__textHolder'>
                            <div className='about-office__title office-hongKong__title'>
                                <i className='icon-location'></i>
                                <p>{hong_kong_office.title}</p>
                            </div>
                            <p className='about-office__description' dangerouslySetInnerHTML={{ __html: hong_kong_office.information}}/>
                        </div>
                    </div>
                    <div className='text-block'>
                        <div className='text-block__content'>
                            <div className='text-block__group'>
                                <h2>{imprint_text_group.professional_liability_insurance.title}</h2>
                                <div dangerouslySetInnerHTML = {{__html: imprint_text_group.professional_liability_insurance.information}} />
                            </div>
                            <div className='text-block__group'>
                                <h2>{imprint_text_group.dispute_resolution.title}</h2>
                                <div dangerouslySetInnerHTML = {{__html: imprint_text_group.dispute_resolution.description}} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className='imprint-page'>
                    <div className='imprint-page__top-bg'></div>
                    <div className='imprint-page__bottom-bg'></div>

                    <img
                        className='bg-desktop'
                        src={bgDesktop}
                        alt='connections'
                    />

                    <div className='imprint-page__title'>
                        <div className='imprint-page__title--loading'></div>
                    </div>

                    <div className='imprint-page__globe'>
                        <div className='imprint-page__globe--loading'></div>
                    </div>

                    <div className='about-office office-malta'>
                        <div className='about-office--loading office-malta--loading'></div>
                    </div>

                    <div className='about-office office-ukraine'>
                        <div className='about-office--loading office-ukraine--loading'></div>
                    </div>

                    <div className='about-office office-germany'>
                        <div className='about-office--loading office-germany--loading'></div>
                    </div>

                    <div className='about-office office-hongKong'>
                        <div className='about-office--loading office-hongKong--loading'></div>
                    </div>
                </div>
            </>
        );
    }
}

export default Imprint;
