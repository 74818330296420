import React from 'react'

import {Link} from 'react-scroll';

const ScrollIcon = (props) => {

    const {color, borderColor} = props

    return (
        <div className="scroll-down loading-block loading-block--4">
        <Link activeClass="active" className="scroll-down__btn" to="scrollPosition" spy={true} smooth={true} offset={0} duration={500} onSetActive={props.handleSetActive} style={{borderColor: borderColor}}>
            <i className="scroll-down__btn-i">
                <svg width="10" height="35" viewBox="0 0 10 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.00391 1.14282V6.85711" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 30L5 34L9 30" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </i>
        </Link>
    </div>
    )
}

export default ScrollIcon