import React from 'react';

const HeadlessCommerce = (props) => {
    const content = props.content;

    return (
        <section className='headless-commerce anim-item'>
            <div className='number'>5</div>
            <div className='headless-commerce__textHolder'>
                <div className='e-commerce-page__title headless-commerce__title'>
                    <h2 className='e-commerce-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }}></h2>
                    <div className='e-commerce-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }}></div>
                </div>
                <div className="e-commerce-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
            </div>
            <div className='headless-commerce__animation-container'>
                <div className='headless-commerce__backend'>
                    <span>Backend</span>
                    <img src={content.backend_image.url} alt={content.backend_image.alt} />
                </div>
                <div className='headless-commerce__gear'>
                    <img
                        className='headless-commerce__gear_icon'
                        src={content.api_icon.url}
                        alt={content.api_icon.alt}
                    />
                    <img
                        className='headless-commerce__gear_image'
                        src={content.gear.url}
                        alt={content.gear.alt}
                    />
                </div>
                <svg className='headless-commerce__arrows' width="430" height="430" viewBox="0 0 430 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M429.414 203.686L416.686 216.414C415.905 217.195 414.639 217.195 413.858 216.414L401.13 203.686C400.349 202.905 400.349 201.639 401.13 200.858C401.911 200.077 403.177 200.077 403.958 200.858L413.213 210.112C410.618 103.018 322.991 17 215.272 17C141.987 17 77.9979 56.8139 43.7597 116.001L40.2973 113.999C75.221 53.6258 140.501 13 215.272 13C325.238 13 414.681 100.87 417.217 210.227L426.586 200.858C427.367 200.077 428.633 200.077 429.414 200.858C430.195 201.639 430.195 202.905 429.414 203.686Z" fill="#767994"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M215.272 413C288.557 413 352.546 373.186 386.784 313.999L390.247 316.001C355.323 376.374 290.043 417 215.272 417C105.306 417 15.8631 329.13 13.3274 219.773L3.95834 229.142C3.17731 229.923 1.91098 229.923 1.12991 229.142C0.348877 228.361 0.348877 227.095 1.12991 226.314L13.8578 213.586C14.6389 212.805 15.9052 212.805 16.6863 213.586L29.4142 226.314C30.1953 227.095 30.1953 228.361 29.4142 229.142C28.6331 229.923 27.3668 229.923 26.5858 229.142L17.3312 219.888C19.9258 326.982 107.554 413 215.272 413Z" fill="#767994"/>
                </svg>
                <div className='headless-commerce__frontend'>
                    <img src={content.frontend_image.url} alt={content.frontend_image.alt} />
                    <span>Frontend</span>
                </div>
            </div>
        </section>
    )
};

export default HeadlessCommerce;
