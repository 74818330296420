import React from "react";
import { Helmet } from "react-helmet";

const CustomSchema = ({ schema }) => {

    const jsonParse = (val) => {
        if (val) {
            try {
                return JSON.parse(`${val}`)
            } catch (e) {
                console.log(`cannot parse JSON data. Please check the correct of the data`)
                return false
            }
        }

        return false
    }

    const showSchema = schema && schema.show_schema;
    const jsonLd = schema && showSchema && jsonParse(schema.json_ld);

    if (showSchema && jsonLd) {
        return (
            <Helmet>
                <script className='structured-data-list' type="application/ld+json">
                    {JSON.stringify(jsonLd)}
                </script>
            </Helmet>
        )
    } else {
        return null
    }

}

export default CustomSchema
