import React from "react";

const SliderItem = (props) => {
    return (
        <div className="banner-title">
            <div className="banner-title__text-wrap banner-title__text-wrap--left-position">
                <div className="banner-title__text banner-title__text--opacity" dangerouslySetInnerHTML={{ __html: props.content.title_first_part}}/>
            </div>
            <div className="banner-title__text-wrap banner-title__text-wrap--right-position">
                <div className="banner-title__right">
                    <span className="banner-title__separator"></span>
                    <span className="banner-title__text" dangerouslySetInnerHTML={{ __html: props.content.title_second_part}} />
                </div>
            </div>
        </div>
    );

};

export default SliderItem;