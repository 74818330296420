import React, {Component} from "react";
import './ContactUs.scss';

class ContactUs extends Component {

    render() {

        if(this.props.content) {

            const content = this.props.content;

            return (
                <>
                    <div className="contact-us">
                        <div className="contact-us__title">
                            <h2 className="contact-us__title-text">
                                {content.title}
                            </h2>
                        </div>

                        <div className="contact-us__advantages">
                            {
                                content.advantages_list.map((item, key) => {
                                    return (
                                        <div className="contact-us__advantage" key={key}>
                                            <div className="contact-us__advantage-title">
                                                {item.advantage.title}
                                            </div>
                                            <div className="contact-us__advantage-subtitle">
                                            <span>
                                                {item.advantage.subtitle}
                                            </span>
                                                <div className="contact-us__advantage-bg">
                                                    {item.advantage.subtitle}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="contact-us__bg">
                            <div className="contact-us__bg-text">
                            <span>
                                {content.bottom_bg_text}
                            </span>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="contact-us">
                </div>
            );
        }
    }
}

export default ContactUs;