import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

const Collaboration = (props) => {
    const { title, text, alkima_badge, usercentrics_badge } = props.content;

    return (
        <section className="collaboration">
            <ParallaxProvider>
                <div className='number number--left'>4</div>
                <div className='usercentrics-page__title collaboration__title'>
                    <h2 className='usercentrics-page__title-text' dangerouslySetInnerHTML={{ __html: title.text }} />
                    <div className='usercentrics-page__title-bg' dangerouslySetInnerHTML={{ __html: title.background }} />
                </div>
                <div className="usercentrics-page__text collaboration__text" dangerouslySetInnerHTML={{ __html: text }} />
                <div className="collaboration__animation">
                    <div className="collaboration__animation--circle">
                        <svg width="118" height="120" viewBox="0 0 118 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M27.6194 89.7873L38.2551 71.2681L16.8992 71.2193L8.36988e-05 41.9493L50.0509 43.6763L73.0983 0.762575L89.991 30.0216L79.9439 47.1991L99.8436 47.0867L116.789 76.4368L68.1008 74.9396L44.571 119.148L27.6194 89.7873ZM65.1945 69.9272L108.143 71.248L97.0445 52.0245L71.3345 52.1697L84.3152 29.9768L73.2692 10.8444L52.9386 48.6994L8.66521 47.1718L19.7113 66.3041L46.746 66.3658L33.2821 89.8095L44.3808 109.033L65.1945 69.9272Z" fill="url(#paint0_linear_8862_19349)"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M38.2551 71.2682L16.8992 71.2195L9.86015e-05 41.9494L50.051 43.6764L73.0983 0.762698L89.991 30.0217L79.9439 47.1992L79.9451 47.1983L38.2551 71.2682ZM46.7461 66.366L71.3345 52.1698L84.3152 29.9769L73.2692 10.8446L52.9387 48.6996L8.66523 47.1719L19.7113 66.3042L46.7461 66.366Z" fill="white"/>
                            <defs>
                            <linearGradient id="paint0_linear_8862_19349" x1="72.6581" y1="0.000119129" x2="37.4728" y2="117.014" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FC6076"/>
                            <stop offset="0.484375" stopColor="#FF4D66"/>
                            <stop offset="1" stopColor="#FF6826"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <img 
                        className="collaboration__badge collaboration__badge--alkima" 
                        src={alkima_badge.url} 
                        alt={alkima_badge.alt} 
                    />
                    <img 
                        className="collaboration__badge collaboration__badge--usercentrics" 
                        src={usercentrics_badge.url} 
                        alt={usercentrics_badge.alt} 
                    />
                </div>
                <Parallax
                    translateY={[30, -30]}
                    className="circle circle--1"
                >
                    <div></div>
                </Parallax>
                <Parallax
                    translateY={[30, 0]}
                    className="circle circle--2"
                >
                    <div></div>
                </Parallax>
                <Parallax
                    translateY={[5, -10]}
                    className="circle circle--3"
                >
                    <div></div>
                </Parallax>
            </ParallaxProvider>
        </section>
    )
};

export default Collaboration;
