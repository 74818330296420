import React, { useState, useEffect } from 'react'

import SwiperTags from './SwiperTags'

import { Link } from 'react-router-dom';

import { withRouter } from 'react-router';

import axios from "axios";

import API from "../../../../../api";

import './Tags.scss'

const Tags = (props) => {
    const [tags, setTags] = useState([]);
    const [postWithTags, setPostWithTags] = useState([])
    const { postId, windowWidth, history } = props;


    useEffect(() => {
        fetchDate()
        window.addEventListener("resize", props.getWindowWidth);
        return () => {
            window.removeEventListener("resize", props.getWindowWidth);
        };
    }, [])


    const fetchDate = async () => {
        try {
            const tagsResult = await axios.get(API.basePath + "/wp-json/wp/v2/tags?post=" + postId);
            const tags = tagsResult.data;
            setTags(tags);
            const postResult = await axios.get(API.basePath + `/wp-json/wp/v2/posts?tags=${tags.map(({ id }) => id).join(",")}`);
            const posts = postResult.data;
            setPostWithTags(posts);
        } catch (e) {
            console.log(e);
        }

    }



    const Wrapper = windowWidth > 762 ? "div" : SwiperTags

    return (
        <div >
            <Wrapper className="post-tags">
                {tags.map((tag) => {

                    return (
                        <Link key={tag.id} className="tag" to={`/blog?tag=${tag.slug}`}>
                            {`#${tag.name}`}
                        </Link>
                    )
                })}
            </Wrapper>

        </div>
    )
}

export default withRouter(Tags)
