import React from 'react'

import Swiper from 'react-id-swiper';

const SwiperTags = (props) => {

    const params = {
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: 10,
    }

    return (
        <Swiper {...params}>

            {props.children}

        </Swiper>
    )
}

export default SwiperTags
