import React from "react";

const CardContent = (props) => {
    
  const { children, ...restProps } = props;

  const Wrapper = restProps.href ? "a" : "div";

  return <Wrapper {...restProps}>{children}</Wrapper>;
};

export default CardContent;
