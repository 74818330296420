import React, { useState, useEffect } from 'react';

import "./ContactForm.scss";

import axios from "axios";
import API from "../../api";
import { gtmPush } from "../../helpers/gtm";
import Field from "./Field";
import Button from "./Button";

const ContactForm = (props) => {
    const { form_data, fieldsValueInit, heading } = props
    const stepsAmount = form_data.steps && form_data.steps.length
    const [isFormSuccess, setIsFormSuccess] = useState(false)
    const [fieldsValue, setFieldsValue] = useState(fieldsValueInit)
    const [isLoading, setIsLoading] = useState(false)
    const [isCheckboxAgree, setIsCheckboxAgree] = useState(false)
    const [fieldsError, setFieldsError] = useState({})
    const [step, setStep] = useState(1)

    const firstStepFields = form_data.field.filter(item => item.step === 'first')
    const secondStepFields = form_data.field.filter(item => item.step === 'second')
    
    useEffect(() => {
        let isErrorPrevStep = false
        for (let key in fieldsError) {
            if (firstStepFields.some(item => item.field_name_attribute === key)) {
                isErrorPrevStep = true
                break
            }
        }

        isErrorPrevStep && setStep(1)

    }, [fieldsError])

    const inputHandler = (id, value) => {
        const values = {...fieldsValue, [id]: value};

        setFieldsValue(values)
    };

    const checkboxAgreeHandler = () => {
        setIsCheckboxAgree(isCheckboxAgree => !isCheckboxAgree)
    };

    const scrollToInvalidField = () => {
        if(fieldsError) {
            let errorField = document.getElementsByClassName('field--error')[0];

            if (errorField) {
                let position  = errorField.getBoundingClientRect();
                if(position.top < 0) {
                    errorField.scrollIntoView();
                }
            }
        }
    };

    const prevStep = () => {
        setStep(step => step < 2 ? 1 : step - 1)
    }

    useEffect(() => {
        isFormSuccess && setStep(stepsAmount)
    }, [isFormSuccess])

    

    const sendForm = (e) => {
        e.preventDefault();

        if(step === 1) {
            setStep(2)

            return false
        }

        let bodyFormData = new FormData();

        if(fieldsValue) {
            const fields = fieldsValue;
            
            for (var field in fields) {
                bodyFormData.append(field, fields[field]);
            }
        }
        
        if(isCheckboxAgree) {
            bodyFormData.append('agree', isCheckboxAgree);
        }

        setIsLoading(true)

        const cf7id = 10;
        axios({
            method: 'post',
            url: API.basePath+'/wp-json/contact-form-7/v1/contact-forms/'+cf7id+'/feedback',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
            .then(response => {

                let validationTimeout = setTimeout(()=> {

                    setIsLoading(false)
                    setFieldsError({})
                    

                    if(response.data.status === 'validation_failed') {
                        let responseErrors = response.data.invalid_fields;

                        let invalidFields = [];

                        for (let key = 0; key < responseErrors.length; key++) {
                            let error = responseErrors[key];
                            let label = error.field;

                            if(error.message !== '') {
                                invalidFields[label] = error.message;
                            } else {
                                delete invalidFields[label];
                            }
                            
                            setFieldsError(prevState => ({
                                ...prevState,
                                ...invalidFields
                            }));

                        }
                        scrollToInvalidField();

                    } else if(response.data.status === 'mail_sent') {

                        const fields = fieldsValue;
                        let fieldValue = [];
                        let w = window.innerWidth;

                        for (var field in fields) {
                            fieldValue[field] = '';
                        }

                        setIsFormSuccess(true)
                        setFieldsValue(fieldValue)
                        setIsCheckboxAgree(!isCheckboxAgree)
                        setFieldsError({})

                        gtmPush({
                            event: 'cf7submission',
                            formId: cf7id
                        });

                        if (w < 992) {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                            });
                        }

                        clearTimeout(validationTimeout);
                    }

                }, 1000 );
            })
            .catch(error => {
                console.error(error);

                setIsLoading(false)
            });
    };



    return (
        <div className={`contact-form loading-block loading-block--2${isFormSuccess ? ' contact-form--success' : ''}`}>
            <div className="contact-form__subtitle">{form_data.steps && form_data.steps[step - 1].step_name}</div>
            <div className="contact-form__glow"></div>
            <div className="contact-form__triangle"></div>
            <div className="contact-form__lines"></div>
            <div className="contact-form__lines contact-form__lines--2"></div>
            
            {heading && <div className="contact-form__heading contact-form__heading--desktop-hidden" dangerouslySetInnerHTML={{ __html: heading}} />}
            <form
                className="contact-form__form"
                onSubmit={sendForm}
                autoComplete="off"
            >
                {form_data.steps && <div className="contact-form__count" dangerouslySetInnerHTML={{__html: form_data.steps[step - 1].step_counter}} />}

                {step !== 1 && step !== stepsAmount &&
                    <div className="contact-form__back">
                        <Button
                            type="button"
                            buttonStyle="flat"
                            text={form_data.back_button_label}
                            clickHandler={prevStep}
                            iconName="icon-angle-left"
                        />
                    </div>
                }

                {step === 1 && (
                    <>
                        {form_data.steps && <div className="contact-form__title" dangerouslySetInnerHTML={{__html: form_data.steps[0].step_title}} />}

                        {firstStepFields.map(item => {
                            return (
                                <Field
                                    fieldData={item}
                                    error={fieldsError[item.field_name_attribute]}
                                    value={fieldsValue[item.field_name_attribute]}
                                    changeHandler={inputHandler}
                                    requiredLabel={form_data.required_label}
                                    key={item.field_name_attribute}
                                />
                            )
                        })}

                        <Button
                            buttonStyle="primary"
                            text={form_data.next_button_label}
                            iconName="icon-angle-right"
                            iconAfter
                        />
                    </>
                )}

                {step === 2 &&
                    <>
                        {form_data.steps && <div className="contact-form__title" dangerouslySetInnerHTML={{__html: form_data.steps[1].step_title}} />}

                        {secondStepFields.map(item => {
                            return (
                                <Field
                                    fieldData={item}
                                    error={fieldsError[item.field_name_attribute]}
                                    value={fieldsValue[item.field_name_attribute]}
                                    changeHandler={inputHandler}
                                    requiredLabel={form_data.required_label}
                                    key={item.field_name_attribute}
                                />
                            )
                        })}

                        <div className="contact-form__checkbox">
                            <div className={`checkbox${(fieldsError['agree']) ? ' checkbox--not-checked' : ''}`}>
                                <input required id="agree" name="agree" checked={isCheckboxAgree} type="checkbox" onChange={checkboxAgreeHandler}/>
                                <label htmlFor="agree">{form_data.agree_field_label}</label>
                                <i className="icon-error"></i>
                            </div>
                        </div>

                        <Button
                            buttonStyle="submit"
                            isLoading={isLoading}
                            text={form_data.submit_label}
                            isFormSuccess={isFormSuccess}
                        />
                    </>
                }

                {step === 3 &&
                    <>
                        <div className="contact-form__success">
                            <div className="success__head">
                                <div className="success__title" dangerouslySetInnerHTML={{__html: form_data.steps[2].step_title}} />
                                <div className="success__img" dangerouslySetInnerHTML={{__html: form_data.success_image}}/>
                            </div>
                            <div className="success__text" dangerouslySetInnerHTML={{__html: form_data.success_text}}/>
                        </div>
                    </>
                }
            
            </form>
        </div>
    )
};

export default ContactForm;