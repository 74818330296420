import React from 'react';

const Newsletter = (props) => {
    const content = props.content;
    const { parallax } = props;

    return (
        <section className='newsletter anim-item'>
            <div className='number newsletter__number'>8</div>
            <div className='newsletter__textHolder'>
                <div className='e-commerce-page__title newsletter__title'>
                    <h2 className='e-commerce-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                    <div className='e-commerce-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
                </div>
                <div className="e-commerce-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
            </div>
            <div className="newsletter__image e-commerce-page__image">
                <div className="rightPhone fadeInRight">
                    <img style={{transform: parallax(-1, -1)}} src={content.right_phone_image.url} alt={content.right_phone_image.alt} />
                </div>
                <div className="leftPhone fadeInRight">
                    <img style={{transform: parallax(1, 1)}} src={content.left_phone_image.url} alt={content.left_phone_image.alt} />
                </div>
            </div>
        </section>
    )
};

export default Newsletter;
