import React from 'react';
import Particles from 'react-particles-js';

const Tracking = ({content}) => {

    const particles = {
        particles: {
            number: { value: 15, },
            color: { value: '#3E3E53' },
            opacity: {
                value: 0.5,
                anim: { enable: true }
            },
            size: {
                value: 12,
                random: true,
                anim: {
                    enable: true,
                    speed: 3
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                speed: 1
            }
        }
    }

    return (
        <section className='tracking anim-item'>
            <div className='number tracking__number'>6</div>
            <div className='tracking__textHolder'>
                <div className='e-commerce-page__title tracking__title'>
                    <h2 className='e-commerce-page__title-text'
                        dangerouslySetInnerHTML={{__html: content.title.text}}></h2>
                    <div className='e-commerce-page__title-bg'
                         dangerouslySetInnerHTML={{__html: content.title.background_text}}></div>
                </div>
                <div className="e-commerce-page__text" dangerouslySetInnerHTML={{__html: content.content.text}}/>
                <a href={content.content.button.url} className="e-commerce-page__button">
                    {content.content.button.title}
                </a>
            </div>
            <div className='tracking__image e-commerce-page__image technologies-image fadeInRight'>
                <Particles params={particles} style={{width: '100%'}}/>
                {
                    content.gears.map((item, key) => {
                        return (
                            <div className={`gear gear--${key+1}`} key={key}>
                                <img className="gear__image" src={item.gear.url} alt={item.gear.alt} />
                                <img className="gear__text"  src={item.text.url} alt={item.text.alt} />
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
};

export default Tracking;
