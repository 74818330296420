import React from 'react';

const Info = (props) => {
    const { title, text } = props.content;

    return (
        <section className="info">
            <h1 className="info__title" dangerouslySetInnerHTML={{ __html: title }} />
            <div className="info__bg">
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className="info__text" dangerouslySetInnerHTML={{ __html: text }} />
        </section>
    )
};

export default Info;
