import React, {useEffect, useState} from "react";

import NotFound from "../NotFound/NotFound";
import Loader from "../../../components/Loader/Loader";
import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";
import CVFileCreator from "./CVFileCreator";
import "./CVPage.scss";

import axios from "axios";
import API from "../../../api";

const CVPage = (props) => {

    const [page_data, setPage_data] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);
    const [notFoundStatus, setNotFoundStatus] = useState(false);

    useEffect(() => {
        let slug_page = props.match.params.cvpage;

        axios.get(API.basePath + '/wp-json/wp/v2/cvpage/?slug=' + slug_page)
            .then(response => {
                if (response.data.length !== 0) {
                    setPage_data(response.data[0].acf);
                    setYoast_meta(response.data[0]);
                    setLoaded_status(true);
                    props.setHeaderOptions('cv', true, true);
                } else {
                    setNotFoundStatus(true);
                }
            })
            .catch(error => {
                console.log(error);
                setNotFoundStatus(true);
            });
        props.setContactElements(false, false, false);
    }, [props.match.params.cvpage]);

    if (!notFoundStatus) {
        if (loaded_status) {

            let phoneNumber = page_data.cv_content.phone_number;
            phoneNumber = phoneNumber.replace(/[\s()+]/g, "");
            let siteDisplay = page_data.cv_content.site;
            siteDisplay = siteDisplay.replace(/https?:\/\//g, "");

            let photo_url, photo_class;
            if (page_data.cv_content.photo) {
                photo_class = "-normal";
                photo_url = page_data.cv_content.photo.url;
            } else {
                photo_class = "-default";
                photo_url = "";
            }

            return (
                <>
                    <PageMeta metaData={yoast_meta || null} hreflangs={page_data['hreflang_list'] || null} />
                    <CustomSchema schema={page_data.schema} />
                    <div className="cv-page cv-page--preloader">
                        <div className={"cv-page__content"}>
                            <div className="cv-page__top-bg"></div>
                            <div className="cv-page__bottom-bg"></div>
                            <div className="cv-page__container">
                                <div className={`cv-page__photo cv-page__photo${photo_class}`}>
                                    <img className={`cv-page__photo${photo_class}`} src={photo_url}/>
                                </div>
                                <div className="cv-page__info">
                                    <div className="cv-page__info-text">
                                        <h1 className="cv-page__info-name">{page_data.cv_content.first_name} {page_data.cv_content.last_name}</h1>
                                        <p className="cv-page__info-position">{page_data.cv_content.position}</p>
                                    </div>
                                    {page_data.cv_content.linkedin_link &&
                                    <div className="cv-page__info-social-links">
                                        <a href={page_data.cv_content.linkedin_link} target="_blank"
                                           rel="nofollow noopener" className="social-page__link">
                                            <i className="icon-linkedin"></i>
                                        </a>
                                    </div>
                                    }
                                </div>
                                <div className="cv-page__info_list cv-page__phone">
                                    <a href={`tel:+${phoneNumber}`}>
                                        <div className="cv-page__info_list-icons">
                                            <i className="icon-phone"></i>
                                        </div>
                                        <p>+{page_data.cv_content.phone_number}</p>
                                    </a>
                                </div>
                                <div className="cv-page__info_list cv-page__email">
                                    <a href={`mailto:${page_data.cv_content.email}`}>
                                        <div className="cv-page__info_list-icons">
                                            <i className="icon-envelope"></i>
                                        </div>
                                        <p>{page_data.cv_content.email}</p>
                                    </a>
                                </div>
                                {siteDisplay &&
                                    <div className="cv-page__info_list cv-page__site">
                                        <a href={page_data.cv_content.site} target="_blank"
                                        rel="nofollow noopener noreferrer">
                                            <div className="cv-page__info_list-icons">
                                                <i className="icon-sphere"></i>
                                            </div>
                                            <p>{siteDisplay}</p>
                                        </a>
                                    </div>
                                }
                                <CVFileCreator cv_content={page_data.cv_content} className='cv-page__btn'/>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="cv-page cv-page--preloader">
                        <div className="cv-page__content">
                            <div className="cv-page__top-bg"></div>
                            <div className="cv-page__bottom-bg"></div>
                            <div className="cv-page__container"></div>
                        </div>
                        <Loader/>
                    </div>
                </>
            );
        }
    } else {
        return <NotFound setContactElements={props.setContactElements} />;
    }

};

export default CVPage;