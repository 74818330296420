import React, { useEffect, useState } from "react";

import axios from "axios";

import API from "../../../../api";

const PostImages = (props) => {
  const [media, setMedia] = useState([]);
  const { postId, isBig } = props;

  useEffect(() => {
    if (postId) {
      (async () => {
        try {
          const res = await axios.get(
            API.basePath + "/wp-json/wp/v2/media/" + postId
          );

          if (res.data) {
            setMedia(res.data);
          } else {
            console.log(res);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [postId]);


  if (media.media_details) {
    const img = media.media_details.sizes.full.source_url

    return <span className={isBig ? "post__img" : "articles__img"} style={{ display: "block", backgroundImage: `url(${img})` }} ></span>
  } else {
    return null
  }
};

export default PostImages;
