import React from 'react';

const ContentManagement = (props) => {
    const content = props.content;

    return (
        <section className='content-management anim-item'>
            <div className='number content-management__number'>4</div>
            <div className='content-management__top'>
                <div className='content-management__textHolder'>
                    <div className='corporate-websites-page__title'>
                        <h2 className='corporate-websites-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                        <div className='corporate-websites-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
                    </div>
                    <div className="corporate-websites-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
                </div>
                <div className="content-management__image corporate-websites-page__image">
                    <div className='gear-wrapper gear-wrapper--1'>
                        <img src={content.gear_1.url} alt={content.gear_1.alt} />
                        <img src={content.label_1.url} alt={content.label_1.alt} />
                    </div>
                    <div className='gear-wrapper gear-wrapper--2'>
                        <img src={content.gear_2.url} alt={content.gear_2.alt} />
                        <img src={content.label_2.url} alt={content.label_2.alt} />
                    </div>
                    <div className='gear-wrapper gear-wrapper--3'>
                        <img src={content.gear_3.url} alt={content.gear_3.alt} />
                        <img src={content.label_3.url} alt={content.label_3.alt} />
                    </div>
                </div>
            </div>
            <div className='content-management__bottom'>
                <div className='content-management__bottom_item'>
                    <div className='content-management__bottom_number'>
                        <div className='chapter-number'>4.</div>
                        <div className='underchapter-number'>1</div>
                    </div>
                    <img className='content-management__bottom_icon' src={content.icon_1.url} alt={content.icon_1.alt} />
                    <div className='content-management__bottom_text' dangerouslySetInnerHTML={{ __html: content.text_1 }} />
                </div>
                <div className='content-management__bottom_item'>
                    <div className='content-management__bottom_number'>
                        <div className='chapter-number'>4.</div>
                        <div className='underchapter-number'>2</div>
                    </div>
                    <img className='content-management__bottom_icon' src={content.icon_2.url} alt={content.icon_2.alt} />
                    <div className='content-management__bottom_text' dangerouslySetInnerHTML={{ __html: content.text_2 }} />
                </div>
                <div className='content-management__bottom_item'>
                    <div className='content-management__bottom_number'>
                        <div className='chapter-number'>4.</div>
                        <div className='underchapter-number'>3</div>
                    </div>
                    <img className='content-management__bottom_icon' src={content.icon_3.url} alt={content.icon_3.alt} />
                    <div className='content-management__bottom_text' dangerouslySetInnerHTML={{ __html: content.text_3 }} />
                </div>
            </div>
        </section >
    )
};

export default ContentManagement;
