import React from 'react';
import { Link } from "react-router-dom";

const Design = (props) => {
    const content = props.content;
    const { parallax } = props;

    return (
        <section className='design anim-item'>
            <div className='number design__number'>2</div>
            <div className='design__textHolder'>
                <div className='corporate-websites-page__title design__title'>
                    <h2 className='corporate-websites-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                    <div className='corporate-websites-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
                </div>
                <div className="corporate-websites-page__text1" dangerouslySetInnerHTML={{ __html: content.text_1 }} />
                <img className="design__icon" src={content.icon.url} alt={content.icon.alt} />
                <div className="corporate-websites-page__text2" dangerouslySetInnerHTML={{ __html: content.text_2 }} />
                <div className="design__link-show">
                    <Link to={content.button.url} className="base-btn">{content.button.label}</Link>
                </div>
            </div>
            <div className='design__image corporate-websites-page__image design-image fadeInLeft'>
                <div className="design__image corporate-websites-page__image">
                    <div className="card1 fadeInLeft">
                        <img style={{ transform: parallax(1, 1) }} src={content.card_1.url} alt={content.card_1.alt} />
                    </div>
                    <div className="card2 fadeInLeft">
                        <img style={{ transform: parallax(-1, -1) }} src={content.card_2.url} alt={content.card_2.alt} />
                    </div>
                    <div className="card3 fadeInLeft">
                        <img style={{ transform: parallax(1, 1) }} src={content.card_3.url} alt={content.card_3.alt} />
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Design;
