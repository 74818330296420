import React from 'react'

import './Text.scss'

const Text = (props) => {

    const { text } = props

    return <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
}

export default Text;
