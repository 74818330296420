import React from 'react'

import SecondLevelTitle from '../SecondLevelTitle/SecondLevelTitle';
import Text from '../Text/Text'

import './TechnologyContent.scss'

const TechnologyContent = (props) => {

    const { title, text, icon } = props
    return (
        <>
            {icon ? <img className="content__icon" src={icon.url} alt={icon.name} /> : null}
            <SecondLevelTitle title={title} />
            <Text text={text} />
        </>
    )
}

export default TechnologyContent;
