import React, { useState, useEffect } from 'react';
import Plx from 'react-plx';

const Compliant = (props) => {
    const { title, text, image } = props.content;
    const [parallaxStatus, setParallaxStatus] = useState(true);

    useEffect(() => {
        let w = window.innerWidth;

        if (w > 992) {
            setParallaxStatus(false);
        }
      }, []);

    const dataParallax = [
        {
            start: '.compliant__image',
            easing: 'easeOut',
            duration: 900,
            properties: [
                {
                    startValue: -100,
                    endValue: 0,
                    unit: '%',
                    property: 'translateX',
                },
            ],
        },
    ];

    return (
        <section className='compliant right-side-number'>
            <div className='number number--right'>2</div>
            <div className='compliant__textHolder'>
                <div className='usercentrics-page__title compliant__title'>
                    <h2 className='usercentrics-page__title-text' dangerouslySetInnerHTML={{ __html: title.text }} />
                    <div className='usercentrics-page__title-bg' dangerouslySetInnerHTML={{ __html: title.background_text }} />
                </div>
                <div className="usercentrics-page__text" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            <Plx className='compliant__image' parallaxData={ dataParallax } disabled={parallaxStatus}>
                <img
                    className='compliant__image--sheet'
                    src={image.sheet.url}
                    alt={image.sheet.alt}
                />
                <img
                    className='compliant__image--pen'
                    src={image.pen.url}
                    alt={image.pen.alt}
                />
            </Plx>
        </section>
    )
};

export default Compliant;
