import React, { useState, useEffect } from 'react';

import "./Field.scss";

const Field = (props) => {
    const { fieldData: field, error, changeHandler } = props
    const FieldType = field.field_type[0]  // input || textarea

    const inputType = field.field_type[0] === 'input' ? field.input_type : undefined
    const name = field.field_name_attribute

    const activeFieldClass = ' field--active'
    const filledFieldClass = ' field--filled'
    const errorFieldClass = ' field--error'
    
    const [activeField, setActiveField] = useState('');
    const [filledField, setFilledField] = useState('');
    const [errorField, setErrorField] = useState('');

    const fieldFocus = () => {
        setActiveField(activeFieldClass)
    };

    const fieldFocusOut = () => {
        setActiveField('')
    };

    const clearInput = () => {
        changeHandler(name, '')
    }

    const onChangeHandler = (e) => {
        const value = e.target.value
        changeHandler(name, value)
    }

    useEffect(() => {
        setFilledField(props.value ? filledFieldClass : '')
    }, [props.value])

    useEffect(() => {
        setErrorField(error ? errorFieldClass : '')
    }, [error])

    return (
        <div className={`field${activeField}${filledField}${errorField}`}>
            <div className={`field__input${field.field_type[0] === 'textarea' ? ' field__input--textarea' : ''}`}>
                <div className="field__label-block">
                    <label className="field__label" htmlFor={name}>
                        <div className="field__label-text">{field.label}</div>
                        {field.is_required === 'no' &&
                            <div className="field__label-required">{props.requiredLabel}</div>
                        }
                    </label>
                </div>
                <FieldType
                    type={inputType}
                    name={name}
                    onBlur={fieldFocusOut}
                    onFocus={fieldFocus}
                    onChange={onChangeHandler}
                    placeholder={field.placeholder}
                    value={props.value ? props.value : ''}
                    required={field.is_required === 'yes' ? true : false}
                    id={name}
                />
                <div className="field__input--clear" onClick={clearInput}>
                    <i className="icon-close"></i>
                </div>
            </div>
            <div className={`field__status ${(error) ? 'field__status--visible':''}`}>
                <div className="field__status-msg">{error}</div>
                <div className="field__status-icon">
                    <i className="icon-error"></i>
                </div>
            </div>
        </div>
    );
};

export default Field;