import React from 'react';

const OnlineMarketing = (props) => {
    const content = props.content;
    const { parallax } = props;

    return (
        <section className='online-marketing anim-item'>
            <div className='number online-marketing__number'>5</div>
            <div className='corporate-websites-page__title'>
                <h2 className='corporate-websites-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                <div className='corporate-websites-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
            </div>
            <div className="corporate-websites-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
            <div className="online-marketing__image corporate-websites-page__image">
                <div className="card1 fadeInRight">
                    <img style={{ transform: parallax(1, 1) }} src={content.card_1.url} alt={content.card_1.alt} />
                </div>
                <div className="card2 fadeInRight">
                    <img style={{ transform: parallax(-1, 1) }} src={content.card_2.url} alt={content.card_2.alt} />
                </div>
                <div className="speaker fadeInRight">
                    <img style={{ transform: parallax(1, -1) }} src={content.speaker.url} alt={content.speaker.alt} />
                </div>
            </div>
        </section>
    )
};

export default OnlineMarketing;
