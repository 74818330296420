import React, { useState, useRef, useEffect } from "react";
import isObject from "../../helpers/isObject";

import styles from "./VideoPlayer.module.scss";

function VideoPlayer(props) {
    const {
        video,
        poster,
        playIcon,
        ratioVertical,
        ratioHorizontal,
        className = '',
        allowPlay = true
    } = props

    // allowPlay - property can allow or disallow video playback. Can be used for Modal. allowPlay = false : The Modal is closed

    const [isPlaying, setIsPlaying] = useState(false);
    const [showPreview, setShowPreview] = useState(true);
    const videoRef = useRef(null);

    const isPoster = isObject(poster, true)
    const isPlayIcon = isObject(playIcon, true)
    const isShowPreview = showPreview && (isPoster || isPlayIcon)
    const horisontal = Number(ratioHorizontal) || video.width
    const vertical = Number(ratioVertical) || video.height

    const playVideo = () => {
        if (!isPlaying && allowPlay) {
            videoRef.current.play();
            setIsPlaying(!isPlaying);
            setShowPreview(false);
        }
    };

    useEffect(() => {
        if (!allowPlay) {
            videoRef.current.pause();

        } else if (!showPreview && allowPlay) {
            videoRef.current.play();
        }
    }, [allowPlay])

    if ( !isObject(video, true) ) {
        return null;
    }

    return (
        <div className={`${styles.wrapper} ${className}`} style={{'--x': horisontal, '--y': vertical }}>
            
            {isShowPreview &&
                <div className={styles.preview} onClick={playVideo}>
                    {isPoster &&
                        <img
                            className={styles.poster}
                            src={poster.url}
                            alt={poster.alt}
                            width={poster.width}
                            height={poster.height}
                        />
                    }
                    {isPlayIcon &&
                        <img
                            className={styles.icon}
                            src={playIcon.url}
                            alt={playIcon.alt}
                            width={playIcon.width}
                            height={playIcon.height}
                        />
                    }
                </div>
            }

            <video
                className={styles.video}
                ref={videoRef}
                src={video.url}
                width={video.width}
                height={video.height}
                controls={!isShowPreview}
            ></video>
            
        </div>
    )
}

export default VideoPlayer;