import React, {Component} from "react";
import { Link } from 'react-router-dom';
import history from '../../history';

class DelayLink extends Component {

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    handleClick = (e) => {

        e.preventDefault();
        this.props.clickAction();

        setTimeout(() => {
            history.push(this.props.to);
        }, this.props.delay);
    };

    render() {

        return (
            <Link to={this.props.to}
                  onClick={this.handleClick}
                  className={this.props.className}
                  onMouseEnter={this.props.mouseEnterAction}
                  onMouseLeave={this.props.mouseLeaveAction}>
                {this.props.children}
            </Link>
        );
    }
}

export default DelayLink;