import React, {Component} from "react";
import './IncludedServices.scss';

class IncludedServices extends Component {

    render() {

        if(this.props.content) {

            const content = this.props.content;

            return (
                <>
                    <div className="included-services">
                        <div className="included-services__title">
                            <h2 className="included-services__title-text">
                                {content.section_title}
                            </h2>
                            <div className="included-services__title-bg">
                                {content.section_title}
                            </div>
                        </div>

                        <div className="included-services__list">
                            {
                                content.services_list.map((item, key) => {
                                    return (
                                        <div className="included-services__item" key={key}>
                                            <div className="included-services__item-inner">
                                                <div className="included-services__item-content">
                                                    <div className="included-services__item-icon">
                                                        <img src={item.service.icon.url} alt={item.service.icon.alt}/>
                                                        <i className="icon-check"></i>
                                                    </div>
                                                    <div className="included-services__item-title" dangerouslySetInnerHTML={{ __html: item.service.title}} />
                                                </div>
                                                <div className="included-services__item-index">
                                                    {key+1}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </>
            );
        } else {
            return (
                <div className="included-services"></div>
            );
        }
    }
}

export default IncludedServices;