import React from 'react'

import { Link } from 'react-router-dom';

import PostImages from '../Posts/PostImages';

import PostCategory from '.././Posts/PostCategory';

import { Html5Entities } from 'html-entities';

import dateFormat from "dateformat";

const htmlEntities = new Html5Entities();



const PostList = (props) => {

    return (
        <ul className="articles__wrapper">
            {props.post.map((post, key) => {
                const title = post.title.rendered[0].toUpperCase() + post.title.rendered.slice(1);
                const {date} = post
                const day = dateFormat(date, `dS`);
                const month = dateFormat(date, `mmmm`);
                const year = dateFormat(date, `yyyy`);
                
                return (
                    <li className="articles__item" key={post.id} style={{ animationDelay: `${key * 100}ms` }}>
                        <Link className="articles__item-link" to={`/blog/${post.slug}`}>
                            <PostImages postId={post.featured_media} title={title} post={post} />
                            <span className="articles__content">
                                <span className="articles__content-item">
                                    <span className="post-date">
                                        <span>{day} {month.toUpperCase()} {year}</span>
                                    </span>
                                    <PostCategory post={post.id} />
                                    <h3 className="articles__content-title">{htmlEntities.decode(title)}</h3>
                                </span>
                                <span className="articles__content-btn" >
                                    <i className="icon-angle-right white"></i>
                                </span>
                            </span>
                        </Link>
                    </li>
                )
            })}

        </ul>
    )
}

export default PostList;