import React from 'react'
import './PostProjects.scss'
import {ReactComponent as SvgTypeIcon} from './images/type-icon.svg';
import {ReactComponent as SvgTechnologyIcon} from './images/technology-icon.svg';
import {ReactComponent as SvgLinkIcon} from './images/link-icon.svg';

export default function PostProjects({postName = '', page_data = {}} = {}) {
    const {acf = {}} = page_data;

    return acf.projects &&
        <div id="projects" className={`${postName}__content_projects`}>
            <h2 className={`${postName}__content_projects-title title-post`}>{acf.projects_title_text}</h2>
            <div className={`${postName}__content_projects-wrapper`}>
                {
                    acf.projects.map((project) => {
                        return (
                            <div className="projects-card" key={project.title}>
                                <div className="projects-card__top">
                                    <img className="projects-card__img" src={project.thumbnail.url} alt={project.title}/>
                                    {project.logotype && project.logotype.url && project.logotype.url.length > 0 &&
                                        <div className="projects-card__logo">
                                            <img src={project.logotype.url} alt="logo"/>
                                        </div>}
                                </div>
                                <div className="projects-card__wrap">
                                    <div className="projects-card__bottom">
                                        <div className="projects-card__text-container">
                                            {project.title &&
                                                <div className="projects-card__title projects-card__text">
                                                    <p dangerouslySetInnerHTML={{__html: project.title}}/>
                                                </div>
                                            }
                                            {project.type &&
                                                <div className="projects-card__type projects-card__text">
                                                    <SvgTypeIcon/>
                                                    <p dangerouslySetInnerHTML={{__html: project.type}}/>
                                                </div>
                                            }
                                            {project.technologies &&
                                                <div className="projects-card__text projects-card__technologies">
                                                    <SvgTechnologyIcon/>
                                                    <p dangerouslySetInnerHTML={{__html: project.technologies}}/>
                                                </div>
                                            }
                                            {project.link &&
                                                <div className="projects-card__text projects-card__link">
                                                    <SvgLinkIcon/>
                                                    <p dangerouslySetInnerHTML={{__html: project.link}}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {project.show_link_to_detail_page === "1" &&
                                        <a
                                            href={project.detail_page.url}
                                            className="base-btn base-btn--dark projects-card__btn"
                                        >
                                            {project.detail_page.title}
                                        </a>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
}