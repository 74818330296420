import React, { Component } from "react";

class FooterEventsOnScroll extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('load', this.handleLoad);
    }

    handleScroll = () => {

        let stickyBtn = document.getElementsByClassName("footer__btn-scroll-top")[0];
        let stickyEnd = document.getElementsByClassName('footerWithContactBlock')[0].offsetTop;
        let wH = window.innerHeight;
        let bottomPosition = stickyEnd - stickyBtn.offsetHeight;

        if (window.innerWidth < 991) {

            let scrollPosition = window.scrollY || document.documentElement.scrollTop;

            if (scrollPosition > wH / 4) {
                this.props.changeButtonsPosition(true);
            } else {
                this.props.changeButtonsPosition();
            }
        }

        if (window.innerWidth > 992) {

            let scrollPosition = window.scrollY || document.documentElement.scrollTop;

            if (scrollPosition < wH / 2) {
                stickyBtn.classList.remove('visible');
            } else if (scrollPosition > wH / 2 && scrollPosition + wH < stickyEnd) {
                stickyBtn.classList.add('visible');
                stickyBtn.removeAttribute("style");
            } else {
                stickyBtn.style.position = "absolute";
                stickyBtn.style.bottom = "auto";
                stickyBtn.style.top = bottomPosition + "px";
            }

            let pagePath = this.props.location.pathname;

            /*
            *   Changing the color of the up button when page is scrolling
            */

            if (pagePath === '/gdpr-cookie-consent') {

                let s1 = document.getElementsByClassName('cookie-opt-in__meta')[0];
                let s2 = document.getElementsByClassName('docs__header')[0];
                let s3 = document.getElementsByClassName('docs__content-col--bottom-right')[0];
                let s4 = document.getElementsByClassName('included-services')[0];
                let s5 = document.getElementsByClassName('cookie-feedback__content')[0];
                let s6 = document.getElementsByClassName('contact-us')[0];

                if (s1 && s2 && s3 && s4 && s5 && s6) {

                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - wH;

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - wH;

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - wH;

                    let s4Options = s4.getBoundingClientRect();
                    let s4Position = s4Options.top - wH;

                    let s5Options = s5.getBoundingClientRect();
                    let s5Position = s5Options.top - wH;

                    let s6Options = s6.getBoundingClientRect();
                    let s6Position = s6Options.top - wH;

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s1Position < 0 && s1Position < s1Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s2Position < 0 && s2Position < s2Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s3Position < 0 && s3Position < s3Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position > 0 && s6Position > 0) {

                        if (s4Position < 0 && s4Position < s4Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0 && s6Position > 0) {

                        if (s5Position < 0 && s5Position < s5Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0 && s6Position < 0) {

                        if (s6Position < 0 && s6Position < s6Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    }
                }

            } else if (pagePath === '/it-support') {

                let s1 = document.getElementsByClassName('support-advantages__list')[0];
                let s2 = document.getElementsByClassName('support-numbers')[0];
                let s3 = document.getElementsByClassName('support-projects')[0];

                if (s1 && s2 && s3) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - wH;

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - wH;

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - wH;

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0) {

                        if (s1Position < 0 && s1Position < s1Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0) {

                        if (s2Position < 0 && s2Position < s2Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0) {

                        if (s3Position < 0 && s3Position < s3Options.height) {
                            this.props.changeButtonStatus();
                        }

                    }
                }

            } else if (pagePath === '/projects/bw-online-shop') {

                let s1 = document.getElementsByClassName('description-success')[0];
                let s2 = document.getElementsByClassName('timeline')[0];
                let s3 = document.getElementsByClassName('project-implementation')[0];

                if (s1 && s2 && s3) {

                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - wH;

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - wH;

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - wH;

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0) {
                        if (s1Position < 0 && s1Position < s1Options.height) {
                            this.props.changeButtonStatus(true);
                        }
                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0) {
                        if (s2Position < 0 && s2Position < s2Options.height) {
                            this.props.changeButtonStatus();
                        }
                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0) {

                        if (s3Position < 0 && s3Position < s3Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    }
                }
            } else if (pagePath === '/projects') {

                this.props.changeButtonStatus(true);

            } else if (pagePath === '/partners') {

                this.props.changeButtonStatus(true);

            } else if (pagePath === '/hosting') {

                let s1 = document.getElementsByClassName('hosting-page__content')[0];
                let s2 = document.getElementsByClassName('hosting-split-screen')[0];
                let s3 = document.getElementsByClassName('hosting-technologies')[0];
                let s4 = document.getElementsByClassName('hosting-reviews')[0];

                if (s1 && s2 && s3 && s4) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - wH;

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - wH;

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - wH;

                    let s4Options = s4.getBoundingClientRect();
                    let s4Position = s4Options.top - wH;

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0 && s4Position > 0) {

                        if (s1Position < 0 && s1Position < s1Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0 && s4Position > 0) {

                        if (s2Position < 0 && s2Position < s2Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position > 0) {

                        if (s3Position < 0 && s3Position < s3Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0) {

                        if (s4Position < 0 && s4Position < s4Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    }
                }

            } else if (pagePath === '/') {

                let s1 = document.getElementsByClassName('services')[0];
                let s2 = document.getElementsByClassName('solutions')[0];
                let s3 = document.getElementsByClassName('reviews')[0];

                if (s1 && s2 && s3) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - wH;

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - wH;

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - wH;

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0) {

                        if (s1Position < 0 && s1Position < s1Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0) {

                        if (s2Position < 0 && s2Position < s2Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0) {

                        if (s3Position < 0 && s3Position < s3Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    }
                }

            } else if (pagePath === '/dpa' || pagePath === '/imprint' || pagePath === '/privacy-policy' || pagePath === '/blog' || pagePath === '/sma' || pagePath === '/amtc') {
                this.props.changeButtonStatus(true);
            } else if (pagePath === '/web-design') {

                let s1 = document.getElementsByClassName('perfectes')[0];
                let s2 = document.getElementsByClassName('services')[0];
                let s3 = document.getElementsByClassName('mobile-first')[0];
                let s4 = document.getElementsByClassName('design-process')[0];
                let s5 = document.getElementsByClassName('faqBlock')[0];

                if (s1 && s2 && s3 && s4 && s5) {

                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - wH;

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - wH;

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - wH;

                    let s4Options = s4.getBoundingClientRect();
                    let s4Position = s4Options.top - wH;

                    let s5Options = s5.getBoundingClientRect();
                    let s5Position = s5Options.top - wH;


                    if (s1Position < 0 && s2Position > 0 && s3Position > 0 && s4Position > 0 && s5Position > 0) {

                        if (s1Position < 0 && s1Position < s1Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0 && s4Position > 0 && s5Position > 0) {

                        if (s2Position < 0 && s2Position < s2Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position > 0 && s5Position > 0) {

                        if (s3Position < 0 && s3Position < s3Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position > 0) {

                        if (s4Position < 0 && s4Position < s4Options.height) {
                            this.props.changeButtonStatus();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0) {

                        if (s5Position < 0 && s5Position < s5Options.height) {
                            this.props.changeButtonStatus(true);
                        }

                    }
                }
            } else if (pagePath === '/about-us') {

                let s1 = document.getElementsByClassName('about-us-page__title')[0];
                let s2 = document.getElementsByClassName('timeline')[0];
                let s3 = document.getElementsByClassName('support-projects')[0];

                if (s1 && s2 && s3) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - wH;

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - wH;

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - wH;

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0) {

                        this.props.changeButtonStatus(false);

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0) {

                        this.props.changeButtonStatus(true);

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0) {

                        this.props.changeButtonStatus(false);

                    } else if (s1Position > 0) {
                        this.props.changeButtonStatus(true);
                    }
                }
            } else if (pagePath === '/online-shops') {

                let s1 = document.getElementsByClassName('e-commerce-page__content')[0];

                if (s1) {
                    this.props.changeButtonStatus(true);
                }

            } else if (pagePath === '/corporate-websites') {

                let s1 = document.getElementsByClassName('corporate-websites-page__content')[0];

                if (s1) {
                    this.props.changeButtonStatus(true);
                }

            } else if (pagePath === '/cookie-banner-integration') {

                let s1 = document.getElementsByClassName('usercentrics-page__content')[0];

                if (s1) {
                    this.props.changeButtonStatus(true);
                }

            } else if (pagePath === '/web-development/magento' || pagePath === '/web-development/magento/' || pagePath === '/web-development/shopware' || pagePath === '/web-development/shopware/') {

                this.props.changeButtonStatus(true);

            } else {
                let postPart = document.getElementById('post');

                if (postPart) {
                    this.props.changeButtonStatus(true);
                }
            }
        }
    };

    render() {

        return (
            <div onScroll={this.handleScroll}></div>
        );
    }
}

export default FooterEventsOnScroll;
