import React from "react";

import useMobile from "../../../../hooks/useMobile"

const WhyWe = (props) => {
    const isMobile = useMobile();

    return (
        <div className="why-we">
            <div className="why-we--top-bg"></div>
            <div className="why-we__text-container">
                <div className="why-we__title">   
                    <h2 className="why-we__title_text">{props.content.title}</h2>
                    <div className="why-we__title_bg">{props.content.title}</div>
                </div>
                <div className="why-we__info" dangerouslySetInnerHTML={{ __html: props.content.text}}></div>
            </div>
            { isMobile ?
                <img className="why-we__img" src={props.content.mobile_image.url} alt={props.content.mobile_image.alt} />
            :
                <img className="why-we__img" src={props.content.desktop_image.url} alt={props.content.desktop_image.alt} />
            }
        </div>
    )
};

export default WhyWe;
