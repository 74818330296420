import React, {Component} from "react";
import './Numbers.scss';
import Plx from 'react-plx';

class Numbers extends Component {

    render() {

        if (this.props.content) {

            const title = this.props.content.title;
            const description = this.props.content.description;
            const imgLeft = this.props.content.left_image;
            const imgRightTop = this.props.content.right_top_image;
            const imgRightBottom = this.props.content.right_bottom_image;
            const mobileMedia = this.props.content.mobile_image;
            const numbers = this.props.content.numbers;

            const dataRightTopParallax = [
                {
                    start: '.support-numbers',
                    startOffset: 100,
                    duration: 600,
                    properties: [
                        {
                            startValue: 0,
                            endValue: -170,
                            property: 'translateY',
                        },
                    ],
                },
            ];

            const dataRightBottomParallax = [
                {
                    start: '.support-numbers',
                    startOffset: 500,
                    duration: 600,
                    properties: [
                        {
                            startValue: 100,
                            endValue: 0,
                            unit: '%',
                            property: 'translateX',
                        },
                    ],
                },
            ];

            const dataLeftParallax = [
                {
                    start: '.support-projects',
                    duration: 300,
                    properties: [
                        {
                            startValue: 0,
                            endValue: 70,
                            property: 'translateY',
                        },
                    ],
                },
            ];

            return (
                <>
                    <div className="support-numbers">
                        <Plx className="parallax-layer parallax-layer__left" parallaxData={ dataLeftParallax }>
                            <img src={imgLeft.url} alt={imgLeft.alt}/>
                        </Plx>
                        <Plx className='parallax-layer parallax-layer__right-top' parallaxData={ dataRightTopParallax }>
                            <img src={imgRightTop.url} alt={imgRightTop.alt}/>
                        </Plx>
                        <Plx className="parallax-layer parallax-layer__right-bottom" parallaxData={ dataRightBottomParallax }>
                            <img src={imgRightBottom.url} alt={imgRightBottom.alt}/>
                        </Plx>
                        <div className="support-numbers__mobile-media">
                            <img src={mobileMedia.url} alt={mobileMedia.alt}/>
                        </div>
                        <div className="support-numbers__title">
                            <div className="support-numbers__title-bg">{title}</div>
                            <h2 className="support-numbers__title-text">{title}</h2>
                        </div>
                        <div className="support-numbers__gears-bg"></div>

                        <div className="support-numbers__description">
                            <p>{description}</p>
                        </div>

                        <div className="support-numbers__row">
                            {
                                numbers.map((item, key) => {
                                    return (
                                        <div className="support-numbers__row-item support-number" key={key}>
                                            <div className="support-number__value">{item.number}</div>
                                            <div className="support-number__title">{item.label}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="support-numbers">
                </div>
            );
        }
    }
}

export default Numbers;