import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './FAQBlock.scss';
import useMobile from "../../hooks/useMobile";

import axios from "axios";
import API from "../../api";

import icon from '../../images/faq-icon.svg'

const FAQBlock = (props) => {
    const [faqData, setFaqData] = useState([]);
    const [loadedStatus, setLoadedStatus] = useState(false);
    const isMobile = useMobile();

    const [activeDropdown, setActiveDropdown] = useState(null)
    const [dropdownContentHeight, setDropdownTabContentHeight] = useState(0)

    function toggleDropdown(id) {
        setActiveDropdown((prevId) => (prevId !== id ? id : null))
    }

    function setHeight() {
        if (document && activeDropdown !== null) {
        const contentBox = document.querySelector(
            `[data-id=answer--${activeDropdown}]`
        )

        if (contentBox) {
            setDropdownTabContentHeight(
            contentBox.offsetHeight ? contentBox.offsetHeight : 0
            )
        }
        }
    }

    useEffect(() => {
        setHeight()
        window.addEventListener('resize', setHeight)

        return () => {
        window.removeEventListener('resize', setHeight)
        }
    }, [activeDropdown])

    useEffect(() => {
        axios
            .get(API.basePath + '/wp-json/acf/v3/options/faq-general-settings')
            .then((response) => {
                setFaqData(response.data.acf);
                setLoadedStatus(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const faqEntity = props.content.map((item) => {
        let answer = item.post_content

        return {
            "@type": "Question",
            "name": `${item.post_title}`,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": `${answer}`
            }
        }
    })

    const microdata = {
        "@context": "https://schema.org/",
        "@type": "FAQPage",
        "mainEntity": faqEntity,
    }

    if (loadedStatus) {
        const {
            title,
            subtitle, 
            background_text
        } = faqData

        if (props.content !== null) {
            return (
                <>
                <div className='faqBlock'>
                    <div className='faqBlock--left'>
                        <h2 className='faqBlock__title'>{title}</h2>
                        <p className='faqBlock__subtitle'>{subtitle}</p>
                        <div className='faqBlock__bg-text'>{background_text}</div>
                        {!isMobile && (
                            <img className='faqBlock__icon' src={icon} alt='folder icon' />
                        )}
                    </div>
    
                    <ul className='faqBlock--right'>
                        {props.content.map((item, key) => {
                            return (
                                <li 
                                    className={`
                                        faqBlock__item
                                        ${activeDropdown === key + 1 && 'active'}
                                    `}
                                    key={item.ID} 
                                >
                                    <div 
                                        className='question'
                                        onClick={() => toggleDropdown(key + 1)}
                                    >
                                        <h3>{item.post_title}</h3>
                                        <i></i>
                                    </div>

                                    <div
                                        className='answerWrapper'
                                        style={{
                                            height:
                                            activeDropdown === key + 1
                                                ? dropdownContentHeight + 'px'
                                                : 0,
                                        }}
                                    >
                                        <div 
                                            data-id={`answer--${key + 1}`} 
                                            className='answer'
                                            dangerouslySetInnerHTML={{ __html: item.post_content}}
                                        />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>

                    {isMobile && (
                        <img className='faqBlock__icon' src={icon} alt='folder icon' />
                    )}
                </div>

                <script className='structured-data-list' type="application/ld+json">
                    {JSON.stringify(microdata)}
                </script>
                </>
            );
        } else {
            return ''
        }
    } else {
        return ''
    }
};

export default withRouter(FAQBlock);
