import React from "react";
import { useHistory } from 'react-router-dom';

import Service from "./Service";

import './Services.scss';

const Services = (props) => {
    const history = useHistory();
    const { services_list, section_title, section_bg_text, section_subtitle, support } = props.content

    const onClickHandler = (e, link) => {
        !e.target.closest('a') && history.push(link);
    };

    return (
        <div className="services" id="services">
            <div className="services__header page__meta-content">
                <div className="services__title page__meta-title page__title">
                    <h2 className="services__title-text page__title-text">{section_title}</h2>
                    <div className="services__title-bg page__title-bg">
                        <div>{section_bg_text}</div>
                    </div>
                </div>
                {section_subtitle &&
                    <div className="services__description page__meta-description" dangerouslySetInnerHTML={{ __html: section_subtitle }} />
                }
            </div>
            <div className="services__container">
                <div className="services__wrapper">
                    {
                        services_list.map((item, key) => {
                            return (
                                <div className="services__item our-service"
                                    key={key}>

                                    <Service service_data={item.service} item={key} onClickHandler={onClickHandler} />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="services__support" onClick={(e) => onClickHandler(e, support.support_page_button.url)}>
                    <div className="services__support-header">
                        <div className="services__support-header--top">
                            <img className="services__support-bg" src={support.support_bg.url} alt={support.support_bg.alt} />
                            <div className="services__support-icon" dangerouslySetInnerHTML={{ __html: support.support_icon }} />
                        </div>
                        <h3 className="services__support-title">{support.support_title}</h3>
                    </div>
                    <div className="services__support-description" dangerouslySetInnerHTML={{ __html: support.support_description }} />
                    <a href={support.support_page_button.url} className="services__support-link">
                        <span className="base-btn">
                            {support.support_page_button.title}
                        </span>
                    </a>
                </div>
            </div>
        </div >
    )
}

export default Services;
