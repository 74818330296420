import {useState, useEffect} from 'react';

const useMobile = () => {

    const [isMobile, setIsMobile] = useState(false);

    const getInnerWidth =() => {
        const isMobile = window.innerWidth < 992;
        setIsMobile(isMobile);
    };

    useEffect(() => {
        getInnerWidth();
        window.addEventListener("resize", getInnerWidth)
        return () => {
            window.removeEventListener("resize", getInnerWidth)
        }
    },[]);


    return isMobile
}

export default useMobile