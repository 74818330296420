const isObject = (value, checkIfEmpty = false) => {
    const isObj =
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value);
    if (checkIfEmpty) {
        return (
            isObj &&
            Boolean(Object.keys(value).length)
        )
    }
    return isObj;
}
export default isObject