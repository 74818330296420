import React, { useEffect, useState } from 'react';

import Description from '../Description/Description';

import axios from 'axios';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";

import API from '../../../api';

import Categories from '../Categories/Categories'

import PageMeta from '../../PageMeta/PageMeta'

import Loader from "../../Loader/Loader";

import "./Blog.scss"

const Blog = (props) => {
    const { slug } = props.match.params;

    const [page_data, setPage_data] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);
    const [categorySlug, setCategorySlug] = useState();

    useEffect(() => {
        axios.get(API.basePath + '/wp-json/wp/v2/pages?slug=blog')
            .then(response => {
                setPage_data(response.data[0].acf);
                setYoastData(response.data[0]);
                setLoaded_status(true);
                setCategorySlug(response.data[0].acf.categories.map((item) => {
                    return item.slug
                }))

            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    const setYoastData = (data) => {
        setYoast_meta(data);
    }


    if (!loaded_status) {
        return (
            <div className="blog-page-loader">
                <Loader />
            </div>
        );
    } else {
        return (
            <>
                <PageMeta metaData={yoast_meta} />
                <div className="blog-page">
                    <Description content={page_data.description} />
                    <Categories content={page_data} activeCategorySlug={slug} setYoastData={setYoastData} categories={page_data.categories}/>
                </div>

            </>
        )
    }

}

export default withRouter(Blog);