import React, { Component } from "react";

import PhoneImg from "./PhoneImg/PhoneImg";

import "./MobileFirst.scss";

export default class MobileFirst extends Component {
  ref = null;

  state = {
    isScrolling: false,
    buttonsPosition: false
  };



  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const distanceToTop = this.ref.getBoundingClientRect().top;
    if (distanceToTop < 300 && !this.state.isActive) {
      this.setState({
        isScrolling: true,
      });
    }
  };

  render() {
    const { title, text } = this.props.content;

    return (
      <section className="mobile-first" ref={(ref) => (this.ref = ref)}>
        <PhoneImg
          isScrolling={this.state.isScrolling}
          content={this.props.content}
          buttonPosition={this.state.buttonsPosition}
        />
        <div className="mobile-first__wrapper">
          <div
            className={`mobile-first__inner red${
              this.state.isScrolling ? " red-animate" : ""
              }`}
          >
            <h2
              className={`mobile-first__title${
                this.state.isScrolling ? " mobile-first__title-animate" : ""
                }`}
            > 
            {title}
            </h2>
            <span className="mobile-first__title-bg">{title}</span>
          </div>
          <div className="mobile-first__inner mobile-first__inner-right">
            <div
              className={`pink${this.state.isScrolling ? " pink-animate" : ""}`}
            >
              {title}
              <span className="pink-bg">{title}</span>
            </div>
            <div
              className={`mobile-first__text${
                this.state.isScrolling ? " mobile-first__text-animate" : ""
                }`}
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </div>
        </div>
      </section>
    );
  }
}
