import React, {Component} from "react";
import ReviewsSlider from './ReviewsSlider';

import axios from "axios";
import API from "../../../../api";

import './Reviews.scss';

class Reviews extends Component {

    state = {
        slider_content: [],
        data_load_status: false
    };

    componentDidMount() {

        // Get slider content
        axios.get(API.basePath+'/wp-json/wp/v2/review/')
            .then(response => {
                this.setState({
                    slider_content: response.data,
                    data_load_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {

        if (this.state.data_load_status && this.props.content) {

            let slider_data = [];

            this.state.slider_content.map(function (item, key) {
                slider_data[key] = item.acf;
            });

            return (
                <div className="reviews">
                    <ReviewsSlider sliderData={slider_data} sectionData={this.props.content}/>
                </div>
            );

        } else {
            return (
                <></>
            );
        }
    }
}

export default Reviews;