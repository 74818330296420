import React, { useState, useEffect } from "react";

import MainBanner from "../../MainBanner/MainBanner";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import WhyWe from "./WhyWe/WhyWe";
// import Gallery from "./Gallery/Gallery";
import TimeLine from "./TimeLine/TimeLine";
import Projects from "./Projects/Projects";
import Founders from "./Founders/Founders";

import axios from "axios";
import API from "../../../api";
import Loader from "../../../components/Loader/Loader";

import TextSlider from "./../../MainBanner/TextSlider/TextSlider";
import ScrollIcon from '../../SuccessStory/ScrollIcon/ScrollIcon';
import VideoPlayer from '../../VideoPlayer';
import useMobile from "../../../hooks/useMobile";
import Particles from "react-particles-js";

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import "./AboutUs.scss";

const AboutUs = (props) => {
    const isMobile = useMobile();
    const [page_data, setPage_data] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);

    const particles = {
        particles: {
            number: { value: 30, },
            color: { value: "#3E3E53" },
            opacity: {
                value: 0.9,
                anim: { enable: true }
            },
            size: {
                value: 20,
                random: true,
                anim: {
                    enable: true,
                    speed: 5
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                speed: 5
            },
            fullScreen: false
        }
    }

    useEffect(() => {
        axios
            .get(API.basePath + "/wp-json/wp/v2/pages?slug=about-us")
            .then((response) => {
                setPage_data(response.data[0]);
                setYoast_meta(response.data[0]);
                setLoaded_status(true);
            })
            .catch((error) => {
                console.log(error);
            });
        props.setContactElements();
    }, []);

    const canAnimate = (element) => {
        return (element.top + element.height > 0) && (window.innerHeight - element.top > 0)
    }

    if (loaded_status) {
        const dataRightTopParallax = [
            {
                start: 'self',
                duration: 900,
                properties: [
                    {
                        startValue: 0,
                        endValue: 30,
                        unit: '%',
                        property: 'translateY',
                    },
                ],
            },
        ];
        return (
            <>
                <PageMeta metaData={yoast_meta || null} hreflangs={page_data.acf["hreflang_list"] || null} />
                <CustomSchema schema={page_data.acf.schema} />
                <div className="about-us-page">
                    <div className="start-banner">
                        <div className={`start-banner__slider loading-block loading-block--3 start-banner__slider--about-us`}>
                            <TextSlider slides={page_data.acf.main_banner_content.slide}/>
                        </div>
                        <div className="video-wrapper">
                            <VideoPlayer
                                video={page_data.acf.main_banner_content.video.video}
                                poster={page_data.acf.main_banner_content.video.video_image}
                                playIcon={page_data.acf.main_banner_content.video.icon}
                                className="video"
                            />
                            <Particles params={particles} />
                        </div>

                        <div className="scroll-down loading-block loading-block--4">
                            <ScrollIcon color={`#3e3e53`} borderColor={`#3e3e53`} />
                        </div>
                    </div>
                    <div className="about-us-page__content" id="scrollPosition">
                        <div className="about-us-page__title">
                            <div className="about-us-page__title--top-bg"></div>
                            <h1 className="about-us-page__title-text" dangerouslySetInnerHTML={{ __html: page_data.acf.title }} />
                            <div className="about-us-page__title-bg">
                                <span dangerouslySetInnerHTML={{ __html: page_data.acf.title }} />
                            </div>
                        </div>
                        <WhoWeAre content={page_data.acf.who_we_are} />
                        <WhyWe content={page_data.acf.why_we} />
                        {/* <Gallery content={page_data.acf.gallery} /> */}
                        <TimeLine content={page_data.acf.timeline} canAnimate={canAnimate} />
                        <Projects content={page_data.acf.projects} />
                        <Founders content={page_data.acf.founders} />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="about-us-page about-us-page--preloader">
                    <Loader />
                </div>
            </>
        );
    }
};

export default AboutUs;
