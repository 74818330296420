import React, { Component } from "react";

class HeaderEventsOnScroll extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('load', this.handleLoad);
    }

    handleLoad = () => {

        if (window.innerWidth > 992) {

            let initialScrollPosition = window.scrollY || document.documentElement.scrollTop;

            if (initialScrollPosition > 0) {

                this.props.menuSize(true);

            } else if (initialScrollPosition <= 0) {

                this.props.menuSize(false);
            }
        }
    };

    handleScroll = () => {

        let pagePath = this.props.location.pathname;

        if (window.innerWidth > 992) {

            let scrollPosition = window.scrollY || document.documentElement.scrollTop;

            if (scrollPosition > 0) {

                this.props.menuSize(true);

            } else if (scrollPosition <= 0) {

                this.props.menuSize(false);
            }

            /*
            *   Changing the color of the logo and navigation when page is scrolling
            */

            let headerOptions = document.getElementsByClassName('page__nav')[0];

            if (pagePath === '/gdpr-cookie-consent') {

                let s1 = document.getElementsByClassName('cookie-opt-in__meta')[0];
                let s2 = document.getElementsByClassName('docs__left-parallax-bg')[0];
                let s3 = document.getElementsByClassName('docs__content-col--bottom-right')[0];
                let s4 = document.getElementsByClassName('included-services')[0];
                let s5 = document.getElementsByClassName('cookie-feedback__content')[0];
                let s6 = document.getElementsByClassName('contact-us')[0];

                if (s1 && s2 && s3 && s4 && s5 && s6) {

                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - (headerOptions.offsetTop);

                    let s4Options = s4.getBoundingClientRect();
                    let s4Position = s4Options.top - (headerOptions.offsetTop);

                    let s5Options = s5.getBoundingClientRect();
                    let s5Position = s5Options.top - (headerOptions.offsetTop);

                    let s6Options = s6.getBoundingClientRect();
                    let s6Position = s6Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s1Position < 0 && s1Position > (-s1Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s2Position < 0 && s2Position > (-s2Options.height)) {

                            this.props.changeHeaderEl(true, false);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s3Position < 0 && s3Position > (-s3Options.height)) {
                            this.props.changeHeaderEl(false, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position > 0 && s6Position > 0) {

                        if (s4Position < 0 && s4Position > (-s4Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0 && s6Position > 0) {

                        if (s5Position < 0 && s5Position > (-s5Options.height)) {

                            this.props.changeHeaderEl(true, false);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0 && s6Position < 0) {

                        if (s6Position < 0 && s6Position > (-s6Options.height)) {

                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position > 0 && s2Position > 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {
                        this.props.changeHeaderEl();
                    }
                }

            } else if (pagePath === '/it-support') {

                let s1 = document.getElementsByClassName('page__meta')[0];
                let s2 = document.getElementsByClassName('support-advantages__list')[0];
                let s3 = document.getElementsByClassName('support-numbers')[0];
                let s4 = document.getElementsByClassName('support-projects')[0];

                if (s1 && s2 && s3 && s4) {

                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - (headerOptions.offsetTop);

                    let s4Options = s4.getBoundingClientRect();
                    let s4Position = s4Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0 && s4Position > 0) {

                        if (s1Position < 0 && s1Position > (-s1Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0 && s4Position > 0) {

                        if (s2Position < 0 && s2Position > (-s2Options.height)) {
                            this.props.changeHeaderEl(false, false);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position > 0) {

                        if (s3Position < 0 && s3Position > (-s3Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0) {

                        if (s4Position < 0 && s4Position > (-s4Options.height)) {
                            this.props.changeHeaderEl(false, false);
                        }

                    } else if (s1Position > 0 && s2Position > 0 && s3Position > 0 && s4Position > 0) {
                        this.props.changeHeaderEl();
                    }
                }

            } else if (pagePath === '/projects') {

                let s1 = document.getElementsByClassName('page__meta')[0];
                let s2 = document.getElementsByClassName('referenzen-page__projects')[0];

                if (s1 && s2) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0) {

                        if (s1Position < 0 && s1Position > (-s1Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position < 0 && s2Position < 0) {

                        if (s2Position < 0 && s2Position > (-s2Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position > 0 && s2Position > 0) {
                        this.props.changeHeaderEl();
                    }
                }

            } else if (pagePath === '/web-development') {

                let s1 = document.getElementsByClassName('software-page__content')[0];
                let s2 = document.getElementsByClassName('page__meta')[0];

                if (s1 && s2) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0) {

                        if (s1Position < 0 && s1Position > (-s1Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position < 0 && s2Position < 0) {

                        if (s2Position < 0 && s2Position > (-s2Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position > 0 && s2Position > 0) {
                        this.props.changeHeaderEl();
                    }
                }

            } else if (pagePath === '/hosting') {

                let s1 = document.getElementsByClassName('hosting-page__content')[0];
                let s2 = document.getElementsByClassName('hosting-split-screen')[0];
                let s3 = document.getElementsByClassName('hosting-technologies')[0];

                if (s1 && s2 && s3) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0) {

                        if (s1Position < 0 && s1Position > (-s1Options.height)) {
                            this.props.changeHeaderEl();
                        } else {
                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0) {

                        if (s2Position < 0 && s2Position > (-s2Options.height)) {
                            this.props.changeHeaderEl(false, true);
                        } else {
                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0) {

                        if (s3Position < 0 && s3Position > (-s2Options.height)) {
                            this.props.changeHeaderEl();
                        } else {
                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position > 0 && s2Position > 0) {
                        this.props.changeHeaderEl(true, true);
                    }

                }

            } else if (pagePath === '/') {

                let s1 = document.getElementsByClassName('reviews')[0];

                if (s1) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s1Position > (-s1Options.height)) {
                        this.props.changeHeaderEl(true, true);
                    } else {
                        this.props.changeHeaderEl(false, false);
                    }
                }

            } else if (pagePath === '/blog') {

                let s1 = document.getElementsByClassName('articles')[0];

                if (s1) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s1Position > (-s1Options.height)) {

                        this.props.changeHeaderEl(true, true);

                    } else {
                        this.props.changeHeaderEl();
                    }
                }

            } else if (pagePath === '/online-shops') {

                let s1 = document.getElementsByClassName('e-commerce-page__content')[0];

                if (s1) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s1Position > (-s1Options.height)) {
                        this.props.changeHeaderEl(true, true);
                    } else {
                        this.props.changeHeaderEl(false, false);
                    }
                }

            } else if (pagePath === '/corporate-websites') {

                let s1 = document.getElementsByClassName('corporate-websites-page__content')[0];

                if (s1) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s1Position > (-s1Options.height)) {
                        this.props.changeHeaderEl(true, true);
                    } else {
                        this.props.changeHeaderEl(false, false);
                    }
                }

            } else if (pagePath === '/cookie-banner-integration') {

                let s1 = document.getElementsByClassName('usercentrics-page__content')[0];
                let s2 = document.getElementsByClassName('collaboration')[0];
                let s3 = document.getElementsByClassName('faqBlock')[0];

                if (s1 && s2 && s3) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0) {

                        if (s1Position < 0 && s1Position > (-s1Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0) {

                        if (s2Position < 0 && s2Position > (-s2Options.height)) {
                            this.props.changeHeaderEl(false, true);
                        } else {
                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0) {

                        if (s3Position < 0 && s3Position > (-s2Options.height)) {
                            this.props.changeHeaderEl(false, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position > 0 && s2Position > 0) {
                        this.props.changeHeaderEl(true, false);
                    }

                }

            } else if (pagePath === '/projects/bw-online-shop') {
                let s1 = document.getElementsByClassName('success-story')[0];
                let s2 = document.getElementsByClassName('description-success')[0];
                let s3 = document.getElementsByClassName('success__services')[0];
                let s4 = document.getElementsByClassName('screen-page')[0];
                let s5 = document.getElementsByClassName('timeline')[0];
                let s6 = document.getElementsByClassName('project-implementation')[0];

                if (s1 && s2 && s3 && s4 && s5 && s6) {

                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - (headerOptions.offsetTop);

                    let s4Options = s4.getBoundingClientRect();
                    let s4Position = s4Options.top - (headerOptions.offsetTop);

                    let s5Options = s5.getBoundingClientRect();
                    let s5Position = s5Options.top - (headerOptions.offsetTop);

                    let s6Options = s6.getBoundingClientRect();
                    let s6Position = s6Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s1Position < 0 && s1Position > (-s1Options.height)) {
                            this.props.changeHeaderEl(true, false);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s2Position < 0 && s2Position > (-s2Options.height)) {

                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s3Position < 0 && s3Position > (-s3Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position > 0 && s6Position > 0) {

                        if (s4Position < 0 && s4Position > (-s4Options.height)) {
                            this.props.changeHeaderEl(true, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0 && s6Position > 0) {

                        if (s5Position < 0 && s5Position > (-s5Options.height)) {

                            this.props.changeHeaderEl(false, false);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0 && s6Position < 0) {

                        if (s6Position < 0 && s6Position > (-s6Options.height)) {

                            this.props.changeHeaderEl(true, true);
                        }
                    }
                }

            } else if (
                pagePath === "/projects/office-people" ||
                pagePath === "/projects/volve" ||
                pagePath === "/projects/olymp" ||
                pagePath === "/projects/kostueme" ||
                pagePath === "/projects/fameonme" ||
                pagePath === "/projects/atrava"
              ) {
                const banner = document.querySelector('.success-story');
                if (banner) {
                    const bannerOptions = banner.getBoundingClientRect();
                    if (bannerOptions.top + bannerOptions.height - headerOptions.offsetTop > 0) {
                        this.props.changeHeaderEl(true, false);
                    } else {
                        this.props.changeHeaderEl(true, true);
                    }
                }

            } else if (pagePath === '/web-design') {

                let s1 = document.getElementsByClassName('perfectes__wrapper')[0];
                let s2 = document.getElementsByClassName('design')[0];
                let s3 = document.getElementsByClassName('services')[0];
                let s4 = document.getElementsByClassName('mobile-first__wrapper')[0];
                let s5 = document.getElementsByClassName('design-process__slider')[0];
                let s6 = document.getElementsByClassName('faqBlock')[0];

                if (s1 && s2 && s3 && s4 && s5 && s6) {

                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - (headerOptions.offsetTop);

                    let s4Options = s4.getBoundingClientRect();
                    let s4Position = s4Options.top - (headerOptions.offsetTop);

                    let s5Options = s5.getBoundingClientRect();
                    let s5Position = s5Options.top - (headerOptions.offsetTop);

                    let s6Options = s6.getBoundingClientRect();
                    let s6Position = s6Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s1Position < 0 && s1Position > (-s1Options.height)) {
                            this.props.changeHeaderEl(false, true);
                        } else {
                            this.props.changeHeaderEl();
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s2Position < 0 && s2Position > (-s2Options.height)) {

                            this.props.changeHeaderEl(false, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {

                        if (s3Position < 0 && s3Position > (-s3Options.height)) {
                            this.props.changeHeaderEl(false, false);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position > 0 && s6Position > 0) {

                        if (s4Position < 0 && s4Position > (-s4Options.height)) {
                            this.props.changeHeaderEl(false, true);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0 && s6Position > 0) {

                        if (s5Position < 0 && s5Position > (-s5Options.height)) {

                            this.props.changeHeaderEl(false, false);
                        }

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0 && s4Position < 0 && s5Position < 0 && s6Position < 0) {

                        if (s6Position < 0 && s6Position > (-s6Options.height)) {

                            this.props.changeHeaderEl(false, true);
                        }

                    } else if (s1Position > 0 && s2Position > 0 && s3Position > 0 && s4Position > 0 && s5Position > 0 && s6Position > 0) {
                        this.props.changeHeaderEl();
                    }
                }
            } else if (pagePath === '/about-us') {

                let s1 = document.getElementsByClassName('about-us-page__title')[0];
                let s2 = document.getElementsByClassName('timeline')[0];
                let s3 = document.getElementsByClassName('support-projects')[0];

                if (s1 && s2 && s3) {
                    let s1Options = s1.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);

                    let s2Options = s2.getBoundingClientRect();
                    let s2Position = s2Options.top - (headerOptions.offsetTop);

                    let s3Options = s3.getBoundingClientRect();
                    let s3Position = s3Options.top - (headerOptions.offsetTop);

                    if (s1Position < 0 && s2Position > 0 && s3Position > 0) {

                        this.props.changeHeaderEl(false, false);

                    } else if (s1Position < 0 && s2Position < 0 && s3Position > 0) {

                        this.props.changeHeaderEl(true, true);

                    } else if (s1Position < 0 && s2Position < 0 && s3Position < 0) {

                        this.props.changeHeaderEl(false, false);

                    } else if (s1Position > 0) {
                        this.props.changeHeaderEl(true, true);
                    }
                }

            } else {
                let postPart = document.getElementById('post__content');
                if (postPart) {
                    let s1Options = postPart.getBoundingClientRect();
                    let s1Position = s1Options.top - (headerOptions.offsetTop);
                    if (s1Position < 0 && s1Position > (-s1Options.height)) {

                        this.props.changeHeaderEl(true, true);

                    } else {
                        this.props.changeHeaderEl();
                    }
                }
            }
        }
    };

    render() {

        return (
            <div onScroll={this.handleScroll}></div>
        );
    }
}

export default HeaderEventsOnScroll;
