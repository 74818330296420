import React from 'react';

import useMobile from "../../../../hooks/useMobile";

const Launch = (props) => {
    const content = props.content;
    const isMobile = useMobile();

    return (
        <section className='launch anim-item'>
            <div className='number launch__number'>3</div>
            <div className='launch__textHolder'>
                <div className='e-commerce-page__title launch__title'>
                    <h2 className='e-commerce-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                    <div className='e-commerce-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
                </div>
                <div className="e-commerce-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
            </div>
            {
                isMobile ?
                    <div className="launch__image e-commerce-page__image">
                        <img src={content.mobile_image.url} alt={content.mobile_image.alt} />
                    </div>
                :
                    <div className="launch__image e-commerce-page__image fadeInRight">
                        <div className="can-scroll">
                            <div className="can-scroll__text" dangerouslySetInnerHTML={{ __html: content.scroll_button_text }} />
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.80326 1C-3.97124 10 -0.52874 35.9654 18.6509 47.3402C25.2804 51.118 40.3635 39.4029 35.6184 33.8483C33.325 31.2053 28.6805 32.0328 25.8123 34.0061C8.91976 46.3826 16.8096 61.6126 19.8534 64.906C35.5482 82.1785 62.4039 58.109 71 49.6901" stroke="#3E3E53" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="4 4"/>
                            </svg>
                        </div>
                        <img className="frame" src={content.desktop_image.frame.url} alt={content.desktop_image.frame.alt} />
                        <div className="scrollImage">
                            <img src={content.desktop_image.screen.url} alt={content.desktop_image.screen.alt} />
                        </div>
                    </div>
            }
        </section>
    )
};

export default Launch;
