import React from 'react';

const MachineOptimization = (props) => {
    const content = props.content;

    return (
        <section className='machine-optimization anim-item'>
            <div className='number machine-optimization__number'>3</div>
            <div className='machine-optimization__textHolder'>
                <div className='corporate-websites-page__title machine-optimization__title'>
                    <h2 className='corporate-websites-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                    <div className='corporate-websites-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
                </div>
                <div className="corporate-websites-page__text1" dangerouslySetInnerHTML={{ __html: content.text_1 }} />
                <img className="machine-optimization__icon" src={content.icon.url} alt={content.icon.alt} />
                <div className="corporate-websites-page__text2" dangerouslySetInnerHTML={{ __html: content.text_2 }} />
            </div>
            <div className="machine-optimization__image corporate-websites-page__image">
                <img className="google-image fadeInRight" src={content.google_image.url} alt={content.google_image.alt} />
            </div>
        </section>
    )
};

export default MachineOptimization;
