import React, { useState, useEffect } from 'react'

import './AuthorPost.scss'

const AuthorPost = (props) => {
    const {author} = props
    const {acf, description, name} = author;

    return (
        <div className="post__author">
            <div className="post__author__wrapper">
                <div className="post__author-img">
                    {acf.avatar.url && <img src={acf.avatar.url} alt={name} />}
                </div>
                <div className="post__author-info">
                    <span className="author-name">{name}</span>
                    <span className="author-work">{acf.author_inform.work_place}</span>
                    <span className="author-description">{description}</span>
                </div>
            </div>
        </div>
    )
}

export default AuthorPost
