import React from 'react';

import "./Button.scss";

const Button = (props) => {
    const isSending = props.isLoading || props.isFormSuccess
    const isPrimary = props.buttonStyle === 'primary'
    const isFlat = props.buttonStyle === 'flat'
    let buttonClass = 'button';

    if(isPrimary) buttonClass = buttonClass + ' button--primary'
    if(isFlat) buttonClass = buttonClass + ' button--flat'

    if(props.buttonStyle === 'submit') {
        return (
            <div className="button-submit">
                <button
                    onClick={props.clickHandler}
                    className={(props.isLoading) ? "loading" : ''}
                    type={props.type}
                >
                    <div className={`button-submit__label${(isSending) ? " button-submit__label--hidden" : ''}`}>
                        <div className="button-submit__label-text">{props.text}</div>
                    </div>
                    <div className={`button-submit__loader${(props.isLoading) ? " button-submit__loader--visible" : ''}`}>
                        <div className="button-submit__ring"></div>
                    </div>
                </button>
                <div className={`button-submit__icon${(isSending) ? " button-submit__icon--sending" : ''}`}>
                    <i className="icon-send"></i>
                </div>
            </div>
        )
    }

    return (
        <button
            onClick={props.clickHandler}
            className={buttonClass}
            type={props.type}
        >
            {props.iconName && !props.iconAfter && <i className={`button__icon ${props.iconName}`}></i>}
            <span className="button__label">{props.text}</span>
            {props.iconName && props.iconAfter && <i className={`button__icon ${props.iconName}`}></i>}
        </button>
    )
};

export default Button;