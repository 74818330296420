import React, { useState, useEffect } from 'react';
import Particles from 'react-particles-js';
import Plx from 'react-plx';

const CookieRequired = (props) => {
    const { title, text, button, image, gear } = props.content;
    const [parallaxStatus, setParallaxStatus] = useState(true);

    useEffect(() => {
        let w = window.innerWidth;

        if (w > 992) {
            setParallaxStatus(false);
        }
      }, []);

    const dataParallax = [
        {
            start: 'self',
            duration: 1000,
            properties: [
                {
                    startValue: 22,
                    endValue: 0,
                    unit: '%',
                    property: 'translateY',
                },
            ],
        },
    ];

    const particles = {
        particles: {
            number: { value: 20, },
            color: { value: '#3E3E53' },
            opacity: {
                value: 0.5,
                anim: { enable: true }
            },
            size: {
                value: 12,
                random: true,
                anim: {
                    enable: true,
                    speed: 3
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                speed: 1
            }
        }
    }

    return (
        <section className='usercentrics-required'>
            <div className='number number--left'>1</div>
            <div className='usercentrics-page__title'>
                <h2 className='usercentrics-page__title-text' dangerouslySetInnerHTML={{ __html: title.text }} />
                <div className='usercentrics-page__title-bg' dangerouslySetInnerHTML={{ __html: title.background_text }} />
            </div>
            <div className="usercentrics-page__text" dangerouslySetInnerHTML={{ __html: text }} />
            <a href={button.url} className="usercentrics-page__button">
                {button.title}
            </a>
            <div className="usercentrics-required__image usercentrics-page__image">
                <Plx className="usercentrics-required-parallax" parallaxData={ dataParallax } disabled={parallaxStatus}>
                    <img src={image.url} alt={image.alt}/>
                </Plx>
                <div className="usercentrics-required__gear">
                    <img className="usercentrics-required__gear-img" src={gear.image.url} alt={gear.image.alt} />
                    <img src={gear.logo.url} className="usercentrics-required__gear-logo" alt={gear.logo.alt} />
                    <Particles params={particles} style={{ width: '100%' }} />
                </div>
            </div>
        </section>
    )
};

export default CookieRequired;
