import React from 'react'

import './ServicesTitle.scss'

const ServicesTitle = ({ title }) => {
    return (
        <h3 className="item__services-title">
            <img src={title.icon.url} alt={title.text} />
            <span>{title.text}</span>
        </h3>
    )
}

export default ServicesTitle;
