import React, { useEffect, useRef, useState } from 'react';
import useMobile from "../../../../hooks/useMobile";
import useScrollAnimation from "../../../../hooks/useScrollAnimation";
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import convertPxToRem from "../../../../helpers/convertPxToRem";

const What = (props) => {
    const { title, text, image, advantages } = props.content;
    const isMobile = useMobile()
    const startAnimationRef = useRef(null)
    const scrollTop = useScrollAnimation(startAnimationRef.current)

    return (
        <section 
            className='what'
            style={{ paddingTop: isMobile ? 60 : (convertPxToRem(930) - scrollTop) / 2 }}
        >
            <ParallaxProvider>
                <div 
                    ref={startAnimationRef}
                    className="what__triangle" 
                    style={{ borderTopWidth: (convertPxToRem(927) - scrollTop) / 2 }}
                />
                <div className="what__content">
                    <div className='number number--right'>5</div>
                    <div className='what__text-container'>
                        <div className='usercentrics-page__title'>
                            <h2 className='usercentrics-page__title-text' dangerouslySetInnerHTML={{ __html: title.text }} />
                            <div className='usercentrics-page__title-bg' dangerouslySetInnerHTML={{ __html: title.background }} />
                        </div>
                        <div className='usercentrics-page__text' dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                    <div className='what__image-container'>
                        <Parallax
                            translateY={!isMobile ? [10, -15] : [0, 0]}
                            className='advantages'
                        >
                            {advantages.map((item) => {
                                return (
                                    <div className='advantages__item' key={item.first_part}>
                                        <p dangerouslySetInnerHTML={{ __html: item.first_part }} />
                                        <span>{item.number}</span>
                                        <p dangerouslySetInnerHTML={{ __html: item.second_part }} />
                                    </div>
                                )
                            })}
                        </Parallax>
                        <img src={image.url} alt={image.alt} />
                    </div>
                </div>
            </ParallaxProvider>
        </section>
    )
};

export default What;
