import React from "react"
import { Link } from "react-router-dom";

import Particles from 'react-particles-js';

const Projects = (props) => {

    const title = props.content.title;
    const titleBg = props.content.title_bg;
    const projects = props.content.projects;
    const allProjectsLink = props.content.link_to_all_projects;
    const poster = props.content.poster;

    const particles = {
        particles: {
            number: { value: 20, },
            color: { value: '#FFF' },
            opacity: {
                value: 0.5,
                anim: { enable: true }
            },
            size: {
                value: 15,
                random: true,
                anim: {
                    enable: true,
                    speed: 10
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                speed: 1
            }
        }
    }

    return (
        <div className="support-projects">
            <div className="support-projects__list">
                <div className="support-projects__list-inner">
                    <div className="support-projects__list-item support-projects__title">
                        <div className="support-projects__title-bg" dangerouslySetInnerHTML={{ __html: titleBg }} />
                        <h2 className="support-projects__title-text">
                            {title}
                        </h2>
                    </div>
                    {
                        projects.map((item, key) => {
                            return (
                                <div className="support-projects__list-item support-project" key={key}>
                                    {item.link ?
                                        <a href={item.link}>
                                            <img src={item.logotype.url} alt={item.logotype.alt} />
                                        </a>
                                        :
                                        <a>
                                            <img src={item.logotype.url} alt={item.logotype.alt} />
                                        </a>
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="support-projects__list-item support-projects__to-all">
                        <Link to={allProjectsLink.url} className="base-btn">
                            {allProjectsLink.title}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="support-projects__poster">
                <Particles params={particles} style={{ width: '100%' }} />
                <Particles params={particles} style={{ width: '100%' }} />
                <img src={poster.url} alt={poster.alt} />
            </div>
        </div>
    );
};

export default Projects;
