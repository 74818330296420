import React from 'react';

import './Articles.scss';

import PostList from '../Posts/PostList';

const Articles = (props) => {

    const title = (Array.isArray(props.activeCategory) && props.activeCategory.length > 0) ? props.activeCategory[0].name : props.title;

    if (props.loadedStatus) {
        return (
            <div className="articles" id="scrollPosition">
                <div className="articles__title__wrapper">
                    <h2 className="articles__title">{title}</h2>
                    <span className="title-back">{title}</span>
                </div>
                <PostList post={props.posts}
                    postId={props.posts.featured_media} />
            </div>
        )
    } else {
        return <div className="loading-posts"></div>
    }
}

export default Articles;
