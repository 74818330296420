import React, { useState, useEffect, useRef } from 'react';

import MainBanner from '../../MainBanner/MainBanner';

import Konzeption from './Konzeption/Konzeption';
import Design from './Design/Design';
import Launch from './Launch/Launch';
import Systems from './Systems/Systems';
import HeadlessCommerce from './HeadlessCommerce/HeadlessCommerce';
import Tracking from './Tracking/Tracking';
import Hosting from './Hosting/Hosting';
import Newsletter from './Newsletter/Newsletter';
import Datenschutz from './Datenschutz/Datenschutz';

import axios from 'axios';
import API from '../../../api';
import Loader from "../../../components/Loader/Loader";
import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";
import Support from "../../Support/Support";

import './ECommerce.scss';

const ECommerce = (props) => {
    const [page_data, setPage_data] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);
    const ref = useRef(null);

    function animOnScroll() {
        const animItems = ref.current.querySelectorAll('.anim-item');

        for (let i = 0; i < animItems.length; i++) {
            const animItem = animItems[i];
            const animItemHeight = animItem.offsetHeight;
            const animItemOffset = offset(animItem).top;
            const animStart = 2;
            let animItemPoint = window.innerHeight - animItemHeight / animStart;

            if ((window.pageYOffset > animItemOffset - animItemPoint) && window.pageYOffset < (animItemOffset + animItemHeight)) {
                animItem.classList.add('active')
            }
        }
    }

    function offset(el) {
        const rect = el.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    useEffect(() => {
        axios
            .get(API.basePath + '/wp-json/wp/v2/pages?slug=online-shops')
            .then((response) => {
                setPage_data(response.data[0].acf);
                setYoast_meta(response.data[0]);
                setLoaded_status(true);
            })
            .catch((error) => {
                console.log(error);
            });

        props.setContactElements();

        if (loaded_status) {
            window.addEventListener('scroll', animOnScroll);
        }
        return () => {
            window.removeEventListener('scroll', animOnScroll);
        };
    }, [loaded_status]);

    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    const mouseMove = (e) => {
        let w, h;
        w = window.innerWidth;
        h = window.innerHeight;

        if (w > 992) {
            let x = e.clientX;
            let y = e.clientY;

            setPositionX(x - w / 2)
            setPositionY(y - h / 2)
        }
    }

    const parallax = (directionX = 1, directionY = 1) => {
        return `translate(
            ${(positionX / 25) * directionX}px, 
            ${(positionY / 25) * directionY}px)`;
    }

    if (loaded_status) {
        return (
            <>
                <PageMeta metaData={yoast_meta || null} hreflangs={page_data['hreflang_list'] || null} />
                <CustomSchema schema={page_data.schema} />
                <div className='e-commerce-page'>
                    <MainBanner content={page_data.main_banner_content} id='eCommerce' />
                    <div className='e-commerce-page__content' id="scrollPosition" ref={ref} onMouseMove={(e) => mouseMove(e)}>
                        <div className="e-commerce-page__titling">
                            <h1 className="e-commerce-page__titling-text"
                                dangerouslySetInnerHTML={{__html: page_data.title.page_title}}/>
                            <div className="e-commerce-page__titling-bg">
                                <span dangerouslySetInnerHTML={{__html: page_data.title.page_title}}/>
                            </div>
                            {page_data.title.page_description && (
                                <div className="page__meta-description"
                                     dangerouslySetInnerHTML={{__html: page_data.title.page_description}}/>
                            )}
                        </div>
                        <Konzeption content={page_data.konzeption || null} parallax={parallax}/>
                        <Design content={page_data.design || null} />
                        <Launch content={page_data.launch || null} />
                        <Systems content={page_data.systems || null} />
                        <HeadlessCommerce content={page_data.headless_commerce || null} />
                        <Tracking content={page_data.tracking || null} />
                        <Hosting content={page_data.hosting || null} />
                        <Newsletter content={page_data.newsletter || null} parallax={parallax} />
                        <Datenschutz content={page_data.datenschutz || null} parallax={parallax} />
                        <Support data={page_data} changeBackground={true}  />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="e-commerce-page e-commerce-page--preloader">
                    <Loader />
                </div>
            </>
        );
    }
};

export default ECommerce;
