import React from "react";
import useMobile from "../../../../hooks/useMobile";

import TextSlider from "../../../MainBanner/TextSlider/TextSlider";
import './Banner.scss'

const Banner = (props) => {
    const isMobile = useMobile();
    const partners = [
        'klarna',
        'atrava',
        'liveChat',
        'usercentrics',
        'epoq',
        'unzer',
        'trustedShops',
        'findologic',
        'mollie',
        'jentis',
        'nexi',
        'adyen'
    ]

    return (
        <>
            <div className="start-banner partner-banner">
            <div className={'start-banner__slider loading-block loading-block--3'}>
                <TextSlider slides={props.content.slide}/>
            </div>
                <div className="partner-banner__images">
                    {isMobile && (
                        <img
                            className="partner-banner__image"
                            src='/images/partner/banner/partner-banner-mobile-image.png'
                            alt="partner logos"
                        />
                    )}
                    {!isMobile && (
                        <div className="partner-banner__image">
                            {partners.map((item) => {
                                return(
                                    <div className={`partner-gear partner-gear--${item}`}>
                                        <img
                                            className="logo"
                                            src={`/images/partner/banner/${item}/logo.svg`}
                                            alt={item}
                                        />
                                        <img
                                            className="partner-gear__circle circle1"
                                            src={`/images/partner/banner/${item}/circle1.svg`}
                                            alt="circle1"
                                        />
                                        <img
                                            className="partner-gear__circle circle2"
                                            src={`/images/partner/banner/${item}/circle2.svg`}
                                            alt="circle2"
                                        />
                                        <img
                                            className="partner-gear__circle circle3"
                                            src={`/images/partner/banner/${item}/circle3.svg`}
                                            alt="circle3"
                                        />
                                        <img
                                            className="partner-gear__circle circle4"
                                            src={`/images/partner/banner/${item}/circle4.svg`}
                                            alt="circle4"
                                        />
                                        <img
                                            className="partner-gear__circle circle5"
                                            src={`/images/partner/banner/${item}/circle5.svg`}
                                            alt="circle5"
                                        />
                                        <img
                                            className="partner-gear__circle circle6"
                                            src={`/images/partner/banner/${item}/circle6.svg`}
                                            alt="circle6"
                                        />
                                        <img
                                            className="partner-gear__circle circle7"
                                            src={`/images/partner/banner/${item}/circle7.svg`}
                                            alt="circle7"
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )}
            </div>
        </div>
        </>
    )
}

export default Banner
