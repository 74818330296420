import React, {Component} from "react";
import './Advantages.scss';

class Advantages extends Component {

    render() {

        if (this.props.content) {

            const title = this.props.content.title;
            const description = this.props.content.description;
            const advantages = this.props.content.advantages;

            return (
                <>
                    <div className="support-advantages" id="scrollPosition">
                        <div className="page__meta">
                            <div className="page__meta-bg page__meta-bg--top"></div>
                            <div className="page__meta-bg page__meta-bg--bottom"></div>
                            <div className="page__meta-content">
                                <div className="page__meta-title page__title">
                                    <h1 className="page__title-text" dangerouslySetInnerHTML={{ __html: title}} />
                                    <div className="page__title-bg">
                                        <div dangerouslySetInnerHTML={{ __html: title}} />
                                    </div>
                                </div>
                                <div className="page__meta-description" dangerouslySetInnerHTML={{ __html: description}} />
                            </div>
                        </div>
                        <div className="support-advantages__list">
                            {
                                advantages.map((item, key) => {
                                    return (
                                        <div  className="support-advantage" key={key}>
                                            <div className="support-advantage__head">
                                                <div className="support-advantage__icon">
                                                    <img src={item.advantage_icon.url} alt={item.advantage_icon.alt}/>
                                                </div>
                                                <div className="support-advantage__title">
                                                    <h2 className="support-advantage__title-text">
                                                        {item.advantage_title}
                                                    </h2>
                                                </div>
                                                <div className="support-advantage__index">
                                                    <span>{key+1}</span>
                                                </div>
                                            </div>
                                            <div className="support-advantage__description">
                                                <div>{item.advantage_description}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="support-advantages" id="scrollPosition"></div>
            );
        }
    }
}

export default Advantages;