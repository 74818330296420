import React, { Component } from "react";

import MainBanner from '../../MainBanner/MainBanner';
import Solutions from './Solutions/Solutions';
import Services from "./Services/Services";
import Reviews from "./Reviews/Reviews";

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import axios from "axios";
import API from "../../../api";

class StartPage extends Component {

    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false
    };

    componentDidMount() {
        // Get company menu items
        axios.get(API.basePath + '/wp-json/wp/v2/pages/7')
            .then(response => {
                this.setState({
                    page_data: response.data.acf,
                    yoast_meta: response.data,
                    loaded_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
        this.props.setContactElements();
    }

    render() {
        if (this.state.loaded_status) {
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                    <CustomSchema schema={this.state.page_data.schema} />
                    <div className="start-page">
                        <MainBanner content={this.state.page_data.main_banner_content || null} logotypes={this.state.page_data.partners_logotypes || null} id={'home'} />
                        <Solutions content={this.state.page_data.solutions_section_content || null} />
                        <Services content={this.state.page_data.services_section_content || null} />
                        <Reviews content={this.state.page_data.reviews_section_content || null} />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} />
                    <div>
                        <MainBanner content={this.state.page_data.main_banner_content || null} />
                    </div>
                </>
            );
        }
    }
}

export default StartPage;