import React, { Component } from "react";

import "./Services.scss";

export default class Services extends Component {

  render() {
    const { title, service } = this.props.content;

    return (
      <section className="services">
        <div className="services__wrapper">
          <div className="services__items">
            <div className="services__title_bg">{title}</div>
            <h2 className="services__title-design">{title}</h2>
          </div>
          {service.map((item, key) => {
            return (
              <div className="services__items" key={key}>
                <div
                  className="services__items_bg"
                  style={{ backgroundImage: `url(${item.icon.url})` }}
                ></div>
                <div href={item.link}>
                  <div className="services__items_icon">
                    <img src={item.icon.url} alt="service" />
                  </div>
                  <div className="services__items_title">{item.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}
