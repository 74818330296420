import React from 'react'

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

import "./SocialLink.scss"


const SocialLink = (props) => {
    const {
        link
    } = props;

    const url = window.location.href
    let shareButton = (<></>);

    if (link.name === 'linkedin') {
        shareButton = (<LinkedinShareButton title={link.name} url={url} className="social-icon" >
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </LinkedinShareButton>);
    } else if (link.name === 'xing2') {
        shareButton = (<a href={link.link.url + '/spi/shares/new?url=' + url} target="_blank" className="social-icon">
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </a>)
    } else if (link.name === 'facebook') {
        shareButton = (<FacebookShareButton title={link.name} url={url} className="social-icon">
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </FacebookShareButton>);
    } else if (link.name === 'twitter') {
        shareButton = (<TwitterShareButton title={link.name} url={url} className="social-icon">
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </TwitterShareButton>);
    } else if (link.name === 'instagram') {
        shareButton = (<a href={link.link.url + '/share?url=' + url} target="_blank" className="social-icon">
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </a>)
    }

    return shareButton;
}

export default SocialLink;