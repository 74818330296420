import React, { Component } from "react";

import MainBanner from '../../MainBanner/MainBanner';
import ProjectsList from './ProjectsList/ProjectsList';
import Loader from "../../../components/Loader/Loader";

import "./Referenzen.scss";

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import axios from "axios";
import API from "../../../api";

import { scroller } from 'react-scroll'

class Referenzen extends Component {

    state = {
        page_data: [],
        yoast_meta: [],
        projects: [],
        activeIds: [],
        loaded_status: false,
        activeFilter: 'all',
        projectsLoadingStatus: false
    };

    componentDidMount() {
        // Get company menu items
        axios.get(API.basePath + '/wp-json/wp/v2/pages/45')
            .then(response => {
                this.setState({
                    page_data: response.data.acf,
                    yoast_meta: response.data,
                    projectsIDs: response.data.acf.posts_order,
                    loaded_status: true
                });

                this.loadProjectsData();
            })
            .catch(error => {
                console.log(error);
            });
        this.props.setContactElements();
    }

    loadProjectsData() {
        let IDs = this.state.page_data.posts_order;

        if (IDs.length > 0) {
            let queryParams = '';

            for (let i = 0; i < IDs.length; i++) {
                if (i === 0) {
                    queryParams += '?include[]=' + IDs[i];
                } else {
                    queryParams += '&include[]=' + IDs[i];
                }
            }

            axios.get(API.basePath + '/wp-json/acf/v3/projects' + queryParams + "&per_page=" + IDs.length)
                .then(response => {

                    this.setState({
                        projects: response.data,
                        activeIds: IDs,
                        projectsLoadingStatus: true,
                        activeFilter: 'all',
                    });

                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    filterHandler(filterId) {
        scroller.scrollTo('projects-filter', {
            duration: 800,
            delay: 0,
            smooth: 'easeOutCubic'
        });

        this.setState({
            activeFilter: filterId,
        });
    };

    render() {
        if (this.state.loaded_status) {
            const meta_data = this.state.page_data.referenzen_page_content;
            const filter_data = this.state.page_data.referenzen_page_content.page_filter;

            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                    <CustomSchema schema={this.state.page_data.schema} />
                    <div className="referenzen-page">
                        <MainBanner content={this.state.page_data.main_banner_content || null} id='referenzen' />

                        <div className="page__meta" id="scrollPosition">
                            <div className="page__meta-bg page__meta-bg--top"></div>
                            <div className="page__meta-bg page__meta-bg--bottom"></div>
                            <div className="page__meta-content">
                                <div className="page__meta-title page__title">
                                    <h1 className="page__title-text" dangerouslySetInnerHTML={{ __html: meta_data.page_title }} />
                                    <div className="page__title-bg">
                                        <div dangerouslySetInnerHTML={{ __html: meta_data.page_title }} />
                                    </div>
                                </div>
                                <div className="page__meta-description" dangerouslySetInnerHTML={{ __html: meta_data.page_description }} />
                            </div>
                        </div>

                        <div className="projects-filter">
                            <div className="projects-filter__title">
                                <div className="projects-filter__title-bg" dangerouslySetInnerHTML={{ __html: filter_data.filter_title_bg }} />
                                <h2 className="projects-filter__title-link" onClick={() => this.filterHandler('all')}>
                                    {filter_data.filter_title}
                                </h2>
                            </div>

                            {
                                filter_data.filter_items.map((item, key) => {
                                    return (
                                        <div className="projects-filter__item" key={key}>
                                            <div className="projects-filter__item-link">
                                                <a onClick={() => this.filterHandler(item.filter_id)} className={this.state.activeFilter === item.filter_id && "active-filter"}>
                                                    <div className="projects-filter__item-bg" dangerouslySetInnerHTML={{ __html: item.icon }} />
                                                    <div className="projects-filter__item-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
                                                    <h2 className="projects-filter__item-title">
                                                        {item.title}
                                                        <div className='projects-filter__item-arrow'>
                                                            <i className="icon-angle-down"></i>
                                                        </div>
                                                    </h2>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="referenzen-page__projects">
                            {
                                this.state.projectsLoadingStatus ? (
                                    <ProjectsList button={meta_data.button_text} category={this.state.activeFilter} endBlock={meta_data.block_end_of_list} IDs={this.state.activeIds} projects={this.state.projects} />
                                ) : ('')
                            }
                        </div>

                    </div>
                </>
            );

        } else {
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} />
                    <div className="referenzen-page referenzen-page--preloader">
                        <Loader />
                    </div>
                </>
            );
        }
    }
}

export default Referenzen;
