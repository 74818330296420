import React from 'react';

import { Link } from 'react-router-dom';


const CategoriesList = (props) => {
  const { categories, scrollOnChangeCategory } = props

  return (
    <div className="categories__items">
      {categories.map((category) => {
        if (category.id !== 1) {
          return (
            <div
              className="categories__item"
              key={category.id}
            >
              <Link to={`/blog/${category.slug}`}>
                <span className="categories__item--title"
                  onClick={() => scrollOnChangeCategory()}>{category.name}</span>
              </Link>
            </div>
          );
        }
      })}
    </div>
  )
}

export default CategoriesList
