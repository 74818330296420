import React from 'react'

import './SecondLevelTitle.scss'

const SecondLevelTitle = ({ title }) => {
    return (
        <div className="second-level-title">
             <h2 className="second-level-title_text" dangerouslySetInnerHTML={{ __html: title.text }} />
             <div className="second-level-title_bg" dangerouslySetInnerHTML={{ __html: title.background }} />
        </div>
    )
}

export default SecondLevelTitle
