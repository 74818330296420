import React, { useState, useEffect, useRef, useCallback } from "react";

import useMobile from "../../../hooks/useMobile";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function MockupDevices(props) {

    const [isScrolling, setIsScrolling] = useState(false);

    const [mockupPage, setmockupPage] = useState(false);

    const isMobile = useMobile();

    const { alt, mockupImg, mockupImgSizes, contentScreen, contentScreenSizes, device, noScroll, mockupDevicesRef, imageAreReady } = props;


    const gadgetWrapper = useRef(null);

    const screenWrapper = useRef(null);

    const mockupWrapper = useRef(null);

    const mockupContent = useRef(null);

    const mobile = useRef(null);

    const autoScroll = function (delay) {
        const frame = mockupWrapper.current;
        const content = mockupContent.current;
        const timeoutId = setTimeout(function () {
            const scrollInterval = setInterval(function () {
                frame.scrollTop += 20;
        
                if (frame.scrollTop >= content.offsetHeight - frame.offsetHeight) {
                  clearInterval(scrollInterval);
               
                  setTimeout(() => {
        
                    const scrollToTop = setInterval(function () {
                      frame.scrollTop -= 20;
                      if (frame.scrollTop === 0) {
                        clearInterval(scrollToTop);
                      }
              
                    }, 10);
        
                  }, 1000)
        
                }
        
              }, 10);
        }, delay);
    };

    const scrollPosition = useCallback(
        () => {
            const distanceToTop = mobile.current.getBoundingClientRect().top;
            if (distanceToTop < 300) {
                setIsScrolling(true)
            }
        },
        [],
    )

    useEffect(() => {
        if (!noScroll) {
            window.addEventListener("scroll", scrollPosition)
        }

        if (!noScroll) {
            if (isScrolling && !isMobile) {
                autoScroll(500);
            }
        }

        return () => {
            if (!noScroll) {
                window.removeEventListener("scroll", scrollPosition);
            }
        };
    }, [isMobile, isScrolling, noScroll, scrollPosition, mockupImg, mockupDevicesRef]);

    return (
        <div className={`mobile-first__img ${device ? device : ''}`} ref={noScroll ? null : mobile}>
            <div ref={gadgetWrapper}>
                <div className="mobile-screen">
                    <div className="mobile-screen__moc-up">
                        <img
                            src={mockupImg}
                            alt={alt}
                            ref={screenWrapper}
                            width={mockupImgSizes.width}
                            height={mockupImgSizes.height}
                        />
                    </div>
                    <div
                        ref={mockupWrapper}
                        className={`mobile-screen__frame ${imageAreReady ? 'mobile-screen__frame--ready' : ""}`}
                        name="firstInsideContainer"
                        style={{maxHeight: mockupPage + 'px'}}
                    >
                        <div className="mobile-screen__img-wrap" ref={mockupContent}>
                            <LazyLoadImage
                                src={contentScreen}
                                width={contentScreenSizes.width}
                                height={contentScreenSizes.height}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}