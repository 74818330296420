import React from 'react';

import './Gear.scss'

const Gear = (props) => {

    const { gears, } = props

    return (
        <>
            {gears && gears.map((img, key) => {
                return (
                    <div className={`content__img__wrapper content__img__wrapper--i${key+1}`} key={img.gear.id}>
                        <img className={`content__img ${img.class}`} src={img.gear.url} alt={img.class} />
                        <img src={img.logo.url} className={`content__img-logo ${img.logo_class}`} alt="" />
                    </div>
                )
            })}
        </>
    )
}


export default Gear