import React, { Component } from "react";

import Cookies from 'universal-cookie';

import Menu from '../Menu/Menu';
import DelayLink from '../DelayLink/DelayLink';

import axios from "axios";
import API from "../../api";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { withRouter } from "react-router-dom";

import './Header.scss';
import HeaderEventsOnScroll from "./HeaderEventsOnScroll";

const cookies = new Cookies();

class Header extends Component {

    state = {
        header_data: [],
        menu_status: false,
        cookie_bar_status: cookies.get('cookieconsent_status'),
        data_load_status: false,
        hideFeedbackBtn: true,
        hideMenuBtn: true,
        darkLogo: false,
        darkNav: false,
        menuSize: false
    };

    /**
     menuSize: false => logo and navigation are full size
     darkLogo: false => logo has white color
     darkNav: false => navigation has white color
     **/

    componentDidMount() {

        // Get header data
        axios.get(API.basePath + '/wp-json/acf/v3/options/header-general-settings')
            .then(response => {
                this.setState({
                    header_data: response.data.acf,
                    data_load_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    toggleHeaderMenu = () => {
        this.setState({
            menu_status: !this.state.menu_status,
        });
    };

    cookieBarHandler = () => {

        cookies.set('cookieconsent_status', 'dismiss', { path: '/' }, { expires: new Date(Date.now() + 2592000) });

        this.setState({
            cookie_bar_status: cookies.get('cookieconsent_status'),
        });
    };

    showFeedbackBtn = (e) => {
        this.setState({
            hideFeedbackBtn: false,
        });
    };

    hideFeedbackBtn = (e) => {
        this.setState({
            hideFeedbackBtn: true,
        });
    };

    showMenuBtn = (e) => {
        this.setState({
            hideMenuBtn: false,
        });
    };

    hideMenuBtn = (e) => {
        this.setState({
            hideMenuBtn: true,
        });
    };

    hideHeaderMenu = () => {
        this.setState({
            menu_status: false,
        });
    };

    headerElementsStatus = (logo = false, nav = false) => {

        if (this.state.darkLogo !== logo) {
            this.setState({
                darkLogo: logo
            });
        }

        if (this.state.darkNav !== nav) {
            this.setState({
                darkNav: nav
            });
        }
    };

    menuSize = (status) => {
        if (this.state.menuSize !== status) {
            this.setState({
                menuSize: status
            });
        }
    };


    render() {

        const {
            large_logo,
            min_logo,
            mobile_logo,
            menu_button_title,
            linkedin,
            feedback_button_title
        } = this.state.header_data;

        let menuButtonClasses = 'nav-item__icon menu-close-icon';
        let iconPosition = "";
        let linkedInBtn = "";
        let navPosition = "";
        let linkDelay = 0;

        if (this.state.menu_status) {
            menuButtonClasses += ' menu-is-opened';
            iconPosition = " mobile-position";
            linkedInBtn = " page__nav-item--visible";
            navPosition = " page__nav--right-margin";
            document.body.classList.add('menu-is-open');
            linkDelay = "500";
        } else {
            document.body.classList.remove('menu-is-open');
            iconPosition = "";
        }

        let w = window.innerWidth;

        let skeletonSizes = {
            logo: {
                width: 200,
                height: 74
            },
            feedbackBtn: {
                width: 120,
                height: 21
            },
            menuBtn: {
                width: 104,
                height: 34
            }
        };

        if (w < 992) {
            skeletonSizes = {
                logo: {
                    width: 150,
                    height: 56
                },
                feedbackBtn: {
                    width: 25,
                    height: 20
                },
                menuBtn: {
                    width: 30,
                    height: 24
                }
            };
        }

        // Change the color of header elements
        let menuSize = this.state.menuSize;
        let pagePath = this.props.location.pathname;

        const technologySlugsArr = ['/web-development/magento', '/web-development/magento/', '/web-development/shopware', '/web-development/shopware/','/web-development/wordpress','/web-development/wordpress/']

        function getIsTechnologyPage() {
            return technologySlugsArr.includes(pagePath)
        }

        const isTechnologyPage = getIsTechnologyPage()

        let logoColor = '';
        let btnsColor = '';

        let logoStatus = this.state.darkLogo;
        let navStatus = this.state.darkNav;

        if (!menuSize) {

            if (
                pagePath === "/contact" ||
                pagePath === "/projects/bw-online-shop" ||
                pagePath === "/projects/office-people" ||
                pagePath === "/projects/volve" ||
                pagePath === "/projects/kostueme" ||
                pagePath === "/projects/fameonme" ||
                pagePath === "/projects/olymp" ||
                pagePath === "/projects/atrava"
              ) {
                logoStatus = w < 991 ? false : true;
                navStatus = false;
            } else if (pagePath === '/about-us' || pagePath === '/about-us/' || pagePath === '/partners' || pagePath === '/partners/' || pagePath === '/dpa' || pagePath === '/imprint' || pagePath === '/privacy-policy' || pagePath === '/hosting' || pagePath === '/terms-and-conditions' || pagePath === '/sma' || pagePath === '/amtc') {
                logoStatus = true;
                navStatus = true;
            } else if(pagePath === '/cookie-banner-integration' || pagePath === '/cookie-banner-integration/') {
                logoStatus = true;
            }
            else if (isTechnologyPage) {
                logoStatus = true;
                navStatus = true;
            } else {
                logoStatus = false;
                navStatus = false;
            }
        } else if (pagePath === '/contact') {
            logoStatus = true;
        } else if (isTechnologyPage) {
            logoStatus = true;
            navStatus = true;
        } else if (pagePath === '/dpa' || pagePath === '/imprint' || pagePath === '/privacy-policy' || pagePath === '/terms-and-conditions' || pagePath === '/sma' || pagePath === '/amtc') {
            logoStatus = true;
            navStatus = true;
        }

        if (
            pagePath === "/projects/bw-online-shop" ||
            pagePath === "/projects/office-people" ||
            pagePath === "/projects/volve" ||
            pagePath === "/projects/kostueme" ||
            pagePath === "/projects/olfameonmeymp" ||
            pagePath === "/projects/olymp" ||
            pagePath === "/projects/atrava"
          ) {
            if (w < 992) {
              logoStatus = true;
              navStatus = true;
            }
          }

        if (this.props.options && this.props.options.pageId === 'cv') {
            logoStatus = this.props.options.darkLogo;
            navStatus = this.props.options.darkNav;
        }

        if (navStatus && logoStatus && !this.state.menu_status) {
            btnsColor = ' nav-item__dark';
            logoColor = ' page__logo--dark';
        } else if (navStatus && !this.state.menu_status) {
            btnsColor = ' nav-item__dark';
        } else if (logoStatus && !this.state.menu_status) {
            logoColor = ' page__logo--dark';
        }

        // Change the type(full, min) of header elements

        let logoSize = '';

        let feedbackBtn = document.getElementsByClassName('nav-item__text-wrap')[0];
        let menuBtn = document.getElementsByClassName('nav-item__text-wrap')[1];
        let feedbackBtnWidth = feedbackBtn ? feedbackBtn.offsetWidth + 'px' : 0;
        let menuBtnWidth = menuBtn ? menuBtn.offsetWidth + 'px' : 0;

        if (menuSize && !this.state.menu_status) {
            logoSize = ' page__logo--min-version';
        }

        if (menuSize && !this.state.menu_status && this.state.hideFeedbackBtn) {
            feedbackBtnWidth = 0;
        }

        if (menuSize && !this.state.menu_status && this.state.hideMenuBtn) {
            menuBtnWidth = 0;
        }

        return (
            <div className="page__header">
                <SkeletonTheme color="#ffffff">
                    <header className="header">
                        <div className={`page__logo${logoSize}${logoColor}`}>
                            {
                                this.state.data_load_status ? (
                                    //Show the page logo

                                    (w < 992) ? (
                                        <DelayLink delay={linkDelay} to="/" clickAction={this.hideHeaderMenu}>
                                            <div className="page__logo-img page__logo-img--mobile" dangerouslySetInnerHTML={{ __html: mobile_logo }} />
                                        </DelayLink>
                                    ) : (
                                        <DelayLink delay={linkDelay} to="/" clickAction={this.hideHeaderMenu}>
                                            <div className="page__logo-img page__logo-img--large" dangerouslySetInnerHTML={{ __html: large_logo }} />
                                            <div className="page__logo-img page__logo-img--min" dangerouslySetInnerHTML={{ __html: min_logo }} />
                                        </DelayLink>
                                    )

                                ) : (

                                    // Skeleton for the logo
                                    <Skeleton height={skeletonSizes.logo.height} width={skeletonSizes.logo.width} />
                                )
                            }
                        </div>

                        <div className={`page__nav${navPosition}`}>
                            <div className={`page__nav-item${iconPosition}`}>
                                {
                                    // Show feedback button
                                    this.state.data_load_status ? (
                                        <DelayLink to="/contact"
                                            delay={linkDelay}
                                            clickAction={this.hideHeaderMenu}
                                            mouseEnterAction={this.showFeedbackBtn.bind(this)}
                                            mouseLeaveAction={this.hideFeedbackBtn.bind(this)}
                                            className={`nav-item__link nav-item__feedback${btnsColor}`}>
                                            <div className="nav-item__icon">
                                                <i className="icon-envelope"></i>
                                            </div>
                                            <div className="nav-item__text" style={{ width: feedbackBtnWidth }}>
                                                <div className="nav-item__text-wrap" dangerouslySetInnerHTML={{ __html: feedback_button_title }} />
                                            </div>
                                        </DelayLink>
                                    ) : (
                                        // Skeleton for the feedback button
                                        <Skeleton height={skeletonSizes.feedbackBtn.height} width={skeletonSizes.feedbackBtn.width} />
                                    )
                                }

                            </div>
                            <div className="page__nav-item">
                                {
                                    // Show menu button
                                    this.state.data_load_status ? (
                                        <a onClick={this.toggleHeaderMenu}
                                            onMouseEnter={this.showMenuBtn.bind(this)}
                                            onMouseLeave={this.hideMenuBtn.bind(this)}
                                            className={`nav-item__link  nav-item__menu${btnsColor}`}>
                                            <div className={menuButtonClasses}>
                                                <span className="top-line"></span>
                                                <span className="middle-line"></span>
                                                <span className="bottom-line"></span>
                                            </div>
                                            <div className="nav-item__text" style={{ width: menuBtnWidth }}>
                                                <div className="nav-item__text-wrap" dangerouslySetInnerHTML={{ __html: menu_button_title }} />
                                            </div>
                                        </a>
                                    ) : (
                                        // Skeleton for the menu button
                                        <Skeleton height={skeletonSizes.menuBtn.height} width={skeletonSizes.menuBtn.width} />
                                    )
                                }

                            </div>

                            <div className={`page__nav-item page__nav-item--linkedin${linkedInBtn}`}>
                                <a href={linkedin} target="_blank" rel="nofollow noopener">
                                    <i className="icon-linkedin"></i>
                                </a>
                            </div>
                        </div>

                        <Menu status={this.state.menu_status} toggleHeaderMenu={this.toggleHeaderMenu} />
                    </header>
                </SkeletonTheme>

                <HeaderEventsOnScroll
                    {...this.props}
                    changeHeaderEl={this.headerElementsStatus}
                    menuSize={this.menuSize}
                />
            </div>
        );
    }
}

export default withRouter(Header);
