import React, { useEffect, useState } from 'react';

import axios from 'axios';

import API from '../../../../api';

const PostCategory = (props) => {
  const [categories, getCategories] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const { isBig } = props;

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(API.basePath + '/wp-json/wp/v2/categories?post=' + props.post);
        if (res.data) {
          getCategories(res.data);
          setDataStatus(true);
        } else {
          console.log(res);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [props.post]);


  if (dataStatus) {
    return (
      <div className={isBig ? "articles__category-post" : "articles__category"}>
        {(categories.length <= 2 || isBig) ? categories.map(({ name }) => name).join(" | ") : `${categories.map(({ name }) => name).slice(0, 2).join(" | ")}...`}
      </div>
    );
  }
  return null;
};

export default PostCategory;
