import React, { Component } from "react";

import "./Products.scss";

export default class Products extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    transform: "none",
    width: 50,
    transition: false,
  };

  mouseMove(e) {
    let w, h;
    w = window.innerWidth;
    h = window.innerHeight;

    if (w > 992) {
      this.myRef.current.classList.remove("transition");

      let x,
        y,
        rotateX,
        moveY,
        boxPos = this.myRef.current.offsetTop;

      if (e.pageX || e.pageY) {
        x = e.pageX;
        y = e.pageY;
      } else if (e.clientX || e.clientY) {
        x =
          e.clientX +
          document.body.scrollLeft +
          document.documentElement.scrollLeft;
        y =
          e.clientY +
          document.body.scrollTop +
          document.documentElement.scrollTop;
      }

      y = -(boxPos - y);

      rotateX = (w / 2 - x) / 1000 / 4;
      moveY = (y - h / 2) / 1000;


      let movePercent = ((w / 2 - x) * 100) / w / 3;

      this.setState({
        transform:
          "matrix3d(" +
          Math.cos(rotateX) +
          ", 0, " +
          Math.sin(rotateX) +
          ", 0, 0, 1, 0, 0, " +
          Math.sin(rotateX) +
          ", 0, " +
          Math.cos(rotateX) +
          ", 0 , 0, " +
          moveY +
          ", 0, 1)",
        width: 50 + movePercent,
      });

      if (this.state.transition) {
        this.setState({
          transition: !this.state.transition,
        });
      }
    }
  }

  mouseLeave() {
    this.setState({
      transform: "none",
      width: 50,
      transition: !this.state.transition,
    });
  }


  render() {

    const {
      left_side,
      right_side,
      title
    } = this.props.content;


    return (
      <section
        className={`products${this.state.transition ? " transition" : ""}`}
        ref={this.myRef}
        name="products"
        onMouseMove={this.mouseMove.bind(this)}
        onMouseLeave={this.mouseLeave.bind(this)}>
        <div className="products__bg">
          <div className="products__bg__wrapper products__bg__wrapper--left" style={{ width: this.state.width + "%" }}>
            <div className="products__bg__item" style={{ backgroundImage: `url(${left_side.background.url})` }}>
              <div className="products__tehnologies">
                {left_side.icons.map((tehnologie, key) => {
                  return (
                    <div className="tehnologies" key={key}>
                      <img src={tehnologie.icon.url} alt={tehnologie.title} />
                      <div className="tehnologies-title">{tehnologie.title}</div>
                    </div>
                  );
                })}
              </div>
              <div className="products__bg-img">
                <div className="products__bg-img--wrapper" style={{ transform: this.state.transform }}>
                  <h2 className="products__bg-img--title" dangerouslySetInnerHTML= {{ __html: title}}></h2>
                </div>
              </div>
            </div>
          </div>
          <div className="products__bg__wrapper products__bg__wrapper--right">
            <div className="products__bg__item" style={{ backgroundImage: `url(${right_side.background.url})` }}>
              <div className="products__tehnologies">
                {right_side.icons.map((item, key) => {
                  return (
                    <div className="tehnologies" key={key}>
                      <img src={item.icon.url} alt={item.title} />
                      <div className="tehnologies-title">{item.title}</div>
                    </div>
                  );
                })}
              </div>
              <div className="products__bg-img">
                <div className="products__bg-img--wrapper" style={{ transform: this.state.transform }}>
                  <div className="products__bg-img--title title-second" dangerouslySetInnerHTML= {{ __html: title}} ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="products__wrapper">
          <div className="products__item products__item--left" style={{ backgroundImage: `url(${left_side.mobile_background.url})` }}>
            <div className="products__item-body">
              <div className="products__item-logo">
                <img src={left_side.logo.url} alt="logo" />
              </div>
              <div className="products__img"></div>
            </div>
          </div>
          <div className="products__item products__item--right" style={{ backgroundImage: `url(${right_side.mobile_background.url})` }}>
            <div className="products__item-body">
              <div className="products__item-logo">
                <img src={right_side.logo.url} alt="olymp" />
              </div>
              <div className="products__img products__img--second">
                <div className="products__bg-img--title title-mobile" dangerouslySetInnerHTML= {{ __html: title}} ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
