import React, {Component} from "react";

import MainBanner from '../../MainBanner/MainBanner';
import ContactUs from './ContactUs/ContactUs';
import IncludedServices  from './IncludedServices/IncludedServices';
import Docs  from './Docs/Docs';
import Form  from './Form/Form';

import "./CookieOptIn.scss";

import Skeleton from 'react-loading-skeleton';

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import axios from "axios";
import API from "../../../api";

class CookieOptIn extends Component {

    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false
    };

    componentDidMount() {
        // Get company menu items
        axios.get(API.basePath+'/wp-json/wp/v2/pages/589')
            .then(response => {
                this.setState({
                    page_data: response.data.acf,
                    yoast_meta: response.data,
                    loaded_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
        this.props.setContactElements();
    }

    render() {

        return (
            <>
                <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                <CustomSchema schema={this.state.page_data.schema} />
                <div className="cookie-opt-in">
                    <MainBanner content={this.state.page_data.main_banner_content || null } id='cookieOptIn' />
                    <div className="cookie-opt-in__meta" id="scrollPosition">
                        <div className="page__meta">
                            <div className="page__meta-bg page__meta-bg--top"></div>
                            <div className="page__meta-bg page__meta-bg--bottom"></div>
                            <div className="page__meta-content">
                                <div className="page__meta-title page__title">
                                    <h1 className="page__title-text" dangerouslySetInnerHTML={{ __html: this.state.page_data.page_title || <Skeleton width={1200}/>}} />
                                    <div className="page__title-bg">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.page_data.page_title || <Skeleton/>}} />
                                    </div>
                                </div>
                                <h2 className="page__meta-subtitle" dangerouslySetInnerHTML={{ __html: this.state.page_data.page_subtitle}} />
                                <div className="page__meta-description" dangerouslySetInnerHTML={{ __html: this.state.page_data.page_desctiption}} />
                            </div>
                        </div>
                    </div>
                    <Docs content={this.state.page_data.documents_section || null} />
                    <IncludedServices content={this.state.page_data.included_services || null} />
                    { this.state.page_data.form_section ? <Form content={this.state.page_data.form_section} /> : ''}
                    <ContactUs content={this.state.page_data.kontaktieren_sie_uns_section || null} />
                </div>
            </>
        );
    }
}

export default CookieOptIn;