import React, { useEffect, useState } from 'react'

import "./SuccessStoryBw.scss"

import API from '../../../api';

import axios from 'axios';

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import Loader from "../../Loader/Loader";

import Header from '../../SuccessStory/Header/Header';

import Description from '../../SuccessStory/Description/Description';

import ServicesSuccess from '../../SuccessStory/ServicesSuccess/ServicesSuccess';

import PageOnScreen from '../../SuccessStory/PageOnScreen/PageOnScreen'

import TimeLine from '../../SuccessStory/TimeLine/TimeLine';

import ProjectImplementation from '../../SuccessStory/ProjectImplementation/ProjectImplementation'

const SuccessStoryBw = (props) => {

    const [page_data, setPage_data] = useState({});
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);

    useEffect(() => {
        axios.get(API.basePath + '/wp-json/wp/v2/pages?slug=bw-online-shop')
            .then(response => {
                setPage_data(response.data[0].acf);
                setYoastData(response.data[0]);
                setLoaded_status(true);
            })
            .catch(error => {
                console.log(error);
            });

        props.setContactElements();
    }, [])

    const setYoastData = (data) => {
        setYoast_meta(data);
    }

    const canAnimate = (element) => {
        return (element.top + element.height > 0) && (window.innerHeight - element.top > 0)
    }

    if (!loaded_status) {
        return (
            <div className="success-stories-loader">
                <Loader />
            </div>
        );
    } else {
        return (
            <>
                <PageMeta metaData={yoast_meta} hreflangs={page_data['hreflang_list']}/>
                <CustomSchema schema={page_data.schema} />
                <div className="success-stories_page">
                    <Header contentLeft={page_data.banner.left_side} contentRight={page_data.banner.right_side} video={page_data.services.video} />
                    <Description content={page_data.description} />
                    <ServicesSuccess content={page_data.services} />
                    {page_data.pages__screens.page_screen &&
                        <PageOnScreen content={page_data.pages__screens} canAnimate={canAnimate} />
                    }
                    <TimeLine content={page_data.timeline} canAnimate={canAnimate} />
                    <ProjectImplementation content={page_data.project_implementation} gears={page_data.pages__screens} canAnimate={canAnimate} />
                </div>
            </>
        )
    }
}

export default SuccessStoryBw;
