import React from 'react';

const Service = (props) => {

    var service_bg = {
        backgroundImage: 'url(' + props.service_data.service_icon.url + ')'
    };

    return (
        <div className={`
            services__item-inner
            services__item${
                props.service_data.service_page_url && !props.noLink ?
                '--link' :
                '--info-block'
            }`
        }>
            <div className="services__item-bg" style={service_bg}></div>
            <div className='services__item-block' onClick={(e) => props.onClickHandler(e, props.service_data.service_button.url)}>
                <h3 className="services__item-title">
                    {props.service_data.service_title}
                </h3>
                <div className="services__item-info">
                    <div className="services__item-description" dangerouslySetInnerHTML={{ __html: props.service_data.service_description }} />
                    {
                        props.service_data.service_button.title ? (
                            <a href={props.service_data.service_button.url} className="services__item-link">
                                <span className="base-btn">
                                    {props.service_data.service_button.title}
                                </span>
                            </a>
                        ) : ''
                    }
                </div>
            </div>
        </div>
    );
};

export default Service;
