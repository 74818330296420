import React from 'react'

import SecondLevelTitle from "../SecondLevelTitle/SecondLevelTitle"

import Text from '../Text/Text'

const Description = (props) => {


    const {title, text} = props.content

    return (
        <section className="description-success" id="scrollPosition">
            <SecondLevelTitle title={title} />
            <Text text={text}/>
        </section>
    )
}

export default Description;