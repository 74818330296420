import React from "react";

import { Link } from 'react-scroll';

import Plx from 'react-plx';

import './Description.scss'

const Description = (props) => {
    const {title, subtitle, text } = props.content

    const dataRightTopParallax = [
        {
            start: 'self',
            duration: 900,
            properties: [
                {
                    startValue: 0,
                    endValue: 30,
                    unit: '%',
                    property: 'translateY',
                },
            ],
        },
    ];

    return (
        <section className="blog__description">
            <h1 className="blog__description__title">{title}
            </h1>
            <p className="blog__description__subtitle">{subtitle}</p>
            <p className="blog__description__text" dangerouslySetInnerHTML={{ __html: text }}></p>
            <div className="blog__description__img">
                <Plx parallaxData={dataRightTopParallax}><img src={props.content.images.front_side.url} alt={props.content.title} className="blog__description__img--front-side" /></Plx>
            </div>
            <img src={props.content.images.back_side.url} alt={props.content.title} className="blog__description__img--back-side" />
            <div className="scroll-down loading-block loading-block--4 scroll-down--description">
                <Link activeClass="active" className="scroll-down__btn" to="scrollPosition" spy={true} smooth={true} offset={0} duration={500} >
                    <i className="scroll-down__btn-i">
                        <svg width="10" height="35" viewBox="0 0 10 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00391 1.14282V6.85711" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 30L5 34L9 30" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </i>
                </Link>
            </div>
        </section>
    )
}

export default Description
