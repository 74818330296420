import React, { useState, useEffect } from "react";

import { getBrowserClasses } from "../helpers/detectBrowser";

import { Link } from "react-router-dom";

import Scroll from "react-scroll";

import Plx from "react-plx";

const ScrollLink = Scroll.Link;
const Element = Scroll.Element;


export default function PhoneImg(props) {
  const [windowWidth, setWindowWidth] = useState(0);

  const getWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };


  const autoScroll = function (id, contentId, delay) {

    const frame = document.querySelector(id);
    const content = document.querySelector(contentId);
    const timeoutId = setTimeout(function () {



      const scrollInterval = setInterval(function () {
        frame.scrollTop += 10;

        if (frame.scrollTop >= content.offsetHeight - frame.offsetHeight) {
          clearInterval(scrollInterval);
       
          setTimeout(() => {

            const scrollToTop = setInterval(function () {
              frame.scrollTop -= 10;
              if (frame.scrollTop === 0) {
                clearInterval(scrollToTop);
              }
      
            }, 10);

          }, 1000)

        }

      }, 10);

    }, delay);
  };



  useEffect(() => {
    getWindowWidth();
    window.addEventListener("resize", getWindowWidth);

    if (props.isScrolling && windowWidth > 992) {
      autoScroll("#screen__frame", "#screen__content", 6000);
    }

    return () => {
      window.removeEventListener("resize", getWindowWidth);
    };
  }, [props.isScrolling, windowWidth]);


  const dataTopParallax = [
    {
      start: document.querySelector(".design-process"),
      duration: 900,
      properties: [
        {
          startValue: 0,
          endValue: -30,
          unit: "%",
          property: "translateY",
        },
      ],
    },
  ];

  const {
    image_button_left,
    image_button_right,
  } = props.content.image.image_button;



  if (windowWidth < 992) {
    return (
      <div className="mobile-first__phone">
        <img src={props.content.image.mobile_image.url} alt={props.content.image.alt} />
      </div>
    );
  } else {
    return (
      <div
        className={`mobile-first__img${
          props.isScrolling ? " mobile-first__img-animate" : ""
          }`}
      >
        <Plx parallaxData={dataTopParallax}>
          <div className="mobile-screen">
            <div className="mobile-screen__moc-up">
              <img
                src={props.content.image.image_phone.url}
                alt={props.content.image.alt}
              />
            </div>
            <Element
              className={`mobile-screen__frame ${getBrowserClasses()}`}
              id="screen__frame"
              name="firstInsideContainer"
            >
              <img
                className="phone"
                id="screen__content"
                src={props.content.image.image_background.url}
                alt="phone"
              />
            </Element>
            <div className="mobile-first__btn">
              <ScrollLink
                className="mobile-first__btn--left"
                activeClass="active"
                to="firstInsideContainer"
                spy={true}
                smooth={true}
                duration={700}
                containerId="screen__frame"
              >
                <img
                  src={image_button_left.url}
                  alt={props.content.image.alt}
                />
              </ScrollLink>
              <Link className="mobile-first__btn--right" to="/contact" target="_blank">
                <img
                  src={image_button_right.url}
                  alt={props.content.image.alt}
                />
              </Link>
            </div>
          </div>
        </Plx>
        <div
          className={`mobile-first__message${
            props.isScrolling ? " mobile-first__message-animate" : " "
            }`}
        >
          <div className="mobile-first__message--text">
            <div>{props.content.image.image_message}</div>
          </div>
          <div className="mobile-first__message--wire">
            <img
              src={props.content.image.image_icon.url}
              alt={props.content.image.alt}
            />
          </div>
        </div>
      </div>
    );
  }
}
