import React from 'react';

const Field = (props) => {

    let field = props.fieldData;

    let requiredLabel = '';
    if(field.is_required === 'no') {
        requiredLabel = <div className="form-field__label-required">{props.requiredLabel}</div>
    }

    if (field.field_type[0] === 'input') {
        return (
            <>
                <div className="form-field__input">
                    <div className="form-field__label-block">
                        <label className="form-field__label" htmlFor={field.field_name_attribute}>
                            <div className="form-field__label-text">{field.label}</div>
                            {requiredLabel}
                        </label>
                    </div>
                    <input type={field.input_type}
                           name={field.field_name_attribute}
                           onBlur={props.blur}
                           onFocus={props.focus}
                           onChange={props.change}
                           placeholder={field.placeholder}
                           value={props.value}
                           id={field.field_name_attribute}
                    />
                    <div className="form-field__input--clear" onClick={() => props.click(field.field_name_attribute)}>
                        <i className="icon-close"></i>
                    </div>
                </div>
                <div className={`form-field__status ${(props.error) ? 'form-field__status--visible':''}`}>
                    <div className="form-field__status-msg">{props.error}</div>
                    <div className="form-field__status-icon">
                        <i className="icon-error"></i>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="form-field__input">
                    <div className="form-field__label-block">
                        <label className="form-field__label" htmlFor={field.field_name_attribute}>
                            <div className="form-field__label-text">{field.label}</div>
                            {requiredLabel}
                        </label>
                    </div>
                    <textarea name={field.field_name_attribute}
                              onBlur={props.blur}
                              onFocus={props.focus}
                              onChange={props.change}
                              placeholder={field.placeholder}
                              value={props.value}
                              id={field.field_name_attribute}
                    ></textarea>
                    <div className="form-field__input--clear" onClick={() => props.click(field.field_name_attribute)}>
                        <i className="icon-close"></i>
                    </div>
                </div>
                <div className={`form-field__status ${(props.error) ? 'form-field__status--visible':''}`}>
                    <div className="form-field__status-msg">{props.error}</div>
                    <div className="form-field__status-icon">
                        <i className="icon-error"></i>
                    </div>
                </div>
            </>
        )
    }

};

export default Field;