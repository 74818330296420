import React from "react";
import { Link } from "react-router-dom";
import './PostOtherTechnologies.scss';

export default function PostOtherTechnologies({ pageData }) {
    const { acf = {} } = pageData;

    return (
        <div className="other-technologies__content-right">
            <div className="technologies-title">{acf.technologies_header}</div>
            <div className="technologies-list">
                {(acf.technologies).map((technology) => {
                    const key = technology.link.title;
                    if (technology.link !== '') {
                        return (
                            <div className="technologies-item" key={key}>
                                <Link to={`${technology.link.url}`}>
                                    <span>{technology.link.title}</span>
                                </Link>
                            </div>
                        )
                    }
                })}
            </div>
        </div>)
}
