import React, {Component} from "react";
import './Docs.scss';

import Plx from 'react-plx';

class Docs extends Component {

    state = {
        parallax_status: true,
    };

    componentDidMount() {
        let w = window.innerWidth;

        if (w > 992) {
            this.setState({
                parallax_status: false
            });
        }
    }

    render() {
        if(this.props.content) {

            const content = this.props.content;

            const dataLeftParallax = [
                {
                    start: 'self',
                    duration: 1290,
                    properties: [
                        {
                            startValue: 0,
                            endValue: 22,
                            unit: '%',
                            property: 'translateY',
                        },
                    ],
                },
            ];

            const dataRightParallax = [
                {
                    start: '.docs__content-col--top-right',
                    easing: 'easeOut',
                    duration: 900,
                    properties: [
                        {
                            startValue: 100,
                            endValue: 0,
                            unit: '%',
                            property: 'translateX',
                        },
                    ],
                },
            ];

            return (
                <>
                    <div className="docs">
                        <div className="docs__header">
                            <div className="docs__title">
                                <h2 className="docs__title-text" dangerouslySetInnerHTML={{ __html: content.title}} />
                                <div className="docs__bg" dangerouslySetInnerHTML={{ __html: content.title}} />
                            </div>
                        </div>

                        <div className="docs__content">
                            <div className="docs__content-col docs__content-col--top-left">
                                <Plx className="docs__left-parallax" parallaxData={ dataLeftParallax } disabled={this.state.parallax_status}>
                                    <img src={content.left_top_image.url} alt={content.left_top_image.alt}/>
                                </Plx>
                                <div className="docs__left-parallax-bg"></div>
                            </div>
                            <div className="docs__content-col docs__content-col--top-right">
                                <div className="docs__about-websiten" dangerouslySetInnerHTML={{ __html: content.text_about_websites}} />
                            </div>
                            <div className="docs__content-col docs__content-col--bottom-right">
                                <Plx className="docs__right-parallax" parallaxData={ dataRightParallax } disabled={this.state.parallax_status}>
                                    <div className="docs__right-top-parallax">
                                        <img src={content.right_top_image.url} alt={content.right_top_image.alt}/>
                                    </div>
                                    <div className="docs__right-bottom-parallax">
                                        <img src={content.right_bottom_image.url} alt={content.right_bottom_image.alt}/>
                                    </div>
                                </Plx>
                            </div>
                            <div className="docs__content-col docs__content-col--bottom-left">
                                <div className="docs__about-data-protection" dangerouslySetInnerHTML={{ __html: content.text_about_data_protection}} />
                            </div>
                        </div>
                    </div>

                </>
            );
        } else {
            return (
                <div className="docs">
                </div>
            );
        }
    }
}

export default Docs;
