import React from 'react';

const Integration = (props) => {
    const { text, button } = props.content;

    return (
        <section className="integration">
            <div className="integration__text-container">
                <div
                    className="usercentrics-page__text integration__text"
                    dangerouslySetInnerHTML={{ __html: text }}
                />
                <a href={button.url} className="usercentrics-page__button">
                    {button.title}
                </a>
            </div>
        </section>
    )
};

export default Integration;
