import React from 'react'

const WrapperImplementation = (props) => {
    const { textComponent, imageComponent } = props;

    return (
        <div className="project-implementation__items">
            <div className="project-implementation__item-text project-implementation__item">
                {textComponent}
            </div>
            <div className="project-implementation__item-image project-implementation__item">
                {imageComponent}
            </div>
        </div>
    )
}


export default WrapperImplementation