import React from 'react';

const Technology = (props) => {

    let args = {};
    let itemClassName = '--info-block';

    if (props.technology_data.page_url && !props.noLink) {
        args['href'] = props.technology_data.page_url;
        itemClassName = '--link';
    }

    return (
        <div className={`technologies__item-inner technologies__item${itemClassName}`}>
            <div className="technologies__item-bg" dangerouslySetInnerHTML={{__html: props.technology_data.background_image}}/>
            <a {...args}>
                <div className="technologies__item-icon" dangerouslySetInnerHTML={{__html: props.technology_data.icon}}/>
                <div className="technologies__item-title">
                    {props.technology_data.title}
                    <div className='technologies__item-arrow'>
                        <i className="icon-angle-down"></i>
                    </div>
                </div>
                {
                    props.technology_data.page_url ? (
                        <div className="technologies__item-link">
                            <span className="base-btn">{props.technology_data.button_label}</span>
                        </div>
                    ) : ''
                }
            </a>
        </div>
    );
};

export default Technology;