import React, { useState, useEffect, useRef } from "react";
import Swiper from "react-id-swiper";

const DesignProcessSlider = ({ scrollStatus, mouseWheelStatus, content }) => {

  const [swiperIsVisible, setSwiperIsVisible] = useState(false);

  const ref = useRef(null);

  const sliderBtn = useRef(null);

  const stopScrollToNextSection = useRef();




  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
      ref.current.swiper.autoplay.start();
    }
  };

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
      ref.current.swiper.autoplay.start();
    }
  };

  const scrollToNextSection = () => {
    if (stopScrollToNextSection.current) {
      return;
    }

    const swiperIndex = ref.current.swiper.activeIndex;
    const countAllSlides = ref.current.swiper.slides.length;
    const nextSection = document.querySelector(".products");

    if (swiperIndex === countAllSlides - 1) {
      setTimeout(function () {
        nextSection.scrollIntoView({ behavior: "smooth" });
        stopScrollToNextSection.current = true;

      }, 1000);
    }
  };


  useEffect(() => {

    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.on("slideChange", () => {
        scrollToNextSection();
        document.body.classList.remove('scrolling');
      });
    }
    if (scrollStatus) {
      setTimeout(function () {
        setSwiperIsVisible(true);

        if (
          ref.current !== null &&
          ref.current.swiper !== null
          && !ref.current.swiper.autoplay.running
        ) {
          ref.current.swiper.params.autoplay.delay = 3000;
          ref.current.swiper.slideTo(0, 0, false);
          ref.current.swiper.autoplay.start();
        }
      }, 2200);
    }
  }, [scrollStatus]);


  const params = {
    speed: 0,
    breakpoints: {
      320: {
        allowTouchMove: true,
        loop: true,
      },
      920: {
        allowTouchMove: false,
      }
    }
  };

  const { slider } = content;


  return (
    <div
      className={`design-process__slider${swiperIsVisible
          ? " design-process__slider--play-animation"
          : " design-process__slider--hidden"
        }`}
    >
      <Swiper {...params} ref={ref}>
        {slider.map((slide) => {
          return (
            <div className="slide" key={slide.number}>
              <div className="slide__inner">
                <div
                  className={`slide__sun${scrollStatus ? " slide__sun-animate" : ""
                    }`}
                >
                  <div className="slide__number">{slide.number}</div>
                </div>
              </div>
              <div className="slide__title">
                {
                  slide.title.first ? (
                    <img
                      className="slide__title-img"
                      src={slide.title.first.url}
                      alt={slide.alt}
                    />
                  ) : ''
                }
                {
                  slide.title.second ? (
                    <img
                      className="slide__title-img"
                      src={slide.title.second.url}
                      alt={slide.alt}
                    />
                  ) : ''
                }
              </div>
            </div>
          );
        })}
      </Swiper>
      {window.innerWidth > 992 ?
        <>
          <div className={`design-process-btn-prev${scrollStatus ? " design-process-btn-prev-animate" : ""}`} onClick={goPrev}>
            <img src={content.buttons.prev.url} alt="prev" />
          </div>
          <div className={`design-process-btn-next${scrollStatus ? " design-process-btn-prev-animate" : ""}`} onClick={goNext}>
            <img src={content.buttons.next.url} alt="next" />
          </div>
        </>
        :
        <>
          <img src={content.buttons.prev.url} alt="prev" onClick={goPrev} className="slider-btn" />
          <img src={content.buttons.next.url} alt="next" onClick={goNext} className="slider-btn next" />
        </>
      }

    </div>
  );
};
export default DesignProcessSlider;
