import React, { Component } from "react";

import DesignProcessSlider from "./DesignProcessSlider";

import { Link } from "react-scroll";

import { animateScroll as scroll } from "react-scroll";

import "./DesignProcess.scss";
export default class DesignProcess extends Component {

  ref = null;

  state = {
    isScrolling: false,
    allowMouseWheel: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const distanceToTop = this.ref.getBoundingClientRect().top;
    if (distanceToTop < 0 && !this.state.isScrolling && window.innerWidth > 992) {
      scroll.scrollTo(this.ref.offsetTop);
      document.body.classList.add('scrolling');
      this.setState({
        isScrolling: true,
        allowMouseWheel: true
      });
    } else if (distanceToTop > 10) {
      document.body.classList.remove('scrolling');
    }
  };


  render() {
    const { button, title, town_background, alt } = this.props.content;

    return (
      <section className="design-process" ref={(ref) => this.ref = ref}>
        <div className="design-process__wrapper">
          <div className="design-process__inner design-process__inner-top">
            <h2 className="design-process__title">{title}</h2>
            <span className="design-process__title-bg">{title}</span>
            <div
              className={`design-process__img-sun${this.state.isScrolling ? " design-process__img-sun-animate" : ""
                }`}
            >
              <div className={`slide-icon${this.state.isScrolling ? " slide-icon-animate" : ""
                }`}>
                <img src={this.props.content.slider_icon.url} alt={this.props.content.alt} />
              </div>
            </div>
            <div
              className={`design-process__img-town${this.state.isScrolling
                  ? " design-process__img-town-animate"
                  : ""
                }`}
            >
              <img src={town_background.url} alt={alt} />
            </div>
          </div>
          <div className="design-process__inner design-process__inner-bottom">
            <div
              className={`design-process__grid${this.state.isScrolling ? " design-process__grid-animate" : ""
                }`}
            ></div>
          </div>
          <DesignProcessSlider
            scrollStatus={this.state.isScrolling}
            mouseWheelStatus={this.state.allowMouseWheel}
            content={this.props.content} />
          <Link
            onClick={this.scrollOff}
            activeClass="active"
            className={`design-process__btn${this.state.isScrolling ? " design-process__btn-animate" : ""
              }`}
            to="products"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={0}
            duration={500}
            delay={0}
            isDynamic={true}
            onSetActive={this.handleSetActive}
            onSetInactive={this.handleSetInactive}
            ignoreCancelEvents={false}
          >
            {button}
          </Link>
        </div>
      </section>
    );
  }
}