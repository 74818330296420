import React, { useEffect, useRef, useState }  from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import useMobile from "../../../../hooks/useMobile";
import useScrollAnimation from "../../../../hooks/useScrollAnimation";
import convertPxToRem from "../../../../helpers/convertPxToRem";

const Management = (props) => {
    const { title, text, image, list } = props.content;
    const isMobile = useMobile()
    const startAnimationRef = useRef(null)
    let scrollTop = useScrollAnimation(startAnimationRef.current)

    return (
        <section className='management'>
            <ParallaxProvider>
                <div className="management--top">
                    <div className='usercentrics-page__title'>
                        <h2 className='usercentrics-page__title-text' dangerouslySetInnerHTML={{ __html: title.text }} />
                        <div className='usercentrics-page__title-bg' dangerouslySetInnerHTML={{ __html: title.background_text }} />
                    </div>
                    <div className="usercentrics-page__text" dangerouslySetInnerHTML={{ __html: text }} />
                    <div className="management__image">
                        <Parallax
                            translateY={[10, -10]}
                            className="management__image--left"
                        >
                            <img src={image.left.url} alt={image.left.alt}/>
                        </Parallax>
                        <img className="management__image--right" src={image.right.url} alt={image.right.alt}/>
                    </div>
                    <div
                        ref={startAnimationRef}
                        className='management__triangle' 
                        style={{ borderTopWidth: !isMobile && (convertPxToRem(927) - scrollTop) / 2 }}
                    />
                </div>
                <div className="management--bottom">
                    <ul className="usercentrics-page__list">
                        {list.map((item, key) => {
                            return(
                                <li 
                                    className='lis'
                                    style={{ paddingTop: !isMobile && (convertPxToRem(320) * (key + 1) - scrollTop) / 2 }}
                                    key={`management-item--${item.title}`}
                                >
                                    <div>
                                        <div>3.<span>{key + 1}</span></div>
                                        <img src={item.icon.url} alt={item.icon.alt} />
                                        <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
                                        <p>{item.text}</p>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </ParallaxProvider>
        </section>
    )
};

export default Management;
