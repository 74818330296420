import React from "react";
import { withRouter } from 'react-router-dom';
import { Html5Entities } from 'html-entities';
import { Helmet } from "react-helmet";

const htmlEntities = new Html5Entities();

function PageMeta({ metaData, hreflangs, location }) {
    const {
        yoast_title,
        yoast_meta
    } = metaData;

    const microdata = {
        "@context": "https://schema.org/",
        "@graph": [
            {
                "@type": "Organization",
                "@id": `${window.origin}#organization`,
                "name": "alkima WEB & DESIGN ®",
                "url": `${window.origin}`,
                "sameAs": [
                    "https://www.linkedin.com/company/alkima-web/about/",
                    "https://twitter.com/alkima_web"
                ],
                "logo": {
                    "@type": "ImageObject",
                    "inLanguage": "en-US",
                    "@id": `${window.origin}#/schema/logo/image/`,
                    "url": "https://backend.alkima.org/app/uploads/2019/10/footer-logo.png",
                    "contentUrl": "https://backend.alkima.org/app/uploads/2019/10/footer-logo.png",
                    "width": 245,
                    "height": 91,
                    "caption": "alkima WEB & DESIGN"
                },
                "image": { "@id": `${window.origin}#/schema/logo/image/` }
            },
            {
                "@type": "WebSite",
                "@id": `${window.origin}#website`,
                "url": `${window.origin}`,
                "name": "alkima WEB & DESIGN ®",
                "description": "Web Agency",
                "publisher": { "@id": `${window.origin}#organization` },
                "inLanguage": "en-US"
            },
            {
                "@type": "WebPage",
                "@id": `${window.origin}`,
                "url": `${window.origin}`,
                "name": "Web Agency | alkima WEB & DESIGN ®",
                "isPartOf": { "@id": `${window.origin}#website` },
                "description": "We make your web project a success! Whether ✅ an online shop ✅ a website ✅ a web app. We accompany you all the way to digital success!",
                "inLanguage": "en-US",
                "potentialAction": [{ "@type": "ReadAction", "target": [`${window.origin}`] }]
            },
            {
                "@type": "LocalBusiness",
                "name": "alkima WEB & DESIGN ®",
                "image": [
                    "https://backend.alkima.org/app/uploads/2021/08/logo_for_META1.png",
                    "https://backend.alkima.org/app/uploads/2021/08/logo_for_META2.png",
                    "https://backend.alkima.org/app/uploads/2021/08/logo_for_META3.png"
                ],
                "url": `${window.origin}`,
                "telephone": "+356 2778 0192",
                "email": "info@alkima.org",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Phoenix Business Centre The Penthouse Old Railway Track",
                    "addressLocality": "Santa Venera",
                    "postalCode": "9022",
                    "addressCountry": "MT"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": 35.89211,
                    "longitude": 14.48296
                },
                "openingHoursSpecification": {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                    ],
                },
                "opens": "10:00",
                "closes": "17:00",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": 5,
                    "bestRating": 5,
                    "reviewCount": 7
                }
            }
        ]
    }

    if (yoast_meta && yoast_title) {
        return (
            <Helmet>
                <script className='structured-data-list' type="application/ld+json">
                    {JSON.stringify(microdata)}
                </script>

                <link rel="alternate" hrefLang="en" href={`${window.location.origin}${location.pathname}`} />

                {
                    hreflangs && hreflangs.length > 0 ? (
                        hreflangs.map(({ hreflang }, key) => {
                            return (
                                <link rel="alternate" hrefLang={hreflang.lang_code} href={hreflang.page_url} key={key} />
                            )
                        })
                    ) : ''
                }

                {
                    yoast_meta.map(function (item, key) {
                        let tadKeys = Object.keys(item);
                        let opts = {};

                        if (item[tadKeys[0]] === 'og:url') {
                            item.content = `${window.location.origin}${location.pathname}`;
                        }

                        opts[tadKeys[0]] = item[tadKeys[0]];
                        opts[tadKeys[1]] = htmlEntities.decode(item.content);

                        return (
                            <meta key={key} {...opts}></meta>
                        );
                    })
                }
                <title>{htmlEntities.decode(yoast_title)}</title>
                <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>
        );
    } else {
        return (
            <></>
        );
    }
};

export default withRouter(PageMeta)
